.analytics-table {
    width: 100%;
    // font-size: 12px;
    // color: color(dark);
    border-collapse: collapse;
    padding: 10px 0;
    // @include mq(768px) {
    //     font-size: 14px;
    // }
    .table {
        &-th {
            &-source {
                .select {
                    &-toggle {
                        padding-left: 3px;
                        .btn {
                            // padding-right: 3px;
                        }
                    }
                    &-menu {
                        max-width: 100%;
                    }
                }
            }
        }
        &-td {
            &-dimensionValues {
                text-align: left !important;
            }
            &-name {
                // font-weight: bold;
            }
        }
    }
    tbody {
        transition: opacity 0.3s ease;
        &.hide {
            opacity: 0;
        }
        tr {
            // &:hover {
            //     background-color: color(secondary, 0.03);
            //     &.dark-mode {
            //         background-color: color(light, 0.1);
            //     }
            // }
            &.active {
                background-color: color(primary, 0.2) !important;
            }
        }
    }
    tr {
        &:not(:last-of-type) {
            // box-shadow: 0px 0px 2px color(light);
            border-bottom: 1px solid color(light);
        }
    }
    th {
        text-align: left;
        // font-weight: bold;
        font-size: 12px;
    }
    td {
    }
    th,
    td {
        padding: 13px 10px;
        white-space: nowrap;
        // vertical-align: top;
    }
}
