.pagination {
    display: flex;
    gap: 3px;
    .btn {
        padding: 0;
        min-width: auto;
        height: 28px;
        min-width: 28px;
        border-radius: 5px;
        text-transform: none;
        padding-left: 5px;
        padding-right: 5px;
        &.disabled {
            opacity: 1;
            background-color: color(secondary,.05);
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}