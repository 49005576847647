.chart-card {
    // background-color: rgba(0, 0, 0, 0.03);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px 25px ;
    margin-right: 8px;
    max-width: 240px;
    margin-bottom: 20px;
    // max-width: 270px;
    .card {
        &-title {
            font-size: 14px;
            color: #606e80;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 13px;
        }
        &-value {
            font-size: 28px;
            font-weight: 500;
        }
        &-body {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
            margin-bottom: 3px;
        }
        &-cover {
        }
      
        &-value {
            display: flex;
            align-items: center;
            gap: 5px;
            &-icon {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                &.bg-success {
                    background-color: color(success,.2);
                }
                &.bg-danger {
                    background-color: color(danger,.2);
                }
                svg {
                    width: 12px;
                    height: auto;
                }
            }
            &-percentage {
                font-size: 14px;
                font-weight: 500;
            }
            &-text {
                font-size: 14px;
                color: #606E80;
                font-weight: 300;
            }
        }
    }

    &.card-success {
        .card-cover {
            svg path {
                stroke: color(success);
            }
        }
    }
    &.card-warning {
        .card-cover {
            svg path {
                stroke: color(warning);
            }
        }
    }
    &.card-danger {
        .card-cover {
            svg path {
                stroke: color(danger);
            }
        }
    }
    &.card-primary {
        .card-cover {
            svg path {
                stroke: color(primary);
            }
        }
    }
}


.dark-mode {
    .chart-card {
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
    }
}

@media screen and (max-width: 768px) { 
    .chart-card {
        flex: 1;
        max-width: initial;
    }

}