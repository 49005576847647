.progress {
    background-color: #dbdee1;
    border-radius: 100px;
    overflow: hidden;
    height: 2px;

    &-bar {
        color: #fff;
        // background-color: rgba(25, 118, 210,1);
        width: 0%;
        height: 100%;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        justify-content: center;
        text-align: center;
        border-radius: 100px;
        //    transition: width 1s ease;
        
        &-title {
            font-weight: bold;
        }
        &-value {
        }
    }
}

.progress-wrapper {
    & > .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        margin-bottom: 4px;
    }
}
