.chart-skeleton {
    padding: 20px 10px;
    display: grid;
    grid-gap: 10px;
    height: 300px;
    
    @include mq(992px){
        grid-template-columns: 1fr 2fr;
    }
    .line {
        border-radius: 10px;
        height: 100%;
        background-color: color(light,.3);
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, transparent,color(secondary,.05),transparent);
            animation: loading 1s infinite;
        }
    }
}

@keyframes loading {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}