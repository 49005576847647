.panel {
    position: fixed;
    top: 200px;
    border: 3px solid transparent;
    border-right: none;
    transition: all .3s ease;
    &.active {
        border-color: color(marketplace,.7);
    }
    @include mq(768px){
        top: 210px;

    }
    right: 0;
    // background-color: rgb(242, 242, 242,.1);
    background-color: rgba(255,255,255,1);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    border-radius: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 13px;
    // border: 1px solid #fff;
    box-shadow: -2px 1px 5px rgba(0,0,0,0.1);
    padding: 5px 10px;
    z-index: 4;
    width: fit-content;
    margin-left: auto;

    .btn-group {
        width: fit-content;
        margin-left: auto;
        display: flex;
        gap: 5px;
    }
}