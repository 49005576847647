.nested-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.5);
    &::-webkit-scrollbar {
        background-color: rgba(255, 255, 255, 1);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        &:hover {
            background-color: rgba(0, 0, 0, 0.6);
        }
        &:active {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
    &.fade {
        transition: opacity 0.15s linear;
        // .nested-modal-dialog {
        //     transition: transform 0.3s ease-out;
        // }
    }

    &.fade:not(.show) {
        opacity: 0;
        // .nested-modal-dialog {
        //     transform: translate(0, -50px);
        // }
    }

    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
    }
    &-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
    }
    &-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
    }
    &-footer {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;
        padding: 0.75rem;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: calc(0.3rem - 1px);
        border-bottom-left-radius: calc(0.3rem - 1px);
    }

    .btn-close {
 
    }
    &-dialog {
        position: relative;
        width: auto;
        margin: 0.5rem;
        &-scrollable {
            height: calc(100% - 1rem);
            .modal {
                &-content {
                    max-height: 100%;
                    overflow: hidden;
                }
                &-body {
                    overflow-y: auto;
                }
            }
        }
        &-centered {
            display: flex;
            align-items: center;
            min-height: calc(100% - 1rem);
        }
    }
    .nested-modal-fullscreen {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
        .nested-modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }
    }
}


.nested-modal-dialog {
    padding: 20px;
}

@media (min-width: 576px) {
    .nested-modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
        &-scrollable {
            height: calc(100% - 3.5rem);
        }
        &-centered {
            min-height: calc(100% - 3.5rem);
        }
    }
}


.dark-mode {
    .nested-modal-content {
        background-color: color(dark-mode);
    }
}