.vertical-composed-chart {
    .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
        transform: translateY(-15px);
        font-size: 10px !important;
        display: none !important;
    }

    .recharts-default-legend {
        display: flex;
        justify-content: space-between;
        padding-left: 55px !important;
        margin-top: -20px !important;
        .recharts-legend-item {
            font-size: 12px;
            svg {
                display: none !important;
            }
            &:first-of-type {
                display: none !important;
            }
        }
    }


}
