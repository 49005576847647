// .fade {
//     transition: opacity 0.15s linear;
// }

// .fade:not(.show) {
//     opacity: 0;
// }

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100%;
    // height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.5);
    // color: color(dark) !important;
    &::-webkit-scrollbar {
        background-color: rgba(255, 255, 255, 1);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        &:hover {
            background-color: rgba(0, 0, 0, 0.6);
        }
        &:active {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
    &.fade {
        transition: opacity 0.15s linear;
        .modal-dialog {
            transition: transform 0.3s ease-out;
        }
    }

    &.fade:not(.show) {
        opacity: 0;
        .modal-dialog {
            transform: translate(0, -50px);
        }
    }
    &-content {
        position: relative;
        display: flex;
        margin: 20px 10px !important;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        // border-radius: 0.3rem;
        border-radius: 25px;
        outline: 0;
    }
    &-title {
        font-size: 16px;
        text-align: center;
        // color: color(dark) !important;
    }
    &-header {
        padding: 30px 1rem;
    }
    &-body {
        position: relative;
        flex: 1 1 auto;
        margin-bottom: 15px;
        padding: 0 5px;
    }
    &-footer {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        padding-bottom: 30px;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 15px;
        margin-right: 15px;
        // padding: 0.2rem;
        // display: flex;
        // background: none;
        // border: 1px solid color(secondary);
        // border-radius: 4px;
        // color: color(secondary);
        // &:hover {
        //     color: color(dark);
        //     border-color: color(dark);
        // }
        // svg {
        //     user-select: none;
        //     width: 1em;
        //     height: 1em;
        //     display: inline-block;
        //     fill: currentColor;
        //     transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        //     font-size: 20px;
        // }
    }
    &-dialog {
        position: relative;
        margin: 0 auto;
        &-scrollable {
            min-height: calc(100% - 1rem);
            overflow-y: auto;
            background-color: #fff;
            .modal {
                &-content {
                    max-height: 100%;
                    // overflow: hidden;
                }
                &-body {
                    // overflow-y: auto;
                }
            }
        }
        &-centered {
            display: flex;
            align-items: center;
            min-height: calc(100% - 1rem);
        }
    }
    // &-backdrop {
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     z-index: 1040;
    //     width: 100vw;
    //     height: 100vh;
    //     background-color: #000;
    //     &.fade {
    //         opacity: 0;
    //         transition: opacity 0.15s linear;
    //     }
    //     &.show {
    //         opacity: 0.5;
    //     }
    // }
    .modal-fullscreen {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
        .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
            margin: 0 !important;
        }
    }
    .modal-sm {
        max-width: 280px;
        .modal-content {
            border-radius: 15px;
        }
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
        // margin: 1.75rem auto;
        // margin: 15vh auto 1.75rem;
        &-scrollable {
            height: calc(100% - 3.5rem);
        }
        &-centered {
            min-height: calc(100% - 3.5rem);
        }
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}
