.insights-page {
  .insights {
    &-title {
      // text-align: center;
    }
    &-subtitle {
      // text-align: center;
    }
    &-header {
      padding-top: 18px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px 30px;
      justify-content: space-between;
      position: sticky;
      top: 50px;
      z-index: 4;
      backdrop-filter: blur(3px);
      background-color: rgba(0, 0, 0, 0.05);
      background-color: rgba(255, 255, 255, 1);
      // padding: 10px 10px;
      // border-radius: 8px;
      // border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.02);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .scroll-items {
        flex: 1;
        max-width: 600px;
        width: 100%;
        .swiper {
          margin-left: 0;
        }
        .btn {
          border-radius: 0 !important;
          &-success {
            background-color: transparent;
            color: color(dark);
            border-bottom: 2px solid color(success);
          }
        }
      }

      .select {
      }
    }
  }
  .insights-row {
    display: flex;

    & > .wrapper {
      .banner-slide-title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
      }
    }
    @include mq(992px) {
      display: flex;
      gap: 20px;
      .analysis-chart {
        flex: 2;
      }
      & > .wrapper {
        flex: 1;
        .banner-slide-title {
          text-align: left;
        }
        .banner-slide {
        }
      }
    }
  }
  .vertical-title {
    font-size: 20px;
    font-weight: 500;
    // margin-bottom: 7px;
  }
  .vertical-composed-chart {
    // max-width: 850px;
    // margin: 0 auto;
  }
  .average-stats {
    &-title {
      font-size: 20px;
      font-weight: 500;
      text-transform: none;
    }
    & > .card-group {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      margin-bottom: 24px;
      @include mq(768px) {
        grid-template-columns: 1fr 1fr;
      }
      @include mq(1340px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
  // table {
  //   width: 100%;
  //   border-collapse: collapse;
  // }
  // tbody {
  //     tr {

  //     }
  // }
  // tr {
  //     &:not(:last-of-type) {
  //         border-bottom: 1px solid color(light);
  //     }

  // }
  // th {
  //     text-align: left;
  // }
  // td {
  // }
  // th,
  // td {
  //     padding: 13px 10px;
  //     white-space: nowrap;
  // }
  // .data  {
  //     .row {
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: center;
  //         gap: 10px 30px;
  //         & > .wrapper {
  //             display: flex;
  //             align-items: center;
  //             gap: 10px;
  //             flex-wrap: wrap;
  //         }
  //         .select {

  //         }
  //         .dropdown {
  //             .dropdown-menu {
  //                 right: 0;
  //                 left: auto;
  //             }
  //         }
  //     }
  // }
}

// .insights-chart {
//     max-width: 420px;
//     display: inline-block;
//     margin: 50px;
// }

// .animated-box {
//     width: 100px;
//     height: 100px;
//     background: linear-gradient(45deg, #f06, transparent);
//     animation: rotate 2s linear 3;
//   }

//   @keyframes rotate {
//     from {
//       transform: rotate(0deg);
//     }
//     to {
//       transform: rotate(360deg);
//     }
//   }

//   .recharts-layer.recharts-bar-rectangle {
//     background: linear-gradient(90deg, #15A0C5 0%, #00B368 100%) !important;

//   }

.audiences-chart {
  margin-bottom: 24px;
  .audience-btn {
    &.active {
      background-color: color(success);
      color: #fff;
    }
  }
  &-title {
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
  }
  & > .shadow > .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 30px;
    justify-content: space-between;
    & > .btn-group {
      .btn {
        border-radius: 0 !important;
        border-bottom: 2px solid transparent;
        &-success {
          background-color: transparent;
          color: color(dark);
          border-color: color(success);
        }
      }
    }
    & > .btn {
    }
  }
}

.analysis-chart {
  margin-bottom: 24px;
  &-title {
    font-size: 20px;
    font-weight: 500;
  }
  & > .shadow > .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 30px;
    justify-content: space-between;
    & > .btn-group {
      .btn {
        border-radius: 0 !important;
        border-bottom: 2px solid transparent;
        &-success {
          background-color: transparent;
          color: color(dark);
          border-color: color(success);
        }
      }
    }
    & > .btn {
    }
  }
}
.insights-container {
  margin-top: 10px;

  .insights-sidebar {
    flex: 1 1 25%;
    background-color: #f6fcfc;
    max-width: 250px;
    padding: 30px 0 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e1f7f0;



    .btn-selected {
      background-color: color(marketplace);
      color: #ffff;
      border-radius: 5px 0 0 5px;
      border: 1px solid #e1f7f0;
      border-right: none;
    }

    button {
      text-align: left;
      text-wrap: nowrap !important;
      justify-content: flex-start;
      margin-bottom: 5px;
    }
  }

  .insights-content {
    flex: 1 1 70%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .insights-row {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .wrapper {
        flex: 1;
      }
    }
  }

  .insights-page-content {
    width: 100%;
    box-shadow: rgba(42, 171, 126, 0.144) 0px 6px 24px 0px,
    rgba(42, 171, 126, 0.144) 0px 0px 0px 1px;
    padding: 35px 25px;
    border-radius: 10px;
    

    .average-stats-title {
      font-size: 20px;
      font-weight: 500;
      text-transform: none;
    }

    .analysis-chart-title {
        font-size: 20px;
        font-weight: 500;
        text-transform: none;
    }

    .average-costs-title {
        font-size: 20px;
        font-weight: 500;
        text-transform: none;
    }

    .banner-slide-title {
        font-size: 20px;
        font-weight: 500;
        text-transform: none;
    }

    .border {
      border: 1px solid rgb(21 90 56 / 41%);
      padding: 20px;
      border-radius: 8px;
    }

    .average-stats {
      margin-bottom: 1rem;
      .title-container {
        display: flex;

        h3 {
          margin-right: 20px;
        }
      }

      .card-group {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .audiences-chart {
      .audiences-chart-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .title-conatiner {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .audiences-main {
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .insights-sidebar,
    .insights-content {
      flex: 1 1 100%;
    }


    .audiences-main {
      margin-left: 0 !important;
      margin: 1rem 0;
    }
  }


}

.custom-select {
  appearance: none; /* Remove default styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 40px 8px 12px;
  font-size: 16px;
  color: #333;
  border: none;
  border-bottom: 2px solid #333; /* Add bottom border */
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%27http://www.w3.org/2000/svg%27 width%3D%2710%27 height%3D%275%27 viewBox%3D%270 0 10 5%27%3E%3Cpath fill%3D%27%23333%27 d%3D%27M0 0l5 5 5-5z%27/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  outline: none;
}

.custom-select:focus {
  border-bottom: 2px solid #007bff; /* Change color on focus */
}

.custom-select option {
  color: #333;
  padding: 8px;
  background-color: #fff;
}

.css-j218zi-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-color: color(marketplace) !important;
}

.average-costs {
  margin-bottom: 3rem;

  .average-costs-title {
    margin-bottom: 1rem;
  }

  .cards-conatiner {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    border: 1px solid #1018280f;
    border-radius: 8px;
    padding: 20px;
  }
}

.insights-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .wrapper {
    flex: 1 1 30%;
    height: 100%;
  }

  .banner-slide-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .header-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .chart-conatiner {
    flex: 1 1 50%;

    .analysis-chart-title {
      text-wrap: nowrap;
      margin-right: 20px;

    }
  }
}

.main-title-insights {
  text-align: center;
  margin: 1rem 0;
}

.verticals-container .scrolled {
  background-color: #ffffff; 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.2rem;
  border-radius: 10px;
}

.verticals-container {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background: #f8f8f8;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  position: sticky;
  top: 0%;
  z-index: 1000;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  z-index: 1;

  .timeframe-selector {
    margin-top: 23px;
  }

  .select-vertical-label {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .MuiSelect-select {
    max-width: 140px !important;
    min-width: 140px !important;
  }

  .scroll-items {
    flex: 1;
  }

  .swiper {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) { 
 .insights-sidebar {
   flex-direction: row !important;
   max-width: initial !important;
   width: 100%;

   .btn {
    width: 100%;
   }
 }
 .insights-sidebar {
   padding: 10px !important;
 }

 .card-group {
   display: block;
 }

 .cards-conatiner {
   display: block !important;

   .average-cost-card {
     width: 100%;
     max-width: initial;
    margin-bottom: 1rem;
   }
 }

 .insights-row {
   display: block !important;

   .analysis-chart-title {
      margin-bottom: 0.5rem;
    }
 }

 .insights-categories {
   margin-top: 20px;
 }

 .scroll-items {
  width: 100%;
}


.verticals-container {
  .MuiSelect-select {
    max-width: 120px !important;
    min-width: 120px !important;
  }

}
}


