.accordion {
    max-width: 700px;
    &-item {
        background-color: rgba(255,255,255,.7);
        // border: 1px solid rgba(255,255,255,1);
        // border: 1px solid color(primary,.2);
        // border: 1px solid color(dark);
        margin-bottom: 20px;
        // box-shadow: 0 3px 3px rgba(0,0,0,.1);
        border-radius: 10px ;
        overflow: hidden;
        &:first-of-type {
            // border-top-left-radius: 0.25rem;
            // border-top-right-radius: 0.25rem;
        }
        &:last-of-type {
            // border-bottom-right-radius: 0.25rem;
            // border-bottom-left-radius: 0.25rem;
            margin-bottom: 0;
            .accordion-button.collapsed {
                // border-bottom-right-radius: calc(0.25rem - 1px);
                // border-bottom-left-radius: calc(0.25rem - 1px);
            }
        }
        &:not(:first-of-type){
            // border-top: 0;
        }
   
    }
    &-button {
        border-radius: 0;
        .ripple {
            opacity: 0.02 !important;
        }
        &:hover {
            // background-color: transparent;
        }
    }
    &-collapse {
        overflow: hidden;
        // height: 0;
       line-height: 1.7;  // set line-height to prevent useRef height glitch 
        transition: height .3s ease;
        
        &.show {
        }
    }
    &-header {
        margin-bottom: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        // background-color: rgba(255,255,255,.03);
    }
    &-description {
        font-size: 14px;
        color: color(muted);
        font-weight: 500;
        padding:5px 0 15px 10px;
    }
    &-button {
        width: 100%;
        padding: 1rem 1.25rem;
        .startIcon {
            margin-right: 10px;
            svg {
                height: 20px;
                width: 20px;
            }
        }
        .endIcon {
            margin-left: auto;
            transition: transform .3s ease;
            transform: rotateZ(180deg);
          
        }
        &:not(.collapsed) {
            .endIcon {
                transform: none;
            }
        }
    }
    &-body {
        padding: 20px;
    }
}
