.analytics-details {
    padding: 0;
    &-inner {
        padding: 22px;
        min-height: 300px;
        position: relative;

        & > .wrapper {
            // display: flex;
            // gap: 10px;
            // margin-bottom: 10px;
            // justify-content: space-between;
            // flex-direction: column;
            margin-bottom: 30px;
            @include mq(768px) {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 60%;
            }
            .source-select {
                .select {
                    
                    &-toggle {
    
                        padding-left: 3px;
                        .btn {
                            // padding-right: 3px;
                        }
                    }
                    &-menu {
                        max-width: 100%;
                    }
                }
            }
            .scroll-items {
                width: 100%;
                .swiper {
                    margin-right:0;
                    position: relative;
                    z-index: 0;
                    .swiper-slide {
                        &:first-child {
                            margin-left: 20px;
                        }
                        &:last-child {
                            margin-right: 20px;
                        }
                    }
                    &:after,
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 20px;
                        z-index: 9999;
                        pointer-events: none;
                    }
                    &:before {
                        box-shadow: inset 15px 0 10px #fff;
                    }
                    &:after {
                        box-shadow: inset -15px 0 10px #fff;
                        right: 0;
                    }
                }
            }
            // .select-group {
              
            //     display: flex;
            //     flex-wrap: wrap;
            //     align-items: center;
            //     gap: 10px;
    
            //     .dimension-select {
            //         width: 100px;
            //         .select {
            //             &-menu {
            //                 max-width: 100%;
            //             }
            //         }
            //     }
            // }
    
            .date-dropdown {
                .dropdown-menu {
                    @include mq(768px) {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    .position {
        @include mq(768px){
            padding-top: 400px;
        }
        &-inner {
        }
    }
}

