.small-footer {
    hr {
        opacity: 0.2;
    }
    .copyright {
        
    }
    .wrapper {
        padding-top: 20px;
        padding-bottom: 20px;

        display: flex;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
        align-items: center;
    }
    .socials {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}