.multi-select {
    position: relative;
    user-select: none;
    width: fit-content;
    // width: 100%;
    max-width: 300px;
    &-loading {
        pointer-events: none;
        opacity: 0.3;
    }
    .search {
        margin-top: 5px;
        margin-bottom: 5px;
        
        input {
            // border-radius: 0;
        }
        
    }
    .form-check-btn {
        display: none;
    }
    .form-check-label {
        font-weight: 600 !important;
    }
    .btn {
        overflow: visible;
        &:hover {
            background-color: #fff;
        }
    }
    * {
        font-family: "Inter", "Helvetica", "Arial", sans-serif;
    }
    //  .btn {
    //     all: unset;
    // }
    .ripple {
        display: none !important;
    }
    &-label {
        font-size: 14px;
        // color: color(dark);
        font-weight: bold;
        margin-bottom: 8px;
        display: inline-block;
    }
    &.active {
        .multi-select {
            &-toggle {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                &-icon {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            &-menu {
                opacity: 1;
                transform: none;
                pointer-events: auto;
            }
        }
    }
    &-toggle,
    &-item {
        display: flex;
        align-items: center;
        .startIcon,
        .endIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            svg,
            img {
                height: 12px;
                width: 12px;
                object-fit: contain;
                fill: currentColor;
            }
        }
        .endIcon {
            margin-left: 0px;
        }
        .startIcon {
            margin-right: 0px;
        }
    }
    &-toggle {
        padding: 4px 8px;
        padding-right: 35px;
        cursor: pointer;
        width: 100%;
        justify-content: space-between;
        text-transform: none;
        min-height: 35px;
        background-color: #fff;
        & > .wrapper {
            display: flex;
            align-items: center;
            // width: 88%;
            overflow: hidden;
            // flex-wrap: wrap;
            gap: 3px;
            &.placeholder {
                opacity: 0.4;
            }
            .rest {
                margin-left: 3px;
                font-size: 10px;
                white-space: nowrap;
                &:hover {
                    background-color: color(primary, 0.1);
                }
            }
        }

        &-title {
        }
       
        &-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 3px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 !important;
            border-radius: 50%;
            &:hover {
                background-color: color(secondary, 0.1);
            }
            svg {
                transition-duration: 300ms;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-property: opacity, transform;
            }
        }
    }
    .form-check-sm {
        margin-left: 0;
    }
    &-menu {
        margin-top: 5px;
        padding: 2px; // for outline
        background-color: #fff;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        min-width: 100%;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        transform: rotateX(-90deg);
        transform-origin: center top;
        opacity: 0;
        pointer-events: none;
        // will-change: transform, opacity;
        max-height: 12rem;
        overflow-y: auto;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: opacity, transform;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.1);
            border: none;
            transition: color 0.2s ease;
        }
        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.02);
            border-radius: 0;
        }
        &.limit-reached {
            .multi-select-item {

                opacity: 0.2 ;
                pointer-events: none ;
            }
        }
    }

    &-item {
        width: 100%;
        font-size: 1rem;
        padding: 6px 6px;
        cursor: pointer;
        line-height: 1.2;
        color: color(dark);
        // transition: background-color 0.3s ease;
        // &.active {
        //     background-color: rgba(0, 0, 0, 0.1);
        // }

        justify-content: space-between; // kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        &-title {
            overflow-x: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        &-size {
            font-size: 8px;
            // color: color(secondary,.7);
            // color: color(primary);
            background-color: color(light,.5);
            padding: 1.5px 3px;
            border-radius: 2px;
            font-weight: 500;
        }
        &:hover:not(.active) {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    &-tag {
        background-color: color(primary);
        color: #fff;
        border-radius: 4px;
        padding: 2px 3px;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 3px;
        &-title {
            line-height: 1.6;
            overflow-x: hidden;
            max-width: 60px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 300 !important;
        }
        &-close {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: color(dark, 0.3);

            &:hover {
                background-color: color(dark, 0.8);
            }
            svg {
                width: 7px;
                height: 7px;
                path {
                    fill: #fff;
                }
            }
        }
    }
    &.direction {
        &-up {
            .multi-select {
                &-menu {
                    margin-top: 0;
                    transform-origin: bottom;
                    top: auto;
                    bottom: 100%;
                    margin-bottom: -20px;
                    border-radius: 5px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    box-shadow: 0px -2px 3px -1px rgba(0, 0, 0, 0.1);
                }
            }
            &.active {
                .multi-select {
                    &-toggle {
                        border-radius: 5px;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }
        }
    }

    .multiselect-tooltip {
        // background-color: rgba(100, 100, 100, 1) !important;
        // opacity: 1 !important;
        // padding: 6px !important;
        max-width: 330px !important;
        span {
            display: flex;
            flex-wrap: wrap;
            gap: 3px;
            white-space: nowrap !important;
        }
    }

    .multiselect-label-hidden {
        background-color: color(primary);
        color: #fff;
        border-radius: 4px;
        padding: 2px 3px;
        height: 16px;
        font-size: 8px;
        font-weight: 400;
    }
}


.custom-wrapper-tag {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}