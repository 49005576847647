.upload-card {
    display: inline-flex;
    align-items: flex-end;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    border-radius: 10px;
    gap: 10px;
    position: relative;
    width: 200px;
    .card {
        &-close {
            position: absolute;
            top: 0;
            right: 0;
            margin: 5px;
        }
        &-image {
            border: 2px solid #fff;
            box-shadow: 0 0 3px rgba(0,0,0,0.3);
            border-radius: 4px;
   
            width: 60px;
            height: 60px;
            padding: 3px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
                object-position: center;
            }
        }
        &-text {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
            max-width: 100px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &-number {
            font-size: 14px;
            font-weight: 400;
            text-align: left;
        }
    }
    & > .wrapper {
    }
}

.dark-mode {
    .upload-card {
        // background-color: color(dark-mode);
        background-color: #000;
        // box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
    }
}