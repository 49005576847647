.tracking-page {
    height: 100%;
    padding: 0 !important;
    // padding-top: 2rem;
    & > .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px 100px;
        flex-wrap: wrap;
    }
    .panel-d  {
        width: 1000px;
        height: 50px;
        background-color: color(pale-dark);
    }
    .scroll {
        padding: 0;
        border-radius: 0;
        min-height: 0;
    }
    .drawer-toggler {
        display: inline-block;
     
    }
    .tracking-cover {
        width: 100%;
        max-width: 400px;
        height: auto;
        // margin: 0 auto;
        // margin-left: auto;
        display: block;
    }

    .align-start {
        display: flex;
        justify-content: flex-start;
    }
    
    .unverified {
        color: #f93939;
        cursor: pointer
    }

    .verified {
        color: color(marketplace);
        cursor: pointer
    }

       #create-convresions-pixel {
        svg {
            width: 10px !important;
        }
       } 

    .tracking-drawer-container {
        justify-content: space-between;
    }

    .no-data-conatiner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
    }
}
.conversion-table {
    width: 100%;
    // font-size: 12px;
    // color: color(dark);
    border-collapse: collapse;
    // margin: 10px 0;
    // @include mq(768px) {
        // font-size: 14px;
    // }

    border-radius: 5px;
    overflow: hidden;

    .table {
        
        &-row {
        }
        &-th {
            &-settings {
                .settings-icon {
                    width: 12px;
                    height: 12px;
                    margin: 0 auto;
                    display: inline-block;
                }
            }
        }
        &-td {
            &-name {
                max-width: 200px;
                span {
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }

            &-highlight {
                .spin-loading {
                    width: 25px;
                    height: 25px;
                }
            }
            &-settings {
            }
            &-actions {
                .btn-group {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
        }
        &-tr {
        }
    }
    thead {
        // background-color: rgb(0, 31, 57);
        color: #fff;
        tr {
            // background-color: #2aab7e;
            // border-color: #2aab7e;
            th {
                color: black;
                padding: 15px;

                .details-icon {
                svg {
                    path {
                        fill: black !important;
                    }
                }
                }
            }
        }
    }
    tbody {
        tr {
        
      
        }
    }
    tr {
        background-color: color(light, 0.15);
        &:last-of-type {
            // border-bottom: 2px solid #2aab7e;
        }
        &:not(:last-of-type) {
            border-bottom: 1px solid color(light);
        }
    }
    th {
        color: color(light);

    }
    td {
    }
    th,
    td {
        padding: 8px 10px;
        white-space: nowrap;
    }
}

.custom-tooltip {
    // background-color: rgba(100, 100, 100, 1) !important;
    background-color: color(dark) !important;
    border-radius: 8px !important;
    text-align: center;
    line-height: 1.3;
    font-weight: 500;
    color: #fff;
    // opacity: 1 !important;
    // border: 2px solid black;
    // box-shadow: 0 0 3px rgba(255,255,255,0.3);
    // borderRadius: '8px',;
    padding: 5px 8px !important;
    // width: calc(100% - 11px) !important;
    // width: 300px !important;
    max-width: fit-content;
    z-index: 999;
    font-size: 12px !important;
    box-shadow: 0 0 3px color(light, 0.3);
    &.warning {
        background-color: rgb(255, 240, 178) !important;
        color: rgb(51, 51, 51) !important;
        border: 1px solid color(dark, 0.05);
        opacity: 1 !important;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    }
    &.sidebar-tooltip {
        font-size: 10px !important;
        background-color: #fff !important;
        color: #000 !important;
        // box-shadow: 0 0 3px color(dark);
        border: 2px solid color(success) !important;
    }
}


.create-pixel-header {
    display: flex;
    justify-content: space-between;
    align-self: start;
    width: 100%;

    svg {
        width: 20px;
    }
}

.create-pixel-body {
    padding-top: 2rem;
}

.confirmation-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 90%
}

.campaign-details-tr {
    .campaign-info-td {
        border-radius: 0 !important;
    }
    .install-pixel-tab {
        .tabs {
            padding: 2rem;
        }
    }
}

.edit-name-span {
    svg {
        width: 10px;
        height: 10px;
    }
}


.tracking-drawer {
    height: 100%;
    .custom-border {
        border: 2px solid rgb(219 222 225) !important;
    }
}

.conversion-table {
.details-icon {
    svg {
        path {
            fill: white;
        }
     
    }
}
}
