.create-conversion-tab {
    &-title {
        font-weight: 500;
        margin-bottom: 10px;
    }
    .step-1 {
        .form-legend {
            display: flex;
            gap: 50px 100px;
            flex-direction: column;
            margin-bottom: 30px;

            @include mq(768px){
                align-items: flex-start;
                flex-direction: row;
            }
        }
        form {
            flex: 1;
        }
    }
    .step-2 {
        .form-legend {
            display: flex;
            gap: 100px 100px;
            flex-direction: column;
            margin-bottom: 30px;
            @include mq(768px){
                align-items: flex-start;
                flex-direction: row;
            }
        }
        form {
            flex: 1;
            & > .wrapper {
                display: flex;
                gap: 10px;
                margin-bottom: 10px;
            }
            .select {
                flex: 1;
            }
            .field {
                flex: 1;
            }
        }
    }
 
    .cover {
        height: auto;
        width: 100%;
        max-width: 200px;
     
    }
    .tabs {
        padding: 0;
        border: none;
    }
    .nav-tabs {
        flex-direction: row;
        width: 100%;
        gap: 8px;
        align-items: center;
        margin-bottom: 15px;

        display: none;
    }
    .nav-item {
            &.disabled {
                .btn {
                    pointer-events: none !important;
                    min-width: auto;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    opacity: 0.9;
                }
            }
        .nav-link {
            border-radius: 5px;
            font-size: 12px;
            padding: 1px 10px;
            // line-height: ;
            border: 1px solid color(light, 0.5);
            background-color: color(light, 0.2);
            margin-bottom: 0;
            text-transform: none;

            .startIcon {
                display: flex !important;
            }
            &.active {
                // color: color(primary);
                // border-color: color(primary);
                color: color(pale-dark);
                border-color: color(pale-dark);
            }
            svg {
                height: 10px !important;
                width: auto !important;
                path {
                    fill: currentColor !important;
                }
            }
            &:hover {
                background-color: color(light, 0.3);
            }
        }
    }
    &-footer {
        .btn-group {
            margin-left: auto;
            width: fit-content;
            display: flex;
            gap: 15px;
        }
    }

}

.create-conversion-modal {
    max-width: 450px;
    .btn-group {
        display: flex;
        gap: 10px;
    }
    .modal {
        &-body {
            text-align: center;
        }
    }
}

.editing-mode {
    .complete-btn {
    }
}
.conversion-form {
    @include mq(768px){
        max-width: 380px;
    }
}


.instructions-form {
        padding: 20px;
    border-radius: 15px;
    background-color: rgba(0,0,0,0.05);
    &-title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        margin-bottom: 15px;
        svg {
            width: 20px;
            height: auto;
            path {
                fill: color(secondary);
            }
        }
    }
    &-description {
        max-width: 550px;
        line-height: 1.5;
        margin-bottom: 20px;
    }
    & > .wrapper {
            @include mq(768px){
        max-width: 380px;
    }
    }
}