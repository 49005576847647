.countries-and-bid {
    &-title {
        // text-align: center;
        margin-bottom: 30px;
    }
    &-btn-group {
        margin-bottom: 8px;
        display: flex;
        gap: 3px;
        flex-wrap: wrap;
        .btn {
            min-width: auto;
        }
    }
}