.error-page {
    padding: 0;
}

.error {
    padding: 0;
    min-height: 100vh;
    @include df();
    .container {
        text-align: center;
    }
    a {
        margin-bottom: 1rem;
    }
    .wrapper {
        max-width: 350px;
        margin: 0 auto;
    }
    &-img {
        position: relative;
        height: 0;
        padding-top: 100%;
        width: 100%;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-title {
        font-size: 5vw;
        text-transform: capitalize;
        font-weight: 400;
    }
}
