.search {
    position: relative;
    width: fit-content;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
    &.active {
        .search {
         
            &-menu {
                opacity: 1;
                transform: none;
                pointer-events: auto;
            }
        }
    }
 
    &-menu {
        width: calc(100vw - 30px - 12px);
        max-width: 320px;
        padding: 6px; // for outline
        background-color: #fff;
        border-radius: 10px;
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        border: 1px solid color(light,1);
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
        opacity: 0;
        transform: translateY(15px);
        pointer-events: none;
        will-change: transform, opacity;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: opacity, transform;
        display: grid;
        grid-template-columns: 1fr auto;
        .btn {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            min-width: auto;
            height: 100%;
            font-size: 12px;
            font-weight: 400;
        }
        .field {
            input {

                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

        }
    }

    &-toggle {
        width: 30px !important;
        height: 30px !important;
    }
    &-submit {
        padding-right: 9px;
        padding-left: 9px;
    }

    &-field {
        margin-bottom: 0;
        input {
            font-size: 12px;

        }
    }


 
}
