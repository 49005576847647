

.qualification-page {
    max-width: 100%;
    min-height: 100vh;
    padding: 0;
    background-color:#F8F9FB;
    .logo {
        max-width: 188px;
        margin: 0 auto 50px;
        display: block;
    }
    .qualification {
        box-shadow: 0px 1px 2px 0px #1018280F;
        border: 2px solid #E4E7EC;
        border-radius: 15px;
        background-color: #fff;
        padding: 30px 25px;
        width: 100%;
        
        // max-width: 407px;
        max-width: 630px;
        margin: 0 auto;
        margin-bottom: 50px;
   
        // @include mq(1100px) {
        //     margin-bottom: 0px;
        // }
    
        // &-description {
        //     font-size: 14px;
        //     text-align: center;
        //     margin-bottom: 40px;
        // }
    }
    & > .container {
        padding-top: 40px;
        padding-bottom: 40px;
        height: 100%;
   
  
    }
    .select {
        width: 100%;
        max-width: 100%;
        // .btn {
        //     padding: 12px 12px;
        //     line-height: 1.5;
        //     padding-right: 35px;
        // }
        margin-bottom: 20px;
        &-menu {
            width: 100%;
        }
        // &-item {
        //     padding: 12px 12px;
        // }
    }
    .multi-select {
        width: 100%;
        max-width: 100%;
    }
    .radio-multi-select,
    .checkbox-multi-select {
        label {
            margin-bottom: 15px;
        }
        .radio-text,
        .form-check-label {

        font-size: 14px !important;
        }
    }
    .checkbox-multi-select {
        .form-check {
            display: flex;
        }
        .field {
            margin-bottom: 0 !important;
            margin-left: 7px;
            flex: 1;
        }
    }
    .switch-select {
        display: flex;
        align-items: center;
        gap: 15px;
        .select-label {
            margin-top: -2px;
            margin-bottom: 0;
        }
        & > .wrapper {
            display: flex;
            gap: 2px;
            
            .info-popup {
                align-self: flex-start;
                margin-top: -6px;
                background-color: color(light,.7);
                width: 15px;
                height: 15px;
                svg {
                    height: 8px;
                }
            }
        }
    }
    .tabs {
        flex: 1;
        display: block;
        padding: 0;
        box-shadow: none;
        .nav-tabs {
            display: flex;
            flex-direction: row;
            width: 100%;
            display: none;
            .nav-link {
                border-radius: 4px;
                margin-bottom: 3px;
            }
        }
        .tab-content {
            padding-left: 0;
        }
        .divider {
            display: none;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        font-family: Manrope;
   
    
        &-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 30px;
        }
        .step-scheme {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 30px;
            &-bar {
                flex: 1;
                background-color: #E4E7EC;
                height: 6px;
                border-radius: 100px;
                &.active {
                    background-color: color(success);
                }
            }
            &-left {

            }
        }
        &-description {
            font-size: 18px;
            font-weight: 600;
            color: #66768E;
            text-align: center;
            margin-bottom: 70px;
        }
        .field {
            margin-bottom: 20px;
        }
        // .submit-btn {
        //     margin-top: 12px;
        //     margin-bottom: 20px;
        // }
        .btn-group {
                margin-top: 12px;
                display: flex;
                gap: 10px;
                justify-content: right;
        }
    }
    .cut-line {
        margin-bottom: 20px;
    }
    .social-btn {
        margin-bottom: 15px;
    }


    .accordion {
        &-button {
            background-color: color(light,.1);
        }
        &-header {
            border-bottom: none;

        }
    }
    .copyright {
        font-size: 14px;
        margin: 0 auto;
        max-width: 407px;
    }
    .testimonials {
        height: 100%;
    }

    .custom-error-message {
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
            width: 15px;
        }
    }

    .MuiFormHelperText-root {
        margin-left: 0 !important;
    }

    .MuiInputBase-root {
        border-radius: 10px !important;
    }
}
