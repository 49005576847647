
.tracking-help {
    border: 1px solid color(pale-dark,.1);
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    &-btn {
        line-height: 1.6;
    }
    .help {
        border-radius: 100px;
        background-color: color(pale-dark);
        display: flex;
        width: fit-content;
        margin: 0 auto;
        gap: 10px;
        align-items: center;
        padding: 5px;
        border: 1px solid color(light, 0.5);
        // color: color(pale-dark);
        color: #fff;

        // background-color: color(light);
        padding: 4px 7px;
        padding-left: 10px;
        font-size: 12px;
        margin-bottom: 16px;
        
        &-icon {
            // border: 1px solid color(pale-dark,.5);
            border: 1px solid #fff;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        svg {
            height: 9px;
            width: auto;
            // fill: color(pale-dark,.8);
            fill: #fff;
        }
    }

    strong {
        display: block;
        font-size: 14px;
        font-weight: 600;
    }
    p {
        font-size: 12px;
    }
}

.tracking-instructions-tooltip {
    max-width: 300px;
    width: 100% !important;
    padding: 0 !important;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    border-radius: 15px !important;
    border: 1px solid color(light, .9);
    overflow: hidden;
    background-color: #fff !important;
    opacity: 1 !important;
    .instructions {
        &-header {
            padding: 10px 10px;
            background: #fff;
            color: color(dark);
            max-width: 200px;
            margin: 0 auto;
        }
        &-body {
            background-color: color(light,.3);
            border-top: 1px solid color(light,.8);
            padding: 10px;
            ul {

                max-width: 250px;
                margin: 0 auto;
            }
            li {
                color: color(dark,.7);
                text-align: left;
                position: relative;
                padding-left: 28px;
                margin-bottom: 10px;
                line-height: 1.25;
                &:before {
                    content:  attr(data-number);
                    background-color: color(light,.5);
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: color(pale-dark);
                }
            }
        }
    }
}

.tracking-help-modal {
   ul {
    li {
       text-align: left;
       margin-bottom: 16px;
       position: relative;
       padding-left: 30px;
       &:before {
         content: attr(data-number);
         position: absolute;
         left: 0;
         top: 0;
         color: rgba(0,0,0,0.5);
       }
    }
   }
   p {
    text-align: left;
    font-size: 16px;
   }
   .modal {
    &-body {
        padding-left: 40px;
        padding-right: 40px;
    }
   }
}