.image-cropper {
    cursor: pointer;
    padding: 20px;
    border: 1px dashed rgba(106, 116, 125, 0.3);
    padding: 10px;
    border-radius: 10px;
    .image-cropper-body {
        width: fit-content;
        margin: 0 auto;
    }
    img {
        display: block !important;
        max-width: 100% !important;
        max-height: 250px !important;
        margin: 0 auto !important;
    }
}