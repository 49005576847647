.deposit-modal {
    .modal {
        &-content {
            overflow: hidden;
        }
       

    }
    .nested-modal-toggler {
       display: none;
    }
    .redirect-modal {
        padding: 20px 0;
        padding: 30px 20px;
        font-size: 12px;
        font-weight: 500;
        color: color(dark);
        .nested-modal {
            &-header,
            &-body,
            &-footer {
                border: none !important;
                padding: 0;
            }
            &-title {
                font-size: 14px;
                color: color(dark) !important;
            }
            &-body {
                padding-top: 10px;
                padding-bottom: 30px;
            }
            &-header,
            &-footer {
            }
        }
        .info-text {
            display: inline-block;
            padding: 2px 3px;
            margin-bottom: 30px;
            border-radius: 2px;
            background-color: color(warning,.15);
        }
        li {
            display: flex;
            margin-bottom: 10px;
            span {
                margin-top: 4px;
            }
           p {
                flex: 1;
           }
        }
    }
}
.deposit-component {
    .modal {
        &-title {
            margin-bottom: 10px;
            color: color(dark) !important;
        }
        &-cover {
            // position: absolute;
            // opacity: 0.5;
            // max-height: 230px;
            max-width: 150px;
            height: auto;
            margin-left: auto;
            display: block;
            margin-bottom: -15px;
            height: 60px;
      
            
        }
        // &-image {
        //     // max-width: 100px;
        //     height: 100px;
        //     width: auto;
        //     margin: 0 auto 20px;
        //     display: block;
        // }
        &-header {
            background-color: color(light,.3);
            margin-bottom: 40px;
        }
        &-body {
            padding: 0 1rem;
            margin-bottom: 50px;
            max-width: 400px;
            margin: 0 auto 70px;
          
         
        }
        &-footer {
            background-color: color(primary,.3);

        }
    }
    .modal-autocomplete {
        max-width: 100%;
    }
    .balance-btn {
        width: 100%;
        margin: 0 auto;
    }
    .balance-dropdown {
        // margin: 0 auto;
        margin-bottom: 10px;

        .dropdown-menu {
            display: flex;
            align-items: center;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 25px;
            width: 500vw;
            // max-width: 200px;
            min-width: 200px;
            width: fit-content;
            gap: 5px;
            .balance-amount {
                flex: 1;
                font-size: 12px;
                text-align: center;
                // border: 1px solid color(secondary,.1);
                background-color: color(success,.1);
                color: color(dark);
                border-radius: 5px;
                padding: 3px 6px;
            }
            .balance-text {
                font-size: 14px;
                color: color(secondary, 0.7);
                font-weight: 500;
                white-space: nowrap;
             
            }
        }
    }

    .balance-info {
        margin-bottom: 20px;
        font-size: 12px;
        color: color(muted);
    }

  
}


.dark-mode {
    .deposit-modal {
        .modal {
            &-header,
            &-footer {

                background-color: transparent;
            }
            &-cover {
                // opacity: 1;
                filter: brightness(0) invert(1);
            }
        }
        .form-check-checkbox {
            border-color: color(primary,.8);
        }
    }
}

.payment-options {
    display: flex;
    gap: 15px;
    margin-top: 15px;
    align-items: end;
  }

  .deposit-modal-divider {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    color: gray;
  }
  
  .payment-option {
    flex: 1;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .fiat {
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 100px;

    h4 {
        margin: 0 !important;
    }
  }
  
  .payment-option:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment-option.selected {
    border-color: #15a1c5;
    background-color: rgba(63, 81, 181, 0.05);
  }
  
  .payment-icon {
    margin-bottom: 15px;
  }
  
  .payment-option h4 {
    margin: 0 0 10px;
  }
  
  .payment-option p {
    margin: 0;
    font-size: 0.9em;
    color: #666;
  }
  
  .payment-method-btn {
    margin-bottom: 10px;
  }

  .payment-method-btn {
    margin: 0 !important;
  }