
.csv-btn {
    padding: 3px 10px !important;
    border-radius: 5px !important;
    // width: 32px;
    // height: 32px;
    // svg {
    //     width: 18px;
    //     height: auto;
    //     margin-right: -2px;
    // }
}

.csv-btn-link-wrapper {
    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}