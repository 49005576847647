// .ads-card {
//     background-color: #fff;
//     border-radius: 10px;
//     border: 1px solid color(secondary,.2);
//     padding: 7px 10px;
//     box-shadow: 0 0 3px color(secondary,.1);
//     // height: 155px;
//     // height: 140px;
//     min-height: 140px;
//     .card {
//         &-title {
//             font-size: 14px;
//             font-weight: bold;
//             margin-bottom: 10px;
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;
//         }
//         &-body {
//             display: flex;
//             align-items: center;
//             gap: 10px;
//             border-bottom: 2px solid color(secondary,.1);
//             padding-bottom: 3px;
//             margin-bottom: 3px;
//         }
//         &-images {
//             display: flex;
//             width: 110px;
//             height: 54px;
//             position: relative;

//         }
//         &-image {
//             border: 2px solid #fff;
//             box-shadow: 0 0 3px rgba(0,0,0,0.3);
//             border-radius: 4px;
//             position: absolute;
//             top: 0;
//             width: 50px;
//             height: 50px;
//             &:nth-child(1){
//                 left: 0;
//                 z-index: 1;
//             }
//             &:nth-child(2){
//                 left: 50%;
//                 transform: translateX(-50%);
//                 z-index: 2;
//             }
//             &:nth-child(3){
//                 right: 0;
//                 z-index: 3;
//             }
//             img {
//                 display: block;
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 border-radius: 4px;
//             }
//         }
//         &-text {
//             font-size: 12px;
//             color: color(dark);
//         }
//         &-footer {
//             &-title {
//                 font-size: 12px;
//                 color: color(muted);
//                 font-weight: 400;
//             }
//             & > .wrapper {
//                 display: flex;
//                 align-items: center;
//                 flex-wrap: wrap;
//                 gap: 0 5px;
//             }
//         }
//         &-size {
//             font-size: 12px;
//             color: #000;
//         }
//     }
// }








.ads-card {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid color(secondary,.1);
    padding: 7px 10px;
    box-shadow: 0 0 3px color(secondary,.1);
    // height: 155px;
    // height: 140px;
    // min-height: 153px;
    min-height: 163px;
    max-width: 250px;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
        &-title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
            
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-content {
                // color: color(dark);
                width: 70%;
                // white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                
            }
        }
        &-body {
            display: flex;
            align-items: center;
            gap: 10px;
            border-bottom: 2px solid color(secondary,.1);
            padding-bottom: 3px;
            margin-bottom: 3px;
        }
        &-images {
            display: flex;
            width: 100%;
            height: 100%;
            max-width: 300px;
            // position: relative;

        }
        &-image {
            border: 2px solid #fff;
            box-shadow: 0 0 3px rgba(0,0,0,0.3);
            border-radius: 4px;   
            width: 100%;
            height: 100%;
            margin-bottom: 20px;
            
            &:nth-child(1){
                left: 0;
                z-index: 1;
            }
            &:nth-child(2){
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
            }
            &:nth-child(3){
                right: 0;
                z-index: 3;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 4px;
                font-size: 10px;
            }
        }
        
        &-text {
            font-size: 12px;
            // color: color(dark);
        }
        &-footer {
            &-title {
                font-size: 12px;
                color: color(muted);
                font-weight: 400;
            }
            & > .wrapper {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 0 5px;
            }
        }
        &-size {
            font-size: 12px;
            // color: #000;
        }
    }

    .edit-title-ads-card {
        display: flex;

        input {
            padding: 2px;
        }
        svg {
            width: 14px;
            height: 14px;
            align-self: center;
            margin-left: 10px;
        }
    }

    .edit-icon-ads-card {
        margin-left: 10px;

        svg {
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    }
}


.dark-mode {
    .ads-card {
        // background-color: color(dark-mode);
        background-color: #000;
        // box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
    }
}

