.campaigns-wizard {
    .custom-error-message {
        display: flex;
        align-items: center;
        margin-top: 7px;

    svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
    }

    .accordion-error-icon {
        display: flex;
        align-items: center;
        margin-right: 7px;

        svg {
            width: 15px;
            fill: red;
            height: 15px;
        }
    }
    marquee {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        background-color: rgba(255, 244, 229, 0.5);
    }
    hr {
        background-color: color(light);
        border-top: 0;
    }

    .alert {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 12px;
        background-color: transparent;
        svg {
            width: 15px;
        }
    }
    .drawer {
        overflow-y: auto !important; //set important to avoid "JOYRIDE" overwriting it
        // border-bottom: 10px solid color(primary,.5);
        &-fullscreen {
            // margin-top: 15px;
            // height: calc(100vh - 30px);
            height: 100vh;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
    &-create {
        // .campaigns-wizard-title {
        //     color: color(primary);
        // }
        // .drawer {
        //     border-color: color(success,.3);
        // }
    }
    &-edit {
        // .drawer {
        //     border-color: color(warning,.3);

        // }
        // fd
        .campaign-wizard-tabs {
            & > .nav-tabs {
                background-color: color(warning, 0.3);
            }
        }
    }
    &-title {
        z-index: 1;
        position: absolute;
        top: 0px;
        left: 0px;
        margin: 40px;
        @include mq(768px) {
            position: fixed;
        }
    }
    .drawer {
        padding: 0;
        &-close {
            position: absolute;
            top: 0;
            right: 0;
            margin: 10px;
        }
        & > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            .tabs {
                flex: 1;
                // align-items: flex-start;
            }
            & > .tabs {
                & > .nav-tabs {
                    @include mq(768px) {
                        align-self: flex-start;
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }

    .shadow {
        box-shadow: none;
        border: none;
        padding: 0;
    }

    .tabs {
        .nav-tabs {
            margin-right: 0;
            overflow: hidden;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 100px;

            @include mq(768px) {
                width: 250px;
                // padding-top: 210px; // prev
                padding-top: 21vh; // next
            }
            @include mq(1360px) {
                width: 380px;
                padding-left: 68px;
                padding-right: 68px;
            }
            &:before {
                content: "";
                position: absolute;
                // bottom: -50px;
                // top: 520px;
                top: 65vh;
                left: 0px;

                width: 380px;
                height: 400px;
                opacity: 0.3;
                background-image: url("../../assets/images/icons/layer.png");
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                pointer-events: none;
                display: none;
                @include mq(768px) {
                    display: block;
                }
            }
            .indicator {
                right: auto;
                left: 15px;
                color: color(dark);
                // path {
                //     fill: currentColor;
                // }
                @include mq(1360px) {
                    left: 50px;
                }
            }
            .nav-item .startIcon {
                display: none;
            }
            .nav-item.marked {
                .startIcon {
                    display: block;
                }
                // &,
                // & + .nav-item,
                // & + .nav-item + .nav-item,
                // & + .nav-item + .nav-item + .nav-item {
                //     .startIcon {
                //         display: none;
                //     }
                // }
            }
            .nav-item.disabled {
                opacity: 0.3;
            }
            .nav-link {
                overflow: visible !important;
                // pointer-events: none;
                .ripple {
                    opacity: 0.01 !important;
                }
            }

            .startIcon {
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translateX(-50%);
                @include mq(768px) {
                    top: 50%;
                    transform: translateY(-50%) translateX(0);
                    left: -20px;
                }
                svg path {
                    // fill: color(success) !important;
                    stroke: color(success) !important;
                }
            }
        }
        .tab-content {
            background-color: color(light, 0.3);
            padding-top: 50px;
            padding-bottom: 100px;
            padding-left: 30px;
            padding-right: 30px;
            @include mq(768px) {
                padding-top: 0;
                padding-bottom: 30px;
            }

            .btn-group {
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;
                .btn-circle-dark {
                    width: 29px;
                    height: 29px;
                }
            }
            &-center {
                max-width: 630px;
                margin: 0 auto;
            }
        }
        .field,
        .multi-select,
        .select {
            width: 100%;
            max-width: none;
            // margin-bottom: 23px;
            &:not(&.search) {
                margin-bottom: 2.5vh;
            }
            &-toggle {
                min-height: 35px;
            }
        }
    }

    // tab 1
    .campaign-information {
        @include mq(768px) {
            // margin-top: calc(22.5vh - 38px);
            // margin-top: 22.5vh;
        }

        @include mq(992px) {
            display: flex;
            gap: 30px;
            & > .wrapper {
                flex: 1;
            }
        }
        &-block {
            border-radius: 50%;
            background-color: #E9E5DF;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 200px;
                height: auto;
            }
        }
        .row-top {
            display: flex;
            gap: 0px 30px;
            flex-direction: column;
            // align-items: flex-end;
            align-items: flex-start;
            margin-bottom: 30px;

            @include mq(1200px) {
                flex-direction: row;
            }
            // @include mq(1360px) {
            //     gap: 50px;
            // }
            .col {
                width: 100%;
                flex: 1;
            }
        }
        .row-bottom.btn-group {
            justify-content: space-between;
        }

        #targetURL {
            margin-bottom: 0px !important;
        }

        .MuiFormControlLabel-label {
            font-size: 12px !important;
        }

        .MuiCheckbox {
            padding-right: 0px !important;
        }
        .MuiCheckbox-sizeSmall {
            padding-right: 2px !important;
        }
    }

    // tab 2
    .targeting {
        @include mq(992px) {
            display: flex;
            // gap: 30px;
            align-items: flex-start;
            & > .wrapper {
                flex: 1;
            }
        }
        @include mq(768px) {
            // margin-top: 2rem; // prev
        }
        &-block {
            border-radius: 50%;
            background-color: color(success,.03);
            width: 300px;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 200px;
                height: auto;
            }
        }
        

        &-description {
            font-size: 14px;
            max-width: 413px;
            line-height: 1.5;
            margin-bottom: 50px;
        }
        .multi-select {
            width: 100%;
            margin-bottom: 23px;
            // max-width: 100%;
        }

        .row-top {
            .fields-group {
                display: grid;
                grid-gap: 0 30px;
                @include mq(1200px) {
                    grid-template-columns: 1fr 1fr;
                }
            }
            margin-bottom: 30px;
            @include mq(576px) {
                flex-direction: row;
            }
            @include mq(1360px) {
                gap: 50px;
            }
        }
    }

    // tab 3
    .upload-ads {
        .DisplayTab button svg{
            width: 24px;
        }
        .preview-header {
            display: flex;
            align-items: center;
            justify-content: space-between; /* Pushes icons to the right */
            width: 100%;
          }
          
          .preview-icons {
            display: flex;
            gap: 10px; /* Adds space between icons */
            margin-left: auto; /* Pushes icons to the right */
          }
          
          .preview-icon {
            font-size: 20px; /* Adjust icon size */
            color: #333; /* Adjust color */
            cursor: pointer;
            border: none; /* Ensure no borders */
            outline: none; /* Removes focus outline */
          }

          .preview-icon:focus {
            outline: none; /* Ensures no outline on focus */
          }

          .preview-icon.active {
            color: #007bff;
          }
          .preview-align-right.preview-mobile{
            flex-direction: column !important;
            align-items:end !important;
          }
          .preview-align-left.preview-mobile{
            flex-direction: column !important;
            align-items:start !important;
          }
          .preview-icon:hover {
            color: #007bff; /* Change color on hover */
          }
          .nativeads-body-content{
            text-align: left;
          }
          

        .custom-tooltip {
            text-align: left !important;
        }
        @include mq(768px) {
            // margin-top: 7vh;
        }
        &-container {
            // max-width: 400px;
            // margin: 0 auto;
        }
        &-title {
            font-size: 20px;
            text-align: center;
            margin-bottom: 10px;
            background-color: transparent;
            border: none;
            outline: none;
            // text-align: center;
            border-radius: 4px;
            padding: 6px;
            width: 100%;
            // display: inline-block;
            // max-width: auto;
        }
        &-subtitle {
            max-width: 260px;
            margin: 0 auto;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            margin-bottom: 10px;
            opacity: 0.5;
        }
        .row-upload-image {
            .upload-image {
                & > .wrapper {
                    margin: 0;
                }
            }
        }

        .border-dashed {
            border-radius: 10px;
            padding: 15px 10px;
            // background-color: #fff;
            max-width: 247px;
            margin: 0 auto;
            text-align: center;
        }

        .row-creatives {
            padding: 25px;
            background-color: #fff;
            border-radius: 10px;
            .swiper {
            }
            .carousel {
                // max-width: 400px;
                width: 100%;
                margin-bottom: 0;
            }
        }
        .create-btn {
            // text-transform: none;
            // margin: 0 auto;
            // margin-left: auto;
            // display: flex;
            // min-width: 130px;
        }
        .tab-inner {
            display: flex;
            flex-direction: column;
            gap: 40px 20px;
            .col-4 {
                width: 40%;
            }
            .col-8 {
                width: 60%;
            }
            .col-left {
                top: 30px;
            }
            @include mq(992px) {
                align-items: flex-start;
                flex-direction: row;
                .col-left {
                    position: sticky;
                    order: 1;
                }
            }
        }
        .creative-ads-tabs {
            all: unset;
            .divider {
                display: none;
            }
            & > .nav-tabs {
                width: fit-content;
                margin: 0 auto;
                gap: 20px 10px;
                display: flex !important;
                flex-direction: row;
                padding: 0;
                overflow: visible;
                margin-bottom: 10px;

                &:before {
                    display: none;
                }
                @include mq(768px) {
                    justify-content: center;
                }
                .indicator {
                    // right: auto;
                    background-color: color(primary);
                    width: 170px;
                    height: 70px;
                    border-radius: 10px;
                    top: 0 !important;
                    right: auto !important;
                    // left: auto !important;
                    bottom: auto !important;
                    transform: none;
                    svg {
                        display: none;
                    }
                    display: none;
                    @include mq(768px) {
                        display: block;
                    }
                }
                .nav-item {
                    .btn {
                        border-radius: 10px;
                        .startIcon {
                            all: unset;
                        }
                    }
                    @include mq(768px) {
                    }
                }

                .nav-item.active {
                    &,
                    & + .nav-item,
                    & + .nav-item + .nav-item,
                    & + .nav-item + .nav-item + .nav-item {
                        .startIcon {
                            // display: none;
                        }
                    }
                }
                .nav-link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                    padding: 0;
                    margin: 0;
                    flex-direction: column;
                    font-size: 12px;
                    color: color(secondary);
                    font-weight: 600 !important;
                    white-space: wrap;
                    line-height: 1.3;
                    // gap: 10px;
                    // padding: 20px;
                    z-index: 2;
                    height: 100%;
                    transition: 0.3s ease;
                    height: 100px;
                    // @include mq(768px) {
                    height: 70px;
                    width: 170px;
                    font-size: 13px;
                    // }
                    &.active {
                        color: #fff;
                        background-color: color(primary);
                        @include mq(768px) {
                            background-color: transparent;
                        }
                    }
                    .startIcon {
                        margin: 0 auto;
                        display: block;
                    }
                    svg {
                        width: 20px !important;
                        height: 20px !important;
                        path {
                            fill: currentColor !important;
                        }
                    }
                }

                .startIcon {
                }
            }
            .tab-content {
                all: unset;
            }

            .field {
                & > .wrapper {
                    justify-content: flex-start;
                }
            }

            .upload-ads-tab {
                background-color: #fff;
                padding: 25px;
                border-radius: 10px;
                &-title {
                    // color: color(primary);
                    text-align: center;
                    font-weight: 500;
                    margin-bottom: 50px;
                    // position: relative;
                    // &:after {
                    //     content: "";
                    //     position: absolute;
                    //     top: 100%;
                    //     left: 50%;
                    //     transform: translateX(-50%);
                    //     width: 100%;
                    //     height: 1px;
                    //     margin-top: 10px;
                    //     border-radius: 100px;
                    //     background-color: color(light, 0.5);
                    // }
                }
                &-inner {
                }
                .upload-ads-container {
                    flex: 1;
                }
                .upload-ads-preview {
                    .wrapper {
                        // width: 220px;
                        // height: 170px;
                        border-radius: 0;
                        width: 300px;
                        height: 200px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .create-ads-tab {
                background-color: #fff;
                padding: 25px;
                border-radius: 10px;
                &-title {
                    color: color(primary);
                    text-align: center;
                    font-weight: 500;
                    margin-bottom: 50px;
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 1px;
                        margin-top: 10px;
                        border-radius: 100px;
                        background-color: color(light, 0.5);
                    }
                }
                &-inner {
                    position: relative;

                    .accordion {
                        flex: 1;
                        .accordion-button {
                            white-space: nowrap;
                        }
                        &-item {
                            border: 1px solid color(light);
                            .custom-color-field-wrapper {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                .custom-color-field {
                                    margin-top: 10px;
                                    margin-bottom: 0 !important;
                                    .form-label {
                                        line-height: 1;
                                        margin-bottom: 3px;
                                    }
                                    & > .wrapper {
                                        margin-bottom: 0;
                                    }
                                    .custom-tooltip {
                                        max-width: 180px !important;
                                    }
                                }
                                .btn-circle-dark {
                                    position: absolute;
                                    top: 50%;
                                    right: 5px;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                }
                .size-banners-group {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 10px;
                }
                .size-banner {
                    background-color: #fff;
                    border-radius: 10px;
                    border: 1px solid color(dark, 0.1);
                    padding: 10px;
                    font-size: 12px;
                    text-align: center;
                    font-weight: 500;
                    line-height: 1.3;
                    min-height: 140px;
                    display: flex;

                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
                    img {
                        border-radius: 7px;
                        overflow: hidden;
                    }

                    &-320x100 {
                        img {
                            width: 100px;
                        }
                    }
                    &-728x90 {
                        img {
                            width: 100px;
                        }
                    }
                    &-300x250 {
                        img {
                            width: 50px;
                        }
                    }
                    &-320x50 {
                        img {
                            width: 100px;
                        }
                    }
                    &-900x600 {
                        img {
                            width: 100px;
                        }
                    }
                    &-nativeads {
                        img {
                            width: 100px;
                        }
                    }
                    &:hover {
                        // transform: scale(1.05);
                        border-color: color(dark, 0.3);
                    }
                    &.active {
                        background-color: color(primary);
                        color: #fff;
                    }
                    .banner {
                        background-color: color(secondary, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: 9px;
                        margin: 0 auto;
                        margin-bottom: 10px;
                        line-height: 1;
                    }
                }
                .btn-group {
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    .btn {
                        white-space: nowrap;
                    }
                }
                .react-colorful {
                    width: 100%;
                    margin: 0 auto;
                    // margin-top: 10px;
                }
                .creatives-customization-tabs {
                    display: block;
                    .nav-tabs {
                        padding: 0;
                        display: flex;
                        width: 100%;
                        flex-direction: row;
                        gap: 5px;
                    }
                    .nav-link {
                        margin-bottom: 15px;
                        border-radius: 5px;
                        font-size: 12px;
                        justify-content: center;
                        &:hover {
                            background-color: color(primary, 0.1);
                        }
                        &.active {
                            background-color: color(primary);
                            color: #fff;
                        }
                    }
                }
            }

            .preview {
                // pointer-events: none;
                overflow-x: auto;
                overflow-y: auto;
                // width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.06);
                // padding: 30px 10px;
                border-radius: 10px;
                background-color: rgba(255, 255, 255, 0.3);
                // min-height: 200px;
                // max-height: 350px;
                margin: 0 auto;
                // max-width: auto;
                display: flex;
                flex-direction: column;
                // background-color: #fff;
                position: relative;
                z-index: 2;
                // margin-bottom: 20px;
                background-color: color(light, 0.3);
                &.hidden-preview {
                    position: fixed;
                    z-index: -100;
                    opacity: 0;
                    pointer-events: none;
                }
                @include mq(1280px) {
                    // max-width: 250px;
                }
                &-title {
                    // text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    // margin-bottom: 20px;
                }

                &-header {
                    padding: 20px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
                &-body {
                    padding: 30px 20px ;
                }
                &-body-native {
                    padding: 30px 20px ;
                }
                &-footer {
                    // padding: 20px 10px;
                    // padding-top: 0;
                    // flex: 1;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                }
                .wrapper {
                    // pointer-events: none;
                    // border: 1px solid #999;
                    border-radius: 15px;
                    margin: 0 auto;
                    overflow: hidden;
                    // box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
                    position: relative;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    &-300x250 {
                        // height: 250px;
                        // width: 300px;
                        width: 320px;
                        height: 250px;
                        .preview-content-body {
                            margin-bottom: 4px;
                        }
                        .preview-content {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            // align-items: center;
                            // font-size: 16px;
                            &-logo {
                                // width: 80px;
                                // width: auto !important;
                                // height: 45px;
                                // object-fit: contain;
                                margin-top: 4px;
                                margin-bottom: 2px;
                                margin-left: auto;
                                margin-right: auto;
                                // background-color: #fff;
                                width: fit-content;

                                img {
                                    // width: 100%;
                                    // height: 100%;
                                    // object-fit: contain;
                                    height: 45px;
                                    width: auto;
                                }
                            }
                            &-title {
                                font-size: 14px;
                                margin-bottom: 1px;
                            }
                            &-description {
                                font-size: 11px;
                                margin-bottom: 5px;
                            }
                            .btn {
                                font-size: 8px;
                                min-width: 100px;
                                background-color: #fff !important;
                                color: color(dark);
                                min-width: auto !important;
                                padding: 2px 4px;
                                padding-right: 2px;
                                padding-left: 5px;
                                border-radius: 50px;
                                svg {
                                    height: 10px;
                                }
                            }
                            &-footer {
                                width: 80%;
                                padding-top: 40%;
                                border-top-right-radius: 15px;
                                border-top-left-radius: 15px;
                            }
                        }
                    }
                    &-320x100 {
                        // width: 320px;
                        // height: 100px;
                        width: 320px;
                        height: 100px;
                        border-radius: 5px !important;
                        .preview-content-body {
                            margin: 5px;
                        }
                        .preview-content {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            // font-size: 16px;
                            &-logo {
                                // width: 45px;
                                // height: 80px;
                                // height: auto;
                                // object-fit: contain;
                                margin-left: 5px;
                                img {
                                    // width: 100%;
                                    // height: 100%;
                                    // object-fit: contain;
                                    width: 45px;
                                    height: auto;
                                }
                            }
                            &-title {
                                font-size: 14px;
                                margin-bottom: 2px;
                            }
                            &-description {
                                font-size: 12px;
                                margin-bottom: 6px;
                            }
                            .btn {
                                font-size: 8px;
                                min-width: 100px;
                                background-color: #fff !important;
                                color: color(dark);
                                min-width: auto !important;
                                padding: 2px 4px;
                                padding-right: 2px;
                                padding-left: 5px;
                                border-radius: 50px;
                                .endIcon {
                                    margin-left: 2px !important;
                                }
                                svg {
                                    height: 8px;
                                }
                            }
                            &-footer {
                                width: 30%;
                                padding-top: 28%;
                                margin: 0 !important;
                                border-top-left-radius: 15px;
                                border-bottom-left-radius: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                    &-728x90 {
                        // width: 320px;
                        // height: 100px;
                        width: 500px;
                        height: 62px;
                        // width: 728px;
                        // height: 90px;

                        border-radius: 5px !important;
                        .preview-content-body {
                            margin: 0 5px;
                        }
                        .preview-content {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            // font-size: 16px;
                            &-logo {
                                // width: 45px;
                                // height: 50px;
                                // object-fit: contain;
                                margin-left: 5px;
                                img {
                                    // width: 100%;
                                    // height: 100%;
                                    // object-fit: contain;
                                    width: 45px;
                                    height: auto;
                                }
                            }
                            &-title {
                                font-size: 14px;
                                margin-bottom: 0px;
                            }
                            &-description {
                                font-size: 12px;
                                margin-bottom: 3px;
                            }
                            &-footer {
                                width: 30%;
                                padding-top: 11%;
                                margin: 0 !important;
                                border-top-left-radius: 15px;
                                border-bottom-left-radius: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .btn {
                                font-size: 8px;
                                min-width: 100px;
                                background-color: #fff !important;
                                color: color(dark);
                                min-width: auto !important;
                                line-height: 1.5;
                                padding-top: 2px;
                                padding-bottom: 2px;
                                padding-left: 5px;
                                padding-right: 2px;
                                border-radius: 50px;
                                .endIcon {
                                    margin-left: 2px !important;
                                }
                                svg {
                                    height: 8px;
                                }
                            }
                        }
                    }
                    &-320x50 {
                        // width: 320px;
                        // height: 100px;
                        width: 320px;
                        height: 50px;
                        border-radius: 5px !important;
                        .preview-content-body {
                            margin: 0 5px;
                        }
                        .preview-content {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            // padding: 3px !important;
                            // font-size: 16px;

                            &-logo {
                                // width: 45px;
                                // height: 80px;
                                // object-fit: contain;
                                margin-left: 5px;
                                img {
                                    // width: 100%;
                                    // height: 100%;
                                    // object-fit: contain;
                                    width: 45px;
                                    height: auto;
                                }
                            }
                            &-title {
                                font-size: 11px;
                                margin-bottom: 0px;
                                line-height: 1;
                            }
                            &-description {
                                font-size: 9px;
                                margin-bottom: 2px;
                            }
                            &-footer {
                                width: 30%;
                                padding-top: 13%;
                                // border-radius: 5px ;
                                border-top-left-radius: 12px;
                                border-bottom-left-radius: 12px;

                                margin: 0 !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .btn {
                                font-size: 8px;
                                min-width: 100px;
                                background-color: #fff !important;
                                color: color(dark);
                                min-width: auto !important;
                                line-height: 1.5;
                                padding: 0 2px;
                                padding-left: 5px;
                                padding-right: 2px;
                                border-radius: 50px;
                                .endIcon {
                                    margin-left: 2px !important;
                                }
                                svg {
                                    height: 8px;
                                }
                            }
                        }
                    }
                    &-900x600 {
                        // height: 250px;
                        // width: 300px;
                        width: 900px;
                        height: 600px;

                        .preview-content {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            // align-items: center;
                            // font-size: 16px;
                            &-logo {
                                width: 120px;
                                height: 80px;
                                // object-fit: contain;
                                margin-top: 4px;
                                margin-bottom: 2px;
                                margin-left: auto;
                                margin-right: auto;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            &-title {
                                font-size: 24px;
                                margin-bottom: 10px;
                            }
                            &-description {
                                font-size: 18px;
                                margin-bottom: 15px;
                            }
                            &-footer {
                                width: 80%;
                                padding-top: 37%;
                                border-top-left-radius: 15px;
                                border-top-right-radius: 15px;
                            }
                            .btn {
                                font-size: 16px;
                                background-color: #fff !important;
                                color: color(dark, 0.8);
                                min-width: auto !important;
                                line-height: 1.5;
                                padding: 5px 0px;
                                padding-left: 15px;
                                padding-right: 12px;
                                border-radius: 50px;
                                svg {
                                    height: 20px;
                                }
                            }
                        }
                    }
                    &-nativeads {
                        // width: 320px;
                        // height: 100px;
                        width: auto;
                        height: auto;
                        border-radius: 5px !important;
                        .preview-content-body {
                            margin: 0 5px;
                        }
                        .preview-align-right{
                            flex-direction: row-reverse;
                        }
                        .preview-content-logo-left{
                            text-align: left;
                        }
                        .preview-content-logo-right{
                            text-align: right;
                        }
                        .nativeads-body-content{
                            word-wrap: break-word;
                        }
                        .preview-content-right{
                            // display: flex;
                            // flex-direction: column; 
                            // align-items: center;
                            width: 50%;
                            flex-shrink:0;
                        }
                        .preview-mobile .preview-content-right{
                            width: 100%;
                        }
                        .preview-mobile .nativeads-body-content{
                            // margin-left: 8px;
                            padding: 0 6px;
                        }
                        .preview-content-right .first_image{
                            width: 70px; /* Adjust size */
                            object-fit: contain;
                        }
                        .preview-content-right .second_image{
                            width: 100%; /* Adjust size */
                            max-height: 300px;
                            object-fit: cover;
                        }
                        .preview-content {
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            // padding: 3px !important;
                            // font-size: 16px;

                            &-logo {
                                // width: 45px;
                                // height: 80px;
                                // object-fit: contain;
                                margin-left: 5px;
                                img {
                                    // width: 100%;
                                    // height: 100%;
                                    // object-fit: contain;
                                    width: 45px;
                                    height: auto;
                                }
                            }
                            &-title {
                                font-size: 11px;
                                margin-bottom: 0px;
                                line-height: 1;
                            }
                            &-description {
                                font-size: 9px;
                                margin-bottom: 2px;
                            }
                            &-footer {
                                width: 30%;
                                padding-top: 13%;
                                // border-radius: 5px ;
                                border-top-left-radius: 12px;
                                border-bottom-left-radius: 12px;

                                margin: 0 !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .btn {
                                font-size: 10px;
                                min-width: 100px;
                                color: #fff;
                                min-width: auto !important;
                                line-height: 1.5;
                                padding: 8px;
                                border-radius: 0px;
                                margin-top: 8px;
                                margin-bottom: 9px;
                                .endIcon {
                                    margin-left: 2px !important;
                                }
                                svg {
                                    height: 8px;
                                }
                            }
                        }
                    }
                }
                .preview-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    // padding: 10px;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    .preview-content-body {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .preview-content-title {
                        color: #fff;
                        font-weight: 600;
                    }
                    .preview-content-title-native {
                        color: rgb(6 23 38);
                        font-size: 14px;
                        // font-family: Inter !important;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    .preview-content-description {
                        color: #fff;
                        
                    }
                    .preview-content-description-native {
                        color: rgb(6 23 38);
                        font-size: 14px;
                        // font-family: Inter !important;
                        margin-bottom: 10px;
                    }
                    .text-overflow {
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* Number of lines to show */
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .btn {
                        // line-height: 1;
                        width: fit-content;
                        min-width: 100px;
                        white-space: nowrap;
                        border: none !important;
                        border-radius: 4px !important;
                        //    font-size: 12px;
                        // width: 100%;
                        // max-width: 120px;
                        // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
                        // border: 1px solid color(dark, 0.3);
                    }
                    .preview-content-footer {
                        // border-radius: 10px;
                        // max-width: 160px;

                        height: 0;
                        display: block;
                        background-color: #fff;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;
                        // padding: 10px 5px;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            object-fit: cover;
                        }
                    }
                }
            }
            .reference-text {
                color: color(muted);
                font-size: 14px;
                margin-bottom: 10px;
                background-color: color(success);
                color: #fff;
                padding: 5px 12px;
                padding-top: 7px;
                font-weight: 600;
                // border-radius: 5px;
                display: inline-block;
                clip-path: polygon(0 0%, 100% 12%, 100% 100%, 0 100%);
                display: inline-flex;
                align-items: center;
                gap: 5px;
                svg {
                    width: 15px;
                    height: auto;
                    display: block;
                }
            }
        }
    }

    // tab 4
    .summary {
        @include mq(768px) {
            // margin-top: 10vh;
        }
        &-title {
            margin-bottom: 35px;
        }
        .row-top {
            display: flex;
            gap: 60px 20px;
            flex-direction: column;
            margin-bottom: 30px;
            @include mq(576px) {
                flex-direction: row;
            }
            @include mq(1360px) {
                gap: 50px;
            }
            .col {
                width: 100%;
                flex: 1;
            }
        }

        .title {
            font-size: 16px;
            color: color(muted);
            margin-bottom: 18px;
        }

        .row-top .col > .wrapper {
            margin-bottom: 18px;
        }
        // .row-middle {
        //     max-width: 500px;
        //     margin: 0 auto;
        //     @include mq(1360px) {
        //         max-width: 100%;
        //     }
        // }

        .th {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 5px;
        }
        .td {
            font-size: 14px;
            max-width: 200px;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
        .submit-btn {
            text-transform: none;
        }
    }

    .multi-select {
        max-width: 100%;
    }

    .carousel {
        // margin-bottom: 40px;
    }
    .carousel-title {
        color: color(muted);
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    .carousel-angles {
        left: auto;
        top: auto;
        bottom: 100%;
        right: 0;
        margin-bottom: 10px;
    }
    .carousel-angle {
        background-color: #fff;

        svg path {
            fill: color(dark) !important;
        }
    }
}

#creatives-modal {
    .modal {
        &-content {
            padding: 20px;
            position: relative;
            overflow: hidden;
        }
        // position: fixed !important;
    }
    .file-image {
        width: 100%;
        height: 100%;
        display: block;
    }

    .modal-body {
        margin: 0 auto;

        img {
            max-height: 450px;
            object-fit: cover;
            margin: 0 auto;
        }
    }

    .modal-footer {
        .error-message {
            margin-bottom: 30px;
        }
    }
}

.geo-modal {
    .modal-content {
        min-height: calc(100vh - 40px);
        padding: 30px 20px;
        border-radius: 10px;
    }
    .modal-header {
        padding: 0;
        padding-top: 30px;
    }
    .modal-body {
        margin-bottom: 0;
    }
}

.geo-modal-toggler {
    width: 100%;
    // background-color: color(primary,.06);
    justify-content: space-between;
    background-color: #fff;
    &:hover {
        background-color: #fff;
    }
    .ripple {
        display: none !important;
    }
    // &:hover {
    //     background-color: color(primary,.1);
    // }
}

.geo-modal-cover {
    opacity: 0.5;
    display: block;
    max-width: 300px;
    margin: 0 auto;
}

.campaign-information-modal {
    max-width: 450px;
    .btn-group {
    }
    .modal {
        &-body {
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.assisted-campaign-switch {
    margin-left: auto;
    display: flex;
    width: fit-content;
    margin-bottom: 20px;
    position: absolute;
    top: 33px;
    left: 237px;
    @include mq(768px) {
        position: fixed;
        top: 400px;
        left: 45px;
    }
}

.wizard-block {
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
}

.summary-block {
 
    &-content {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        .col {
            flex: 1;
            min-width: 220px;
        }
    }
    &-title {
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: 600;
    }
    .wrapper {
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
        &-title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 7px;
        }
        &-value {
            font-size: 15px;
            color: color(muted);
            font-weight: 500;
            line-height: 1.6;
            overflow-x: hidden;
            text-overflow: ellipsis;

            &.line-ellipsis {
                display: -webkit-box;
                -webkit-line-clamp: 3; /* Number of lines before the ellipsis */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 7.5em; /* Adjust based on line-height (5 lines * line-height) */
                line-height: 1.5em; /* Adjust to your line-height */
              
            }
        }
    }
}


#tooltip-summary-block {
    max-width: 500px;
    line-height: 1.6;
}


.custom-wizard-block {
    background-color: #fff;
    border-radius: 10px;

    .wizard-block-header {
        border-bottom: 1px solid #DADCE0;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .arrow-down-icon {

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: #66768E;
            }
        }
    }

        p {
            font-size: 14px;
            color: #66768E;
        }
    }

    .wizard-block-body {
        // padding: 25px;
    }
}

.multi-select-container {
    display: flex;
    flex-wrap: wrap;
    display: block;
    width: 100%;
    padding: 5px 12px;
    background: none;
    outline: none;
    border-width: 1px;
    border-style: solid;
    color: rgba(6, 23, 38, 1);
    border-color: rgba(0, 0, 0, 0.23);
    appearance: none;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .tags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
      
      .tag {
        display: flex;
        align-items: center;
        background-color: rgba(21, 161, 197,1);
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 14px;
        color: #ffff;
      }
      
      .remove-tag {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(6, 23, 38, 0.3);
        margin-right: 5px;

        svg {
            width: 8px;
            height: 8px;

            path {
                fill: #ffff;
            }
        }
        // color: rgba(6, 23, 38,0.3);
      }
      
      .tag-input {
        border: none;
        font-family: Inter !important;
        background: transparent;
        outline: none;
        flex-grow: 1;
        padding: 1px;
        line-height: 1.5;
        font-size: 14px;
        font-weight: 400;
      }
      
      .tag-input::placeholder {
        color: #888;
      }
      
  }
  
  .estimates-wrapper {
    margin-top: 4rem;
}

@media screen and (min-width: 700px) {
    .create-ads-tab-inner .col-right {
        width: 50%;
    }
    .campaigns-wizard .upload-ads .tab-inner .preview-banner-div{
        width: 48%
    }
}
@media screen and (max-width: 1000px) {
    .create-ads-tab-inner .col-right {
        width: 50%;
    }
    .campaigns-wizard .upload-ads .tab-inner .preview-banner-div{
        width: 100%
    }
}
.create-ads-tab-inner .field textarea{
    font-family: Inter !important;
}
@media screen and (max-width: 580px) {
    .estimates-wrapper {
        margin-top: 0rem;
    }    
}

@media screen and (max-width: 991px) {
    .tab-inner {

        .col-4 {
            width: 100% !important;
        }
        .col-8 {
            width: 100% !important;
        }
    }

}

.custom-ads-name-input {
    max-width: 300px !important;
    padding-left: 30px !important;
}

.alignment-coantiner {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 20px;

    .inactive-element {
        cursor: pointer;
        border: 1px solid rgba(106, 116, 125, 1);
        padding: 4px 20px;
        color: gray;
        border-radius: 5px;
        font-size: 14px;
        text-transform: capitalize;
    }

    .active-element {
        background-color: #1f8352 !important;
        border: none !important;
        color: white !important;
    }
}


.preview-body-native {
    max-width: 80%;
    margin: 0 auto;
}