// .fc {
//     &.fc-media-screen {
//         width: 70vw;
//         max-width: 350px;
//         margin: 5px;
//     }
//      .fc-toolbar.fc-header-toolbar {
//         flex-wrap: wrap;
//     }
//     .fc-daygrid-body.fc-daygrid-body-unbalanced {
//         // height: 100px !important;
//     }
//     .fc-view-harness.fc-view-harness-active {
//         height: 200px !important;
//     }
//     .fc-scrollgrid-sync-table {
//         height: 100px !important;
//     height: 100% !important;
//     }
//     .fc-toolbar-title {
//         font-size: 16px;
//         white-space: nowrap;
//         margin-right: 10px;
//     }
//     .fc-button {
//         font-size: 10px;
//     }
//     .fc-toolbar-chunk {
//         display: flex;
//     }
//     .fc-col-header-cell-cushion {
//         font-size: 12px;
//     }
//    .fc-daygrid-day-number {
//     font-size: 12px;
//    }
//    .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
//     min-height: 0;
//    }
//    .fc-day {
//     transition: .3s ease;
//     cursor: pointer;
//     &:hover {
//         background-color: color(primary,.8);
//     }
//     &:active {
//         background-color: color(primary);
//     }
//     .fc-daygrid-day-top {
//         justify-content: center;
//     }
//    }
// }
.react-date-range-picker{

    .custom-labels {
        
        display: flex;
        gap: 5px;
        margin-top: 10px;
        margin-bottom: 17px;
        justify-content: center;
        .btn {
            font-size: 12px;
            font-weight: 400;
            &.active {
                // background-color: color(primary);
                color: color(primary);
                border-color: color(primary);
            }
        }
    }
    .rdrDateRangeWrapper {
        font-size: 10px; 
    }
    .rdrDefinedRangesWrapper {
        width: 120px ;
        display: none; 
        @include mq(768px) {
            display: block;
        }
    }
    
    .rdrInputRanges {
        // display: none;
    }
    .rdrInputRange {
        // display: block;
        flex-direction: column;
        padding: 0 3px;
        &:nth-child(2){
            display: none;
        }
        input {
            order: 2;
        }
        span {
            margin-top: 10px;
            order: 1;
            // font-size: 8px !important;
            font-weight: 500;
            // text-transform: uppercase;
            margin-bottom: 6px;
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
    .rdrInputRangeInput {
        // width: 18px;
        // height: 18px;
        // line-height: 18px;
        margin-right: 0px;
        width: 100%;
    }
    
    .rdrDateDisplay input {
        font-size: 10px;
    }
    .rdrMonthAndYearWrapper {
        padding-right: 0px;
        padding: 0 8.3px;
        width: 100%;
    }
    .rdrMonthAndYearPickers select {
        padding: 10px 24px 10px 10px;
        font-size: 12px;
    }
    .rdrNextPrevButton{
        margin: 0 6px;
    }
}

.calendar-dropdown {
    position: relative;
    z-index: 2;
    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}