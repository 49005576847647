.basic-chat {
    color: #000;
    // box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
    display: flex;
    flex-direction: column;
    // height: 100%;
    border-radius: 6px;
    overflow: hidden;
    font-family: Poppins;
    &-title {
        text-transform: none;
        font-size: 16px;
        // padding: 0 10px;
        padding-bottom: 5px;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        // box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
        @include mq(768px) {
            font-size: 18px;
        }
    }
    &-header {
        // padding: 10px 0;
        // background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-body {
        flex: 1;
        position: relative;
        padding: 10px 0;
        padding-right: 10px;
        // background-color: rgb(246, 246, 247);

        .chat-log {
            padding: 30px 0px;
            // padding-bottom: 50px;
            overflow-y: auto;
            // height: 500px;
            scroll-behavior: smooth;

            // makes basic-chat-log height relative to parent height
            &.use-parent-height {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100% !important;
            }

            //

            .load {
                max-width: 80px;
            }
            &::-webkit-scrollbar {
                // display: none;
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 50px;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.2);
                }
                &:active {
                    background-color: rgba(0, 0, 0, 0.3);
                }
            }
        }

        .message {
            margin-bottom: 1.2rem;
            width: 100%;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 12px;
            color: #000;

            margin-bottom: 45px;
            &-icon {
                overflow: hidden;
                border: 2px solid #e3e3e3;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                object-fit: cover;
                display: block;
            }
            .col {
            }
            &.position {
                // &-right {
                //     align-items: flex-end;
                //     .details {
                //         &-date {
                //         }
                //     }
                //     .message {
                //         &-text {
                //             background-color: rgb(32, 0, 240);
                //             color: #fff;
                //             // text-align: right;
                //         }
                //     }
                // }
            }
            .details {
                display: flex;
                align-items: center;
                // justify-content: flex-end;
                gap: 6px;
                font-size: 12px;
                // color: rgb(112, 112, 112);
                margin-bottom: 5px;

                &-identity {
                    font-weight: bold;
                    // color: color(dark);
                    text-transform: capitalize;
                    font-size: 15px;
                }
                &-date {
                    text-align: right;
                    font-size: 10px;
                    font-weight: 500;
                    align-self: flex-start;
                    // color: rgba(0,0,0,0.5);
                }
            }
            &-text {
                padding: 0px 0;
                border-radius: 6px;
                // background-color: #fff;
                // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
                // color: color(secondary);
                font-size: 14px;
                font-weight: 500;
                // max-width: 350px;
                // max-width: 550px;
                // line-height: 1.9;

                line-height: 1.5;
                // font-family: "Noto Sans", sans-serif;
            }
        }
    }
    &-footer {
        // background-color: #fff;
        .container {
            & > .wrapper {
                position: relative;
                .field {
                    margin-bottom: 0;
                    flex: 1;
                    .form-control {
                        // border: 1px solid color(dark,.3);
                        // border-radius: 15px;
                        // border: none;
                        padding: 15px;
                        padding-right: 80px;
                        &::placeholder {
                            text-transform: none;
                        }
                    }
                }
                .send-btn {
                    // padding: 3px 5px;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 15px;
                    min-width: auto;
                    // background-color: rgb(32, 0, 240);

                    svg {
                        height: 20px;
                        width: auto;
                    }
                }
            }
        }
    }
}

// PRELOADER

.bouncing-loader {
    display: flex;
    padding: 10px 30px;
}

.bouncing-loader > div {
    width: 10px;
    height: 10px;
    margin: 3px 3px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-7px);
    }
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}

.basic-chat .chatbot {
    color: #353740;
    line-height: 1.6;
    font-weight: 400;
    ol {
        margin-top: 16px;
        margin-bottom: 16px;
        counter-reset: ordered-listitem;
     
       & > li {
            line-height: 1.6;
            padding-left: 32px;
            position: relative;
            &:after {
                background: #ececf1;
                border-radius: 20px;
                color: #6e6e80;
                font-size: 12px;
                font-weight: 400;
                height: 20px;
                line-height: 19px;
                padding-top: 1px;
                text-align: center;
                width: 20px;
    
                content: counter(ordered-listitem);
                counter-increment: ordered-listitem;
                left: 0;
                position: absolute;
                top: 0;
            }
            &:not(:first-of-type){
                margin-top: 8px;
    
            }
        }
    }

    a {
        color: #007bff !important; /* Link color */
        text-decoration: none !important;
    }

    a:hover {
        text-decoration: underline !important;
    }

    strong {
        font-weight: bold !important;
    }

    em {
        font-style: italic !important;
    }

    u {
        text-decoration: underline !important;
    }

    s {
        text-decoration: line-through !important;
    }

    code {
        font-family: Consolas, monospace;
        background-color: #f0f0f0;
        padding: 2px 4px;
        border-radius: 3px;
    }

    .code {
        background-color: color(dark) !important;
        color: #fff !important;
    }
}

