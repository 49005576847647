.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .confirmation-modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    max-width: 100%;
    text-align: center;
  }
  .confirmation-modal p
  {
    margin: 25px auto;
  }
  
  .confirmation-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .confirmation-modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-button {
    background-color: #007bff;
    color: white;
  }
  
  .cancel-button {
    background-color: #6c757d;
    color: white;
  }
  
  .confirm-button:hover, .cancel-button:hover {
    opacity: 0.8;
  }