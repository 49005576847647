.privacy-policy-page {
    max-width: 100%;
    min-height: 100vh;
    padding: 0;
    // .navbar {
    //     top: 0;
    // }
    .bar {
        padding: 5px 0;
    }
    // & > hr {
    //     display: none;
    // }
    .showcase {
        height: 250px;
        background-image: linear-gradient(to top, color(success, 0.3) 50%, #fff 110%);
        display: flex;
        align-items: center;

        justify-content: center;
    }
    .container {
        max-width: 1000px;
    }
    // .privacy-policy-title {
    //     color: color(dark);
    //     text-align: center;
    // }
    // .title {
    //     font-size: 24px;
    //     margin-bottom: 13px;
    // }
    // .description {
    //     font-size: 16px;
    //     color: color(dark);
    // }
    // h2 {
    //     margin-bottom: 20px;
    // }

    // h3 {
    //     font-size: 17px;
    //     font-weight: 500;
    //     padding-top: 21px;
    //     padding-bottom: 21px;
    //     position: relative;
    //     margin-bottom: 20px;
    //     text-transform: uppercase;
    //     &:before,
    //     &:after {
    //         content: "";
    //         position: absolute;
    //         width: 100%;
    //         height: 1px;
    //         background-color: rgba(0, 0, 0, 0.05);
    //         left: 0;
    //     }
    //     &:before {
    //         top: 0;
    //     }
    //     &:after {
    //         bottom: 0;
    //     }
    // }
    // h4 {
    //     margin-bottom: 8px;
    // }
    // h5 {
    //     font-size: 17px;
    //     font-weight: 600;
    //     margin-bottom: 8px;
    // }

    // h6 {
    //     font-size: 12px;
    //     font-weight: 600;
    //     margin-bottom: 5px;
    //     margin-top: 16px;
    // }

    // p {
    //     font-size: 13px;
    //     color: color(secondary);
    //     line-height: 19px;
    //     margin-bottom: 8px;
    // }

    // hr {
    //     border-color: rgba(0, 0, 0, 0.05);
    //     margin-top: 20px;
    //     margin-bottom: 20px;
    // }

    // li {
    //     font-size: 13px;
    //     list-style: circle;
    //     margin-bottom: 5px;
    // }
}

.iubenda_policy  {
    font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    color: #505762;
    html,
    body {
        margin: 0;
        padding: 0;
    }
    html {
        overflow-y: scroll;
        font-size: 100%;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    dd,
    dl,
    dt,
    li,
    ol,
    ul,
    fieldset,
    form,
    label,
    legend,
    button,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        margin: 0;
        padding: 0;
        border: 0;
        font-weight: normal;
        font-style: normal;
        font-size: 100%;
        line-height: 1;
        font-family: inherit;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    ol,
    ul {
        list-style: none;
    }
    q:before,
    q:after,
    blockquote:before,
    blockquote:after {
        content: "";
    }
    a:focus {
        outline: thin dotted;
    }
    a:hover,
    a:active {
        outline: 0;
    }
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    nav,
    section {
        display: block;
    }
    audio,
    canvas,
    video {
        display: inline-block;
        *display: inline;
        *zoom: 1;
    }
    audio:not([controls]) {
        display: none;
    }
    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    sup {
        top: -0.5em;
    }
    sub {
        bottom: -0.25em;
    }
    img {
        border: 0;
        -ms-interpolation-mode: bicubic;
    }
    button,
    input,
    select,
    textarea {
        font-size: 100%;
        margin: 0;
        vertical-align: baseline;
        *vertical-align: middle;
    }
    button,
    input {
        line-height: normal;
        *overflow: visible;
    }
    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        cursor: pointer;
        -webkit-appearance: button;
    }
    input[type="search"] {
        -webkit-appearance: textfield;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    textarea {
        overflow: auto;
        vertical-align: top;
    }
    html {
        -webkit-font-smoothing: antialiased;
    }
    p {
        font-size: 13px;
        font-weight: normal;
        line-height: 18px;
        margin-bottom: 9px;
    }
    p small {
        font-size: 11px;
        color: #bfbfbf;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
        color: #505762;
    }
    h1 {
        margin-bottom: 18px;
        font-size: 30px;
        line-height: 2;
    }
    h1 small {
        font-size: 18px;
    }
    h2 {
        font-size: 24px;
        margin-bottom: 18px;
        line-height: 1.5;
    }
    h2 small {
        font-size: 14px;
    }
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 9px;
    }
    h3 {
        font-size: 18px;
    }
    h3 small {
        font-size: 14px;
    }
    h4 {
        font-size: 16px;
    }
    h4 small {
        font-weight: bold;
        font-size: 13px;
    }
    h5 {
        font-size: 13px;
        padding-top: 19px;
    }
    h6 {
        font-size: 13px;
        color: #bfbfbf;
        text-transform: uppercase;
    }
    ul ul,
    ul ol,
    ol ol,
    ol ul {
        margin: 0;
    }
    ul.styled,
    ul {
        list-style: disc;
        padding-top: 5px;
    }
    ul.styled li,
    ul li {
        list-style: disc;
        line-height: 19px;
        font-size: 13px;
        margin-left: 30px;
        margin-top: 2px;
    }
    ol {
        list-style: decimal;
    }
    ul.unstyled {
        list-style: none;
        margin-left: 0;
    }
    dl {
        margin-bottom: 18px;
    }
    dl dt,
    dl dd {
        line-height: 18px;
    }
    dl dt {
        font-weight: bold;
    }
    dl dd {
        margin-left: 9px;
    }
    hr {
        margin: 0 0 19px;
        border: 0;
        border-bottom: 1px solid #eee;
    }
    strong {
        font-style: inherit;
        font-weight: bold;
    }
    em {
        font-style: italic;
        font-weight: inherit;
        line-height: inherit;
    }
    .muted {
        color: #bfbfbf;
    }
    blockquote {
        margin-bottom: 18px;
        border-left: 5px solid #eee;
        padding-left: 15px;
    }
    blockquote p {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        margin-bottom: 0;
    }
    blockquote small {
        display: block;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: #bfbfbf;
    }
    blockquote small:before {
        content: "\2014 \00A0";
    }
    address {
        display: block;
        line-height: 18px;
        margin-bottom: 18px;
    }
    code,
    pre {
        padding: 0 3px 2px;
        font-family: Monaco, Andale Mono, Courier New, monospace;
        font-size: 12px;
        border-radius: 3px;
    }
    code {
        background-color: #fee9cc;
        color: rgba(0, 0, 0, 0.75);
        padding: 1px 3px;
    }
    pre {
        background-color: #f5f5f5;
        display: block;
        padding: 17px;
        margin: 0 0 18px;
        line-height: 18px;
        font-size: 12px;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        white-space: pre;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    .breadcrumbs {
        padding: 0 0 10px 0;
        margin-bottom: 30px;
        border-bottom: 1px solid #f6f6f6;
        width: 100%;
    }
    .breadcrumbs > li {
        float: left;
        filter: alpha(opacity= "$opacity");
        -khtml-opacity: 0.5;
        -moz-opacity: 0.5;
        opacity: 0.5;
    }
    .breadcrumbs > li:not(:last-child):after {
        color: #333b43;
        padding: 0 10px;
        content: "\203a";
    }
    .breadcrumbs + .pills,
    .breadcrumbs + .sec_tabs {
        margin-top: -15px;
    }
    .table {
        display: table;
        border-collapse: collapse;
        padding: 0 !important;
        margin: 0;
    }
    .cust_row {
        display: table-row;
        margin: 0;
    }
    .column {
        display: table-cell;
        vertical-align: top;
        padding: 30px;
    }
    .box_primary {
        border: 1px solid #c0c1c1;
        border-bottom-color: #a8aaab;
        -webkit-box-shadow: 0 1px 0 #ebebec;
        box-shadow: 0 1px 0 #ebebec;
        -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
        background: #fff;
    }
    .box_content {
        border-radius: 4px;
        padding: 30px;
    }
    .box_content .iub_content {
        padding: 30px;
    }
    .box_content .iub_content > hr {
        width: 686px;
        margin-left: -30px;
        margin-right: -30px;
    }
    .box_content .aside {
        width: 191px;
        padding: 30px;
    }
    .box_content .aside.aside-right {
        border-left: 1px solid #dfdfdf;
    }
    .table > .box_content {
        padding: 0;
    }
    .box_10 {
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 15px;
    }
    .box_10 > h4 {
        margin-bottom: 0;
        font-size: 13px;
    }
    .box_10 > .w_icon,
    .box_10 > .w_icon.expand-click,
    .box_10.expand > .w_icon,
    .box_10.expand > .w_icon.expand-click {
        padding-left: 45px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position-x: 10px;
        background-position-y: 7px;
        background-position: 10px 7px;
    }
    .box_10 > .w_icon_16,
    .box_10 > .w_icon_16.expand-click,
    .box_10.expand > .w_icon_16,
    .box_10.expand > .w_icon_16.expand-click {
        padding-left: 40px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position-x: 11px;
        background-position-y: 11px;
        background-position: 11px 11px;
    }
    .box_10 > .w_icon_24,
    .box_10 > .w_icon_24.expand-click,
    .box_10.expand > .w_icon_24,
    .box_10.expand > .w_icon_24.expand-click {
        padding-left: 45px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position-x: 10px;
        background-position-y: 10px;
        background-position: 10px 10px;
    }
    .box_5 {
        padding: 5px;
        border-radius: 3px;
        font-size: 11px;
        margin-bottom: 15px;
    }
    .box_5 hr {
        padding-top: 5px;
        margin: 0 -5px 5px -5px;
        border: 0;
        border-bottom: 1px solid #ac3737;
    }
    .box_5.w_icon_16 {
        padding-left: 30px;
        background-repeat: no-repeat;
        background-position-x: 8px;
        background-position-y: 6px;
        background-position: 8px 6px;
    }
    .box_5.w_icon_16 hr {
        width: 100%;
        padding-left: 30px;
        padding-right: 5px;
        margin-left: -30px;
        margin-right: -5px;
    }
    .box_5.w_icon_16.red {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAATlBMVEUAAAD%2F%2F%2F8AAAD%2F%2F%2F8AAAAAAAD%2F%2F%2F%2F%2F%2F%2F%2FT09P%2F%2F%2F%2F9%2Ff3Y2Nj9%2Ff39%2Ff3d3d3%2F%2F%2F%2F8%2FPz39%2Ff19fX%2B%2Fv79%2Ff34%2BPj5%2Bfn8%2FPz9%2Ff3%2F%2F%2F8ZO4GEAAAAGXRSTlMAEB0gMDNAUHSAgYSRoaWwsra3weLl5fLyUJhrdwAAAF1JREFUeF6NzUcWhCAAwFAQsIPOWCD3v6gPxLYjy7%2BJKE1Ok%2FxAD%2BMbFIB6wYIxLA%2FUbEJAc8PKHmG9oAOkArq8DICdgXCuLUA7EDkBsd%2BfWALnyXmXoNImpytR0AEwdQcUE5t8VQAAAABJRU5ErkJggg%3D%3D);
    }
    .box_thumb {
        background: #fff;
        -webkit-box-shadow: 0 0 1px #a3a3a3, 0 1px 1px #a3a3a3;
        box-shadow: 0 0 1px #a3a3a3, 0 1px 1px #a3a3a3;
        padding: 6px;
    }
    footer {
        margin-top: 17px;
        padding-top: 17px;
        border-top: 1px solid #eee;
    }
    hr {
        padding-top: 15px;
        margin: 0 0 15px 0;
    }
    hr.primary {
        border: 0;
        border-bottom: 1px solid #dfdfdf;
        -webkit-box-shadow: 0 1px 0 #f7f7f7;
        box-shadow: 0 1px 0 #f7f7f7;
    }
    .btn {
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.08);
        padding: 0 20px;
        line-height: 38px;
        color: #54616b;
        font-size: 13px;
        border: 0;
        border-radius: 4rem;
        border-collapse: separate;
        -webkit-transition: 0.1s linear all;
        transition: 0.1s linear all;
    }
    .btn:hover {
        background-position: 0 -15px;
        text-decoration: none;
    }
    .btn:focus {
        outline: 1px dotted #666;
    }
    .btn:active {
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    }
    .btn.disabled {
        cursor: default;
        background-image: none;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
        filter: alpha(opacity= "$opacity");
        -khtml-opacity: 0.65;
        -moz-opacity: 0.65;
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .btn[disabled] {
        cursor: default;
        background-image: none;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
        filter: alpha(opacity= "$opacity");
        -khtml-opacity: 0.65;
        -moz-opacity: 0.65;
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .btn.large {
        font-size: 16px;
        line-height: normal;
        padding: 0 14px;
        border-radius: 6px;
    }
    .btn.mid_large {
        padding: 0 10px;
        line-height: 32px;
        font-size: 13px;
    }
    .btn.mid {
        padding: 0 10px;
        line-height: 28px;
        font-size: 11px;
    }
    .btn.small {
        padding: 0 8px;
        line-height: 18px;
        font-size: 11px;
    }
    :root .alert-message,
    :root .btn {
        border-radius: 0 \0;
    }
    button.btn::-moz-focus-inner,
    input.btn[type="submit"]::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
    .circle {
        font-size: 11px;
        line-height: 18px;
        width: 18px;
        padding: 0;
        text-align: center;
        border-radius: 11px;
    }
    .circle.small {
        width: 14px;
        line-height: 14px;
        border-radius: 9px;
        padding: 0;
    }
    .blue,
    .btn.primary {
        color: #fff;
        background-color: #0073ce;
    }
    .yellow {
        color: #6d693d;
        background-color: #ffd24d;
    }
    .red {
        color: #fff;
        background-color: #ff5d4d;
    }
    .red a,
    .red a:hover:not(.btn) {
        color: #fff;
    }
    .red a {
        border-bottom-color: rgba(247, 247, 247, 0.3);
    }
    .red a:hover {
        border-bottom-color: rgba(247, 247, 247, 0.6);
    }
    .green {
        color: #4d6c47;
        background-color: #f1ffd5;
    }
    .iubgreen {
        color: #01281b;
        background-color: #1cc691;
    }
    .azure {
        color: #364048;
        background-color: #d2ecfe;
    }
    .white {
        color: #54616b;
        background-color: #f8f8f8;
    }
    .black {
        color: #fff;
        background-color: #333333;
    }
    .trasp {
        color: #333b43;
        background-color: #ffffff;
    }
    .alert-message {
        position: relative;
        padding: 7px 15px;
        margin-bottom: 18px;
        color: #404040;
        background-color: #eedc94;
        background-repeat: repeat-x;
        background-image: -khtml-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
        background-image: -webkit-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
        background-image: linear-gradient(to bottom, #fceec1, #eedc94);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$startColor', endColorstr='$endColor', GradientType=0);
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        border-color: #eedc94 #eedc94 #e4c652;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) fadein(rgba(0, 0, 0, 0.1), 15%);
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    }
    .alert-message .close {
        *margin-top: 3px;
    }
    .alert-message h5 {
        line-height: 18px;
    }
    .alert-message p {
        margin-bottom: 0;
    }
    .alert-message div {
        margin-top: 5px;
        margin-bottom: 2px;
        line-height: 28px;
    }
    .alert-message .btn {
        -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
    }
    .alert-message.block-message {
        background-image: none;
        background-color: #fdf5d9;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
        padding: 14px;
        border-color: #fceec1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .alert-message.block-message ul,
    .alert-message.block-message p {
        margin-right: 30px;
    }
    .alert-message.block-message ul {
        margin-bottom: 0;
    }
    .alert-message.block-message li {
        color: #404040;
    }
    .alert-message.block-message .alert-actions {
        margin-top: 5px;
    }
    .alert-message.block-message.error,
    .alert-message.block-message.success,
    .alert-message.block-message.info {
        color: #404040;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    }
    .alert-message.block-message.error {
        background-color: #fddfde;
        border-color: #fbc7c6;
    }
    .alert-message.block-message.success {
        background-color: #d1eed1;
        border-color: #bfe7bf;
    }
    .alert-message.block-message.info {
        background-color: #ddf4fb;
        border-color: #c6edf9;
    }
    .fade {
        -webkit-transition: opacity 0.15s linear;
        transition: opacity 0.15s linear;
        opacity: 0;
    }
    .fade.in {
        opacity: 1;
    }
    .expand-click {
        cursor: pointer;
        position: relative;
    }
    .box_10.expand .expand-click {
        margin: -10px;
        padding: 12px 25px 13px 10px;
    }
    .box_10.expand .expand-content {
        margin-top: 10px;
    }
    .box_10.expand .expand-content > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }
    .expand.expanded .expand-click:after,
    .box_10.expand.expanded .expand-click:after {
        content: "";
        position: absolute;
        right: 10px;
        top: 19px;
        border: 5px;
        border-color: transparent;
        border-style: solid;
        border-top-color: #333b43;
    }
    .expand .expand-click,
    .expand.expanded .expand-click,
    .box_10.expand .expand-click,
    .box_10.expand.expanded .expand-click {
        border-bottom: 1px dotted #ddd;
        margin-bottom: 10px;
        -webkit-transition: 0.2s linear all;
        transition: 0.2s linear all;
    }
    .expand.collapsed .expand-click {
        border-bottom: 0;
        margin-bottom: -10px;
    }
    .expand.collapsed .expand-click:after {
        content: "";
        position: absolute;
        right: 10px;
        top: 17px;
        border: 5px;
        border-color: transparent;
        border-style: solid;
        border-right-color: #333b43;
    }
    .all-collapsed .expand .expand-click:after {
        content: "";
        position: absolute;
        right: 10px;
        top: 17px;
        border: 5px;
        border-color: transparent;
        border-style: solid;
        border-right-color: #333b43;
    }
    .all-collapsed .expand .expand-click {
        border-bottom: 0;
        margin-bottom: -10px;
    }
    .all-collapsed .expand-content {
        display: none;
    }
    html,
    body {
        background-color: #fff;
    }

    body {
        margin: 0;
    }
    .iub_container-fluid {
        position: relative;
        min-width: 940px;
        padding-left: 20px;
        padding-right: 20px;
        zoom: 1;
    }
    .iub_container-fluid:before,
    .iub_container-fluid:after {
        display: table;
        content: "";
        zoom: 1;
        *display: inline;
    }
    .iub_container-fluid:after {
        clear: both;
    }
    .iub_container-fluid > .sidebar {
        float: left;
        width: 220px;
    }
    .iub_container-fluid > .iub_content {
        margin-left: 240px;
    }
    a {
        text-decoration: none;
        font-weight: bold;
        border-bottom: 1px solid #f6f6f6;
        color: #333b43;
    }
    a.unstyled {
        border-bottom: 0;
    }
    a:hover:not(.btn) {
        color: #121518;
        border-bottom-color: #d6d6d6;
        -webkit-transition: 0.1s linear all;
        transition: 0.1s linear all;
    }
    a.no_border,
    a.no_border:hover {
        border-bottom-width: 0;
    }
    .pull-right {
        float: right;
    }
    .pull-left {
        float: left;
    }
    .hide {
        display: none;
    }
    .show {
        display: block;
    }
    .link_on_dark a {
        border-bottom-color: rgba(247, 247, 247, 0.3);
    }
    .link_on_dark a:hover {
        border-bottom-color: rgba(247, 247, 247, 0.6);
    }
    [class*="policyicon_"] {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
    }
    .policyicon_pdt_68 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D);
    }
    .policyicon_purpose_5 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAElBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2BtTDCxAAAABXRSTlMAECBAgLf%2B2%2BsAAABGSURBVBhXY2AAA5ZQBwY4YA0NIJfjCjYHygkNDUTmBGPhgOyFc1iB6pE4wSAOUAGCIxoaiOCYhgYjOKqhQThkyODAAR4OAI98N9LK6tL3AAAAAElFTkSuQmCC);
    }
    .policyicon_purpose_7 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAINJREFUeF6V0UsOxCAIBmB8tVoZ4f6nnUqaoFUW%2FVeEj0hUMOKM9kE7CBcxr93SuGcCf%2FRZniCmXGVUwZV2M78DgYRXQDaAP0OzIJIB4C%2FaQo%2BTCyK9ISFizimAPyuNACjlKXW6SMF30B9I9YFndRieuZCCHKU0QIU1LDEhrvDrQG6EP%2FDZElAL0vLHAAAAAElFTkSuQmCC);
    }
    .policyicon_purpose_9 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAACC0lEQVQ4y7XSO2gWVhQH8BPxDRG%2BEhurMcSI4GsoPqjkZ6BLwcFFHUQJKqbEwRciDqZDF90cpIsILtZHh0KTIdQMgkTRiIshBoWgTRpbsVaxgqRf4uM4JCHfRzpIwXun8%2Bf%2BuHDOifj%2FxwoD2qek7Qat%2FG9Qr1%2FblLRNv%2FqyqKHCjIgIqw3oGE9mmtlQERGhw4DVERFmNFREhG91uq6gxUspnVdlky5dNqlyXkovtSi4rtPe8JeUaq1yWLN9tkVoklJThK1a7HXISrVSehpSGrXb5woWqFZljZNSOmmtBRapUe0Lu4xKOQZSr0633dejS7chKQ25p0%2BvHn3u6Bt7OQFSeuWG3pI6DbvpZ5dc8WwimwTPbYswx49Sei89sDNCpaoI6%2FyqWA5OmxUR4StF6Z0hX5puvyH%2FOmeeudrLwXfjg1prUCo6FuGyty444W89CpYZKQU%2FmF3ywwvVthtxwpwImz1yzjSdpWBYq2nWuzbWoQgX%2FaPOAd%2Br1O55hDOl4LHdDRXqnPVWehLhlPSNgiURFlof4adJMGC7eRERarRKr32t2qBn9lhlg%2BVq7fDbJDhasp%2BfueW9brOscdULv7vntlselnZpadlKH5fSRYvN16ytdJgT4KBGGzVqtNFmv4yndzWrt8WjqSCNGFZUNOxN2Xq8K6%2FD47Et%2FKg7ajAc9edHgz8ciU9%2BPgBKt4%2FTzlslzAAAAABJRU5ErkJggg%3D%3D);
    }
    .policyicon_purpose_10,
    .policyicon_purpose_15 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D);
    }
    .policyicon_purpose_13 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAJ1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9PhkGkAAAADHRSTlMAECBAUHCQoLDA4PB7ua%2BoAAAAa0lEQVR42p3QQQ6AIAxE0aEIFdr7n1eMxIAOMfEt%2B9sF4IOkYt5YSTKO1Qd6p%2BQP6Zqrvyjd7zdiLJggO5VReajwhR%2FBnDIoDwrhQcAfkhd%2FtQO0KDqf1A0kmEZgDjk2AZPzPoJo6wFEYOsHFFISOn%2BKxfoAAAAASUVORK5CYII%3D);
    }
    .policyicon_purpose_14 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwUGBwgJCgsMDQ4PASl6hyAAAAfklEQVR42pXRUQ6EMAgE0MEWW21l7n9btanJWnE3%2Bz4hhCHgq5jKooKD6FJS7OVQebIIROOphlY3dqrsLABidJgg0ZWw0bWBL%2F5vvO%2FIdGVM%2Fh0TMNMx47DwYcVJKgdV0MgwUwSXfA%2F0QY2dKW7CxutHA1lbHMFTavE9qsBvOztlFTRVyS%2BYAAAAAElFTkSuQmCC);
    }
    .policyicon_purpose_16 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAJFJREFUeF6V0NsOAyEIRVE6I4rFwvn%2Fr63N3CR10nQnPK2IUdpbpKmsorJQqOKTl2xeRhDsycMgA7QDGkmfq9cI%2FvNEhGcAO8CowAbAGTEwX1XDKvYNnJM7f78clVqfydOlgwRIG6S1TwDdQEnD3cv1iWw4f54VQ1qfUO5QDDGYVLNCmOQ5O2Ea8R2kP8FWobvefhoT%2FSVCMbAAAAAASUVORK5CYII%3D);
    }
    .icon_ribbon {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAW0lEQVR42u3OwQkAIQxE0XSWVrazlJpdQdGDC0pQEf7A3ELmibsPV1V9pDM%2FAQAAAAAAAAAAAAAAEAXY1%2BcUwCQnITYD6niL2ASo4z3EaoDKf8qNBQHxArgK8ALKMXCw%2Bim7vwAAAABJRU5ErkJggg%3D%3D);
    }
    .icon_owner {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwQGBwgJCgsMDQ4PC8YWy5AAAAiElEQVR42o2QwRKFIAhFIcwyRP7%2Fb3uNlkBv0dkw3jODd4AbPHhNC7xAafqjYBRZOzUa0cHmc9IbiZsefIFtiuQ68RS7FUkNnwTWmRewLE9ewSPh73dfCgJbzxkiRxcrDGJhWVxa5MqYr1HzcLSPRo2ojcoZAcyV2F1MzaPoxIqcP4gGkP5BcAIxQBCQ7o5t3AAAAABJRU5ErkJggg%3D%3D);
    }
    .icon_general {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
    }
    .icon_temple_24 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABwklEQVR42s3Wu0vDUBjGYS%2BLsbZSSaWgguAFHFztYEmFbp0E%2FwOrgotQFyetOKiTLqKCWCenbl20S8FFERydBLt1KV7QwUGR4094hQymjYpi4SGH9zvf%2BUgCoQ3GmF%2F1dwNq%2FRzHaUwkEn24lP73rEaL%2FwEcZmEcJexiDyfKrG8P0OG9OIDBrCvPKMuh98sDaApiAmWYj8fiqg%2FjSrWy9gbrDlDzEHIwLi9YRieiWMOrakIPvZ4DKHYhjTsYD%2Be48Kqrdwpdnw1I4RAFbCKHHWxhX%2BtjHGFb2ZbynHoKOiNV7x3YrnWLrmFFWqvm6vH7DmK4ho0l5NGGCialoiyvPbZ6Yn4GOHhCFBsoIQSDOTHKStoTVY%2FjZ0Act7CxiiICqGJaqsqK2mOrJ%2F6VARGs6ZA2ZTNyq6yoPZF%2FNWC0xiOaEq9HNOpnQBIG3djFGcLKFsQoO8UOepQl%2FQyIIKtP9BjSylcwghhWlKWR0N4sIp4D1NCKDgR0DSIEGxbCYikLibvH8voWNWMdD6jiEXe4waOye1GdGntc9Qcso8nrDtoxgEFdIcp81INed7CIBWQw%2F00ZnbH42YAXef4RnfNn%2FyreALybXwSLU3v7AAAAAElFTkSuQmCC);
    }
    .icon_box_24 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABv0lEQVR42t3Vz0uTcRzAcUMyWhmWdAgqkKDMiBHt0A%2FYsploddwpO1gk0l%2BQEEQU0iUPgd0LIpCOCh2KkG4GTaI6mqAmWhG0CQ5jfXsdntN4bJPRxS%2B84IFnz%2Ff97Nl4Pg0hhP9qkwT%2BtTKZTCN7uM0qeTrZuc4ltQVs0EqK%2B3xmigc8Z5E39HOI7TUFfHAbJxngNQtM0kdzg5VOp7c4TvKYeeZ4wmXaYgNONHGcW7zjA6Mkqzy%2BZq4zzgxj5DhcGUgxwzSZ9TasEtvFCL%2F4WBm4SKDAPXrZV%2BPGWznFVSYIhMpAliILhEieO5ynMWbjDgZ4xjxlAn%2FiAt0skeMG44TIT8boo51zDPOeEqFSXKAnCrRHd5fgNMN8IrDCFLOUCVB74CsdZBlkL03s5xG%2FCcAGA73M0cIViswyQisn%2BFZPoIdljkWP6AIvKDFIiuV6Al2s8JIciSh0hqNk%2BVHvNwiRNd5ylwNRqKvewNnYv1z0O%2FCQ7xsIFCsDCS4xyhKhwiprhCqmGaIz%2Fm0qxBGu8YoCoYpFntLNwbS3ba3zYDdJhshTohwpMEE%2FbeyoMg%2BqTrQWbvKFSSxDJn5tsqH%2FF0QW2NxzTlYFAAAAAElFTkSuQmCC);
    }
    .icon_tools_24 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABdklEQVR42rXVzyuDcRzA8WU3ctOag0RiO5mEg8MKcaJc%2FAlcdxppKPlZdpeTE0dqxZWaaEnh7DShlYuJbG0f78Nz%2BPj2zfM89Wz1qmff5%2FPdu%2B27tpCINNTfJzySyeQGhkPGg7UljBtrEbxDMOkaYCiKJ8QtgR0cGWt9EMe8l8AIBAOWwCGKxtqMCux5CSRQxyW61QtNo4yCEVhWgRzCboFm5CF4xTVuUYUgZQTOVeAZHV4OeRSfEMMFWoxAVt2%2FQtQ14Gzsx43anEWr5Vx61Eza9Qz%2B%2BXw71S09M6hm1v0GVtXmWCMCa2pz3BpgXc1kAg2w1oW0mjnGRJCBOYjhLshAO96MQCroM3hQMzUMBfkOZvEDUU78BFbUxl5LYBdiqPgJbKmNCUtg2xL48PpTMYVvFFDEPdqCDDwi51xHIVi0%2FAGJoew18IJ957oJFWx6CHx5DSygjgPkUULM8i2qGoFTP4ecgaCEMcshh5FFDYIzRGyBhvoF3n%2ByMxzF1ykAAAAASUVORK5CYII%3D);
    }
    .icon_paper_24 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABYklEQVR42t3VP0vDQBjH8Tq4SKXiWFwcRHARVyspgq9AOjkoLoKTcymk%2BjKKYDcnO6iLKPgHRJ11aVGkgt2M2IiWlobzKzyB4zBwBGvBwIdc7rnjR8ITLqGU6qm%2FC8hms%2Bo39TWgg0fUUJX7PV7RlVo1Qtsm4Ckhl%2BM4A%2BGY%2BQJ8TCYiLmp1m4AHWbyGa%2BxiFEtQMneIY80RTtCyCahJwDpOsYc0FtHGDS5wbjjDp03AM6aRRhIpTGAbH5jBEJIm208UwMcbmuIFHam34KEZ0tYGNgEeXFEUW9jAKvLYRFHjCs8moIEMZpHRxnNwzJrx3Oj%2Fj2a%2BQbT4b%2BDBjcmz6iK9M6LF76I6UnHY%2Fgc%2BythB2YK%2B1tcC%2Ful54COPMeT0tsMBxsW%2B0dY52VPAe1RAgAoGw7OA8QoUbvVzgPEU7qS2HJ4b33tRQfBTQBcl4wBZgMIlhrX5EVxJbd7YU0JXD%2BipLw8sG8DBrCLzAAAAAElFTkSuQmCC);
    }
    .icon_man_24 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABLUlEQVR42t3VsUrDUBSAYaWtSwSp6NAH6CbOVSHZ9QEEBx9AEPc6OAh9Bp0KDg6igqs4tktXFQQnKQiKiNRAp%2BT4C2cImqbXc3Fp4VsazvlpuUmmRORfTUig6BNFURnbeISoB2yhlDPiHvhegF18Qn74wA5KPoE13EFGuEXDFGBwBoeQMQ5QsQSqOHUInGDOEljElUPgEguWwDzOHQJnqFoCszh2CBwhsAQq2EdSsDxFE2XrMV3HS0HgFRs%2B90ENNwWBa9TMgTAMp%2FWR8J6z%2FA2bXo8K%2FRXLuB9xFy%2BZAwwHWMUF4pxArNdWnE%2BRLm2ghS76SMecoj66aOls8CvAl3XsoYNnDCF%2FNNTZju6qZwO93L%2FBLkYvGxggVeIpVYNsoI0nJBBPie5qT8ZL%2FwsLLryq%2FnnTDAAAAABJRU5ErkJggg%3D%3D);
    }
    .icon_keyhole_24 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABIUlEQVR42rXUP0vCQRjA8bIoiAppLmwQEmehSX5RLTb2Huw9RGODLvUCXG0Kp16ALhENNRjRXLRlIeafJOyevkNu14M89%2FPgMx133%2BF4bkZEpkrdHK8oipIoowuHK2QxGxzgkk1cYgD3R9BEAQlzgMOruMA3xOMZuZDAEToQxRkWrYEHOIiiibQ1IBMEOtixBn4mCLSRtwbqIKIGbrFhDRzgA6I4xpw1kMA5ep6LHa6RCh20ddx4Aq%2FYj2OSF1D3BJ6QjCOQwosn8IbtOAK7%2BPQEvlAcf3ghj1zE4J9HrmApJDCPkvLZ3WMtJLCCmjLNLWRCAmk8KkPWxyHMgQLaSmCIk5BJPkVfCYxQxbI10MBQCTjcYcsaeMcIomhhTwtM1S9g12NLa1YPRQAAAABJRU5ErkJggg%3D%3D);
    }
    .iub_base_container {
        border-radius: 3px;
        background: #fff;
        color: #595858;
        position: relative;
    }
    .iub_base_container > .close {
        background: transparent
            url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAw1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4eHh1dXUAAAAAAAAAAAAAAAAAAABfX18AAAAAAAAAAAAAAAAAAAAAAAA2NjYAAAAAAAArKyvJycne3t7X19eFhYWxsbGVlZWsrKzr6%2BvLy8vJycnv7%2B%2Fp6enS0tLi4uL09PTv7%2B%2F8%2FPz7%2B%2Fv4%2BPj39%2FcAAABPT09fX19vb2%2F%2F%2F%2F9S%2BfXQAAAAPHRSTlMAAgMEBQYHCgsMDQ4PEhMWGRobHB8gIiMkJScoKSs0NT1DRUpMWF5gjpOYmaGjpr%2FIys3S1dnZ7vP09vfFQC13AAAA9ElEQVR42oXQZ6%2BCMBiG4aeCAoqiuPceuPes8P7%2FX6Xn5RgBTbw%2BNO3dpG2KH0RcM5JJQ4uLUE2UnSM9HZ1y4r0TM50z%2FTs7ZuyVSysKWJX8DZHeUsg2zUfpU4qY6gBE8xLtl6YAtAnP79Ij8uSdFxMNsHY8lVK67nPgxc4CisQ8yTxiRaBGPvcvu%2BSrAY1vvQHUv51TByqHz3sPFcCef75zbgOpwZUiroMUoFSX0b6sKgDMzjqc1x2Tvznb2wTzppf1P1q1u7PTq55mXVuFT7Va48X%2BRnTbL8YtizMTRqHdH45Gw367YAgEKHoml8%2FnMroC9gCKfVabzD1q%2BwAAAABJRU5ErkJggg%3D%3D)
            no-repeat;
        border: none;
        display: block;
        position: absolute;
        z-index: 110;
        top: -10px;
        right: -9px;
        height: 24px;
        width: 23px;
    }
    p {
        line-height: 19px;
        margin: 0;
        padding-top: 11px;
    }
    address {
        margin: 0;
        line-height: inherit;
        display: inline;
    }
    a {
        font-weight: normal;
        border-bottom: 1px solid #f0f0f0;
    }
    .iub_content {
        position: relative;
        padding: 25px 30px;
        margin: 0 auto;
        border-radius: 3px 3px 0 0;
    }
    #wbars {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
    }
    #wbars .horizontal {
        display: none;
    }
    .iub_header {
        border-bottom: 1px dotted #dfdfdf;
        padding-bottom: 25px;
        position: relative;
    }
    .iub_header p {
        margin: 0;
        padding: 0;
    }
    .iub_header img {
        display: block;
        position: absolute;
        top: 5px;
        right: 0;
    }
    h1,
    h2,
    h3 {
        color: #262626;
        margin: 0;
    }
    h1 + p,
    h2 + p,
    h3 + p {
        padding-top: 5px;
    }
    h1 {
        font-size: 19px;
        font-weight: normal;
        line-height: 23px;
        margin-bottom: 5px;
    }
    h2 {
        font-size: 17px;
        font-weight: bold;
        line-height: 21px;
        padding-top: 21px;
    }
    h3 {
        font-size: 13px;
        line-height: 19px;
        font-weight: bold;
        padding-top: 24px;
    }
    h3 + p {
        padding-top: 0;
    }
    .iconed ul li h3 {
        padding-top: 10px;
        color: #615e5e;
    }
    h4 {
        font-size: 13px;
        font-weight: bold;
        padding-top: 19px;
        margin-bottom: 0;
    }
    h4:first-child {
        padding-top: 0;
    }
    h6 {
        color: #262626;
        text-transform: none;
        padding-top: 19px;
    }
    ul.for_boxes,
    ul.for_boxes > li,
    ul.unstyled,
    ul.unstyled > li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    ul.for_boxes {
        zoom: 1;
    }
    ul.for_boxes:before,
    ul.for_boxes:after {
        display: table;
        content: "";
        zoom: 1;
        *display: inline;
    }
    ul.for_boxes:after {
        clear: both;
    }
    .half_col {
        float: left;
        width: 50%;
        zoom: 1;
    }
    .half_col:before,
    .half_col:after {
        display: table;
        content: "";
        zoom: 1;
        *display: inline;
    }
    .half_col:after {
        clear: both;
    }
    .half_col:nth-child(2n + 1) > * {
        margin-right: 15px;
    }
    .half_col:nth-child(2n) > * {
        margin-left: 15px;
    }
    .half_col + .one_line_col,
    .half_col + .iub_footer {
        border-top: 1px dotted #dfdfdf;
    }
    .one_line_col {
        zoom: 1;
        float: left;
        width: 100%;
        border-bottom: 1px dotted #dfdfdf;
    }
    .one_line_col:before,
    .one_line_col:after {
        display: table;
        content: "";
        zoom: 1;
        *display: inline;
    }
    .one_line_col:after {
        clear: both;
    }
    .one_line_col > ul.for_boxes > li {
        float: left;
        width: 50%;
    }
    .one_line_col > ul.for_boxes > li:nth-child(2n + 1) {
        clear: left;
    }
    .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div {
        margin-right: 15px;
    }
    .one_line_col > ul.for_boxes > li:nth-child(2n) {
        clear: right;
    }
    .one_line_col > ul.for_boxes > li:nth-child(2n) > div {
        margin-left: 15px;
    }
    .one_line_col.wide {
        width: 100%;
    }
    .one_line_col.wide > ul.for_boxes > li {
        clear: both;
        width: 100%;
    }
    .one_line_col.wide > ul.for_boxes > li:nth-child(2n + 1) > div {
        margin-right: 0;
    }
    .one_line_col.wide > ul.for_boxes > li:nth-child(2n) > div {
        margin-left: 0;
    }
    ul.normal_list {
        list-style: disc;
        display: block;
        padding-top: 11px;
    }
    ul.normal_list li {
        list-style: disc;
        float: none;
        line-height: 19px;
        margin: 5px 25px;
    }
    .simple_pp > ul > li {
        padding-bottom: 21px;
    }
    .simple_pp > ul > li > ul .iconed {
        padding-left: 40px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position-x: 2px;
        background-position-y: 26px;
        background-position: 2px 26px;
    }
    .simple_pp .for_boxes > .one_line_col > ul.for_boxes {
        margin-top: 0;
    }
    .legal_pp .one_line_col {
        float: none;
        border-top: 0;
        padding-bottom: 21px;
    }
    .legal_pp .one_line_col > ul.for_boxes {
        margin-top: 21px;
    }
    .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n + 1) {
        clear: left;
        float: left;
    }
    .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n) {
        float: right;
        clear: right;
    }
    .legal_pp .definitions {
        margin-top: 21px;
    }
    .legal_pp .definitions .expand-click.w_icon_24 {
        margin-top: -11px;
        padding: 14px 10px 12px 45px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position-x: 5px;
        background-position-y: 0;
        background-position: 5px 0;
    }
    .legal_pp .definitions .expand-content {
        padding-left: 5px;
        padding-right: 5px;
    }
    .wrap p {
        display: inline-block;
    }
    .iub_footer {
        clear: both;
        position: relative;
        font-size: 11px;
    }
    .iub_footer p {
        font-size: 11px;
        padding: 0;
    }
    .iub_content .iub_footer {
        padding: 24px 0;
    }
    .iub_content .iub_footer p {
        margin: 10px 0;
        clear: both;
    }
    .iub_content .iub_footer .show_comp_link {
        display: block;
        float: right;
    }
    .iub_container > .iub_footer {
        min-height: 21px;
        background-color: #f6f6f6;
        color: #717171;
        padding: 30px;
        -webkit-box-shadow: 0 -1px 6px #cfcfcf;
        box-shadow: 0 -1px 6px #cfcfcf;
        border-radius: 0 0 3px 3px;
    }
    .iub_container > .iub_footer > .btn {
        position: absolute;
        top: 25px;
        right: 30px;
    }
    .iub_container > .iub_footer .btn {
        padding: 0px 24px;
        line-height: 29px;
    }
    .iub_container > .iub_footer .button-stack {
        margin: -4px 0;
    }
    .iub_container > .iub_footer .button-stack .btn + .btn {
        margin-left: 5px;
    }
    .iub_container > .iub_footer img {
        margin: -4px 3px 0;
        vertical-align: middle;
        width: 70px;
        height: 25px;
    }
    .wide {
        width: 150px;
    }
    @media (max-width: 767px) {
        .legal_pp .one_line_col,
        .legal_pp .half_col {
            width: 100%;
        }
        .legal_pp .one_line_col > ul.for_boxes > li,
        .legal_pp .half_col > ul.for_boxes > li {
            clear: both;
            width: 100%;
        }
        .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
        .legal_pp .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
            margin-right: 0;
        }
        .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
        .legal_pp .half_col > ul.for_boxes > li:nth-child(2n) > div {
            margin-left: 0;
        }
        .iub_header img {
            position: static;
            margin-bottom: 12.5px;
        }
        .iub_content > .iub_footer .show_comp_link {
            position: static;
            display: inline;
        }
        .iub_container > .iub_footer {
            padding: 20px;
        }
        .iub_container > .iub_footer .btn {
            top: 15px;
            right: 15px;
        }
        .iub_base_container > .close {
            content: "X";
            color: #000;
            font-size: 11px;
            line-height: 18px;
            padding: 0;
            text-align: center;
            border-radius: 30px;
            display: block;
            // background: #fff url(/../design/images/close_big.png) no-repeat;
            background-position-x: 18px;
            background-position-y: 18px;
            background-position: 18px 18px;
            position: absolute;
            z-index: 110;
            top: -10px;
            right: -10px;
            margin: 5px 5px 0 0;
            height: 57px;
            width: 60px;
            -webkit-box-shadow: 0 1px 1px #000000;
            box-shadow: 0 1px 1px #000000;
        }
        .iub_base_container > .close.small {
            width: 14px;
            line-height: 14px;
            border-radius: 9px;
            padding: 0;
        }
    }
    @media (max-width: 480px) {
        html {
            padding: 0;
        }
        body {
            padding: 0;
        }
        .iub_base_container,
        .iub_container {
            margin: 0;
        }
        .half_col:nth-child(2n + 1) > * {
            margin-right: 0;
        }
        .half_col:nth-child(2n) > * {
            margin-left: 0;
        }
        .one_line_col,
        .half_col {
            width: 100%;
        }
        .one_line_col > ul.for_boxes > li,
        .half_col > ul.for_boxes > li {
            clear: both;
            width: 100%;
        }
        .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
        .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
            margin-right: 0;
        }
        .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
        .half_col > ul.for_boxes > li:nth-child(2n) > div {
            margin-left: 0;
        }
        .iub_header img {
            position: static;
            margin-bottom: 12.5px;
        }
        .iub_content > .iub_footer .show_comp_link {
            position: static;
            display: inline;
        }
        .iub_container > .iub_footer {
            padding: 10px;
            text-align: center;
        }
        .iub_container > .iub_footer .btn {
            position: static;
            width: auto;
            display: block;
            margin: 10px auto 0 auto;
            max-width: 200px;
        }
        .iub_container > .iub_footer.in_preview {
            padding: 30px 10px;
        }
        .iub_content {
            padding-left: 20px;
            padding-right: 20px;
        }
        .iub_base_container > .close {
            content: "X";
            color: #000;
            font-size: 11px;
            line-height: 18px;
            padding: 0;
            text-align: center;
            border-radius: 30px;
            display: block;
            // background: #fff url(/../design/images/close_big.png) no-repeat;
            background-position-x: 18px;
            background-position-y: 18px;
            background-position: 18px 18px;
            position: absolute;
            z-index: 110;
            top: -10px;
            right: -10px;
            margin: 5px 5px 0 0;
            height: 57px;
            width: 60px;
            -webkit-box-shadow: 0 1px 1px #000000;
            box-shadow: 0 1px 1px #000000;
        }
        .iub_base_container > .close.small {
            width: 14px;
            line-height: 14px;
            border-radius: 9px;
            padding: 0;
        }
    }
    .iubenda_fixed_policy .iub_base_container {
        max-width: 800px;
    }
    .iubenda_fixed_policy .iub_container {
        margin-left: auto;
        margin-right: auto;
        zoom: 1;
    }
    .iubenda_fixed_policy .iub_container:before,
    .iubenda_fixed_policy .iub_container:after {
        display: table;
        content: "";
        zoom: 1;
        *display: inline;
    }
    .iubenda_fixed_policy .iub_container:after {
        clear: both;
    }
    .iubenda_fluid_policy #wbars {
        overflow-y: auto;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: auto;
    }
    .iubenda_fluid_policy .iub_container {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .iubenda_fluid_policy .half_col:nth-child(2n + 1) > * {
        margin-right: 0;
    }
    .iubenda_fluid_policy .half_col:nth-child(2n) > * {
        margin-left: 0;
    }
    .iubenda_fluid_policy .one_line_col,
    .iubenda_fluid_policy .half_col {
        width: 100%;
    }
    .iubenda_fluid_policy .one_line_col > ul.for_boxes > li,
    .iubenda_fluid_policy .half_col > ul.for_boxes > li {
        clear: both;
        width: 100%;
    }
    .iubenda_fluid_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
    .iubenda_fluid_policy .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
        margin-right: 0;
    }
    .iubenda_fluid_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
    .iubenda_fluid_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
        margin-left: 0;
    }
    .iubenda_embed_policy .iub_base_container {
        background: none;
    }
    .iubenda_embed_policy .iub_container > .iub_footer {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: none;
    }
    .iubenda_embed_policy .expand-click {
        cursor: default;
    }
    .iubenda_vip_policy.iubenda_terms_policy .iub_base_container {
        color: #666;
    }
    .iubenda_vip_policy.iubenda_terms_policy h2 {
        font-size: 24px;
        padding-top: 50px;
    }
    .iubenda_vip_policy.iubenda_terms_policy h3 {
        color: #444;
        font-size: 20px;
        padding-top: 45px;
    }
    .iubenda_vip_policy.iubenda_terms_policy h4 {
        font-size: 16px;
        padding-top: 40px;
        color: #555;
    }
    .iubenda_vip_policy.iubenda_terms_policy h5 {
        font-size: 14px;
        padding-top: 35px;
        margin-bottom: 0;
        color: #666;
    }
    .iubenda_vip_policy.iubenda_terms_policy h6 {
        font-size: 12px;
        color: #505050;
        text-transform: uppercase;
        padding-top: 32px;
        margin-bottom: 0;
    }
    .iubenda_vip_policy.iubenda_terms_policy .definitions {
        margin-top: 60px !important;
    }
    .iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content {
        padding: 25px 15px !important;
    }
    .iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content h4 {
        font-size: 15px !important;
    }
    .iubenda_vip_policy.iubenda_terms_policy .definitions:before {
        content: "";
        border-top: 1px dotted rgba(0, 0, 0, 0.1);
        display: block;
        margin: 0 -10px;
        position: relative;
        top: -45px;
    }
    .iubenda_vip_policy.iubenda_terms_policy .definitions h2 {
        font-size: 13px;
        color: #333;
    }
    .iubenda_vip_policy.iubenda_fixed_policy .iub_container {
        max-width: 660px;
        padding-top: 80px;
    }
    .iubenda_vip_policy .iub_base_container {
        color: #595858;
    }
    .iubenda_vip_policy p {
        font-size: 14px;
        line-height: 1.6;
    }
    .iubenda_vip_policy .allcaps,
    .iubenda_vip_policy p.allcaps,
    .iubenda_vip_policy ul.allcaps li {
        font-variant: small-caps !important;
        font-weight: bold !important;
        font-size: 16px !important;
        font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans,
            Helvetica Neue, sans-serif !important;
    }
    .iubenda_vip_policy ul li {
        font-size: 14px;
        line-height: 1.6;
    }
    .iubenda_vip_policy h1 {
        font-size: 30px;
        color: #141414;
        line-height: 1.6;
        margin-bottom: 60px;
    }
    .iubenda_vip_policy h2 {
        font-size: 18px;
        color: #141414;
        line-height: 1.6;
        padding-top: 50px;
        padding-bottom: 15px;
    }
    .iubenda_vip_policy h3 {
        color: #141414;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 10px;
    }
    .iubenda_vip_policy .legal_pp .one_line_col {
        padding-bottom: 50px;
    }
    .iubenda_vip_policy .half_col:nth-child(2n + 1) > * {
        margin-right: 0;
    }
    .iubenda_vip_policy .half_col:nth-child(2n) > * {
        margin-left: 0;
    }
    .iubenda_vip_policy .one_line_col,
    .iubenda_vip_policy .half_col {
        width: 100%;
    }
    .iubenda_vip_policy .one_line_col > ul.for_boxes > li,
    .iubenda_vip_policy .half_col > ul.for_boxes > li {
        clear: both;
        width: 100%;
    }
    .iubenda_vip_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
    .iubenda_vip_policy .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
        margin-right: 0;
    }
    .iubenda_vip_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
    .iubenda_vip_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
        margin-left: 0;
    }
    .iubenda_vip_policy .definitions,
    .iubenda_vip_policy .iub_footer,
    .iubenda_vip_policy .for_boxes {
        color: #505762;
    }
    .iubenda_vip_policy .definitions h3,
    .iubenda_vip_policy .definitions p,
    .iubenda_vip_policy .definitions li,
    .iubenda_vip_policy .iub_footer h3,
    .iubenda_vip_policy .iub_footer p,
    .iubenda_vip_policy .iub_footer li,
    .iubenda_vip_policy .for_boxes h3,
    .iubenda_vip_policy .for_boxes p,
    .iubenda_vip_policy .for_boxes li {
        font-size: 13px;
    }
    .iubenda_vip_policy .w_icon_24 {
        background-image: none;
    }
    .iubenda_vip_policy .box_10.expand .expand-click.w_icon_24 {
        padding-left: 10px;
    }
    .iubenda_vip_policy .box_primary {
        border-color: #e0e0e0;
        border-bottom-color: #d3d3d3;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .iubenda_vip_policy .box_primary h3 {
        color: #333;
    }
    .iubenda_vip_policy .tc-deactivated h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .iubenda_vip_policy .legal_pp .one_line_col {
        padding-bottom: 21px;
    }
}
