.loading {
    &:not(.extended) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 30px;
        height: 30px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &.extended {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        img {
            width: 70px;
        }
    }
}
