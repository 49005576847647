.pr-order-modal {
    hr {
        border-color: color(marketplace) !important;
    }
    .modal {
        &-header {
            background-color: color(light, 0.3);
            margin-bottom: 40px;
        }
        &-body {
            max-width: 500px;
            margin: 0 auto;
            .description {
                // color: color(dark);
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                line-height: 1.5;
            }

            .form {
                margin: 0 auto;
                .row {
                    display: flex;
                    flex-direction: column;
                    gap: 0 10px;
                    & > * {
                        flex: 1;
                    }
                    @include mq(576px) {
                        flex-direction: row;
                    }
                }
              
                .form-label {
                    font-weight: 500;
                    margin-bottom: 8px;
                }
                .footer-label {
                    font-size: 12px;
                    margin-top: -13px;
                    a {
                        font-size: 12px;
                        // color: color(primary);
                    }
                }
                &-option {
                    &.active {
                        input {
                            background-color: color(marketplace);
                            color: #fff;
                        }
                    }
                    input {
                        cursor: pointer;
                    }
                }

                .btn-group {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                }
            }
        }
        &-footer {
            background-color: color(marketplace);
        }
        &-content {
            overflow: hidden;
        }
    }

    .hidden-options {
        background-color: color(light, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        .order-upload-wrapper {
            padding: 10px 0;
        }
        .order-upload-image {
            display: flex;
            flex-direction: column;
            align-items: center;

            label {
                display: block;
                // margin-bottom: 10px;
            }
            img {
                display: block;
                width: 100%;
                max-width: 300px;
                max-height: 150px;
                object-fit: contain;
                // margin-bottom: 10px;
            }
        }
        hr {
            border-color: color(secondary, 0.1) !important;
            width: 100%;
            border-radius: 0;
        }
        .order-upload-docx {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            img {
                display: block;
                width: 100%;
                max-width: 300px;
                max-height: 150px;
                object-fit: contain;
                margin-bottom: 10px;
            }
        }
        .dropdown {
            &-menu {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .pr-order-submit-btn {
    }
}

.pr-confirmation-modal {
    max-width: 450px;
    font-size: 14px;
    line-height: 1.5;
    .btn-group {
        display: flex;
        gap: 10px;
    }
    .form-check {
  
        & > span {
            flex: 1;
        }
    }
    .modal {
        &-header {
            background-color: transparent;
            margin: 0;
        }
        &-body {
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
        }
        &-footer {
            background-color: transparent;
            padding-bottom: 30px;
        }
    }
}

.image-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.image-preview {
    position: relative;
    display: inline-block;
}

.preview-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.remove-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
