

.sign-up-page {
    max-width: 100%;
    min-height: 100vh;
    padding: 0;

    & > .container {
        padding-top: 40px;
        padding-bottom: 40px;
        height: 100%;
        @include mq(1100px) {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: 1fr 1fr;
        }
        & > .col {
            & > .wrapper {
    
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

        }
    }
    .col {
        &-left {
            width: 100%;
            max-width: 407px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 50px;
            @include mq(1100px) {
                margin-bottom: 0px;
            }
        }
        &-right {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
        }
    }
    .form {
        display: flex;
        flex-direction: column;

        .logo {
            max-width: 240px;
            margin: 0 auto 15px;
        }
        &-description {
            font-size: 14px;
            text-align: center;
            margin-bottom: 50px;
        }
        &-title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 30px;
        }
        .field {
            margin-bottom: 20px;
        }
        .submit-btn {
            margin-top: 12px;
            margin-bottom: 20px;
        }
    }
    .cut-line {
        margin-bottom: 20px;
    }
    .social-btn {
        margin-bottom: 15px;
    }

    .login-signup-now {
        // flex: 1;
        text-align: center;
        font-size: 14px;
        margin-bottom: 50px;
    }

    .copyright {
        font-size: 14px;
        margin: 0 auto;
        max-width: 407px;
    }
    .testimonials {
        height: 100%;
    }
}
