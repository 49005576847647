

.recharts-legend-wrapper {
  display: none;
}

.ads-td-image {
  width: 0;
  text-align: center;
  .content {
      background-color: color(light);
      width: fit-content;
      margin: 0 auto;
      border-radius: 5px;
      overflow: hidden;
      img {
          border-radius: 3px;
          display: block;
          width: 40px;
          height: 30px;
          object-fit: cover;
          cursor: pointer;
      }
  }
}

.zones-wrapper {
  .table td {
    padding: 15px 8px !important;
  }

  .scroll {
    border-radius: 0 !important;
  }

  .table-row {
    th {
      padding: 15px 0px;
    }
  }

}


.campaign-page-header-reports {
  background: #f1f3f4;
  padding: 0.9rem 2rem;
  position: sticky;
  top: 0;
  z-index: 10;
  padding-top: 1.5rem;
  border-bottom: 1px solid #d5d7d7;

  .dropdown-sm {
      .dropdown-toggle {
          padding: 8px 10px !important;
          border-radius: 5px;
      }
  }
}

.active-svg-icon {
  .sort-btn {
    svg {
      path {
        fill: #ffff !important
      }
    }
  }
}

.reports-table {
  width: 100%;
  background: #F8F9FA;
}