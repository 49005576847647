.campaign-build-option-modal {
        font-family: Manrope;
        max-width: 770px;
        .modal {
            &-dialog {
    
            }
            &-title {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 20px;
                @include mq(992px) {
                    font-size: 28px;
                }
            }
            &-description {
                font-size: 16px;
                color: color(muted);
                text-align: center;
            }
            &-body {
                padding: 0 30px;
                
            }
            &-footer {
                padding:30px;
                display: block;
                .btn-group {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    justify-content: flex-end;
                }
            }
        }
}

.build-option-card {
    padding: 20px ;
    border-radius: 8px;
    border: 2px solid color(light,.5);
    cursor: pointer;
    position: relative;
    &:hover {
        background-color: color(secondary,.05);
        box-shadow: 0 2px 4px #cdcfd2;
    }
    .card {
        &-title {
            font-size: 15px;
            color: color(dark,.9);
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 5px;
        }
        &-description {
            font-size: 12px;
            font-weight: 300;
            color: color(muted);
        }
    }
    .form-check {
        position: absolute;
        margin: 10px;
        top:0;
        right: 0;
        pointer-events: none;
    }
    .badge {
        background-color: color(success);
        border-radius: 5px;
        padding: 5px 7px;
        color: #fff;
        font-weight: 400;
    }
}