.sidebar {
    background-color: #ffff;
    border-right: 2px solid #EEF0F2;
    color: #fff;
    // box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem,
    //     rgba(0, 0, 0, 0.6) 0rem 0.125rem 0.25rem -0.0625rem;
    width: 90px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .sidebar-footer {
        margin-bottom: 0.5rem;
    }

    .sidebar-body {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }


    .item-title {
        color: #5f6368;
    }

    .custom-sidebar-link-support {
        svg {
            
            path {
                fill: none !important;
                
            }
        }
    }


    .custom-sidebar-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #1E98B0;

        svg {
            path {
                fill: #5f6368;
            }
        }
    }

    .admin-container {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        padding-top: 20px;
    }
    .accounts-btn {
        margin-bottom: 10px;
        width: 100%;
        gap: 5px;
        font-size: 16px;
        img {
            height: 35px;
            width: auto;
            filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.5));
        }
    }
    .marketplace-badge {
        position: absolute;
        top: 16px;
        right: 13px;
        border-radius: 50%;
        background-color: color(marketplace);
        color: #fff;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s ease;
        font-size: 10px;
        font-weight: 300;
        &.active {
            opacity: 1;
        }
    }
    .custom-tooltip {
        max-width: 220px;
    }

    &-inner {
        // padding-top: 10px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        justify-content: space-between;
        &::-webkit-scrollbar {
            width: 4px;
            height: 10px;
            background-color: rgba(0, 0, 0, 0.05);
        }
        &::-webkit-scrollbar-thumb {
            background-color: color(light, 0.3);
            &:hover {
                background-color: color(light, 0.8);
            }
            &:active {
            }
        }
    }
    &-header {
        // height: 60px;
        display: flex;
        margin-left: 20px;
        gap: 15px;
        align-items: center;
        padding-top: 10px;
        margin-bottom: 15px;
        // @include mq(1400px) {
        //     margin-bottom: 60px;
        // }
    }
    &-body {
        margin-top: 15px;
        flex: 1;
        // margin-bottom: 30px;
    }
    &-footer {
        margin: 0 20px;
        .title {
            text-transform: capitalize;
            font-size: 14px;
            color: color(light);
            font-weight: 400;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            position: relative;
            z-index: 2;

            &-text {
                padding: 4px 0;
                display: inline-block;
            }
            .info-popup {
                align-self: flex-start;
            }
        }
        .select {
            // margin-bottom: 60px;
            margin-bottom: 20px;
            width: 100%;
            z-index: 3;
            &.active {
                .select {
                    &-toggle {
                        border-radius: 0;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }
            &-toggle {
                // background-color: color(dark);
                background-color: transparent;
                &:hover {
                    // background-color: color(dark) !important;
                    background-color: transparent !important;
                }
                svg path {
                    fill: color(light) !important;
                }
                &-title {
                    color: #fff !important;
                    &.text-purple {
                        color: rgb(150, 45, 255) !important;
                    }
                    // &.text-success {
                    //     color: color(success) !important;
                    // }
                }
            }
            &-menu {
                width: 100%;
                transform-origin: bottom;
                bottom: 100%;
                top: auto;
                border-radius: 0;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                max-height: 18rem;
            }
        }
 
        .description {
            font-size: 12px;
            color: color(light);
            font-weight: 400;
            margin-bottom: 50px;
        }
    }
    .react-select {
        margin-bottom: 20px;
        width: 100%;
        color: color(dark);
        font-size: 14px;
        z-index: 3;

         input {
            color: #fff !important; /* Change text color */
            
        }

    }

    &::-webkit-scrollbar {
        width: 7px;
        background-color: rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: rgba(0, 0, 0, 0.1);
        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }
        &:active {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
    &-logo {
        max-width: 170px;
    }
    &-logo {
        display: block;
    }
    &-logo-sm {
        display: none;
        max-width: 39px;
        margin-left: 3px;
    }

    &-text {
        color: color(marketplace);
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 800;
        width: 100%;
        // border-bottom: 1px solid color(muted);
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px;
        // text-transform: uppercase;
        // margin-bottom: 8px;
        user-select: none;

    }

    .add-ons-divider {
        // border-top: 1px solid lightgray;
        // border-bottom: 1px solid lightgray;
        margin-top: 40px;

        .sidebar-list {
            .bar-divider {
                .sidebar-link {
                    svg {
                        padding: 3px;
                        width: 25px;
                        height: 25px;
                        path { fill: #5f636878 }
                    }
                }
            }
        }
    }
    .list-wrapper {
        border: 1px solid rgba(255, 255, 255, 0.05);
        max-width: 160px;
        margin: 0 auto;
        margin-bottom: 0px;
        padding: 15px 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        // margin-bottom: 15px;
        // margin-top: 15px;
        border-right: none;
        transition: background-color 0.6s ease, transform 0.3s ease, margin 0.3s ease;
        &.disabled {
            opacity: 0.5;
            & > * {
                pointer-events: none;
            }
        }
        &.supports-wrapper {
            margin-bottom: 30px;
        }
        &.marketplace-wrapper {
            .pr-sidebar-link {
                svg {
                    width: 20px;
                    padding: 3px;
                }
            }
            .sidebar-text {
                display: flex;
                justify-content: space-around;
                cursor: pointer;
                align-items: center;
                font-size: 10px;
            }
            .endIcon {
              
                svg {
                    width: 15px;
                    height: auto;
                    fill: currentColor;
                }
            }
            .sidebar-list {
                display: none;
                &.show {
                    display: block;
                }
            }
        }
        &-active {
            &.marketplace-wrapper {
                // background-color: color(marketplace);
                .sidebar-text {
                    display: flex;
                    justify-content: space-between;
                    color: rgba(31, 131, 82,1) !important; 
                }
              
            }
        }
    }

    &-list {
        position: relative;
        // overflow: hidden;
        margin-top: 2rem;
        // margin-bottom: 3rem;
        margin-top: 8px;
        .sup-text {
            font-size: 11px;
            font-weight: 300;
            display: inline-block;
            padding: 4px 4px;
            max-width: 120px;
            width: 100%;
            border-radius: 100px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            transition: transform 0.3s ease;
            z-index: 3;
            background-color: color(light, 0.2);
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            // color: rgba(255, 255, 255, 0.6);
            // transform: translateX(calc(-100% - 15px));
            // transform: translateX(-100px);
            cursor: pointer;
            // transform: translateX(-110px);
            // transform: translateX(-20px);
            &.active {
                // transform: translateX(-20px);
                .icon {
                    transform: rotateZ(90deg);
                }
            }
            .icon {
                margin-left: auto;
                width: fit-content;
                // margin-right: 2px;
                transition: transform 0.3s ease;
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 6px;
                    height: auto;
                    display: block;
                    fill: #fff;
                }
            }
        }
        .bar-divider {
   
        }

        .products-description {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            line-height: 1.4;
            color: color(light, 0.2);
            font-size: 12px;
            font-weight: 300;
            max-width: 210px;
            display: none;
            &.hidden {
                display: none;
            }
        }
    }
    &-link {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 8px 10px;
        color: #fff;
        position: relative;
        // transition: all .3s ease;
        line-height: 1;
        color: #fff;
        // text-transform: capitalize;
        font-size: 13px;
        font-weight: 400;
        // &:before {
        //     content: "";
        //     position: absolute;
        //     top: 50%;
        //     left: 0;
        //     transform: translateY(-50%);
        //     height: 25px;
        //     width: 2px;
        //     background-color: transparent;
        //     // opacity: 0;
        //     transition: background-color 0.3s ease;
        // }
        // &:hover:not(.active) {
        //     &:before {
        //         background-color: rgba(255, 255, 255, 0.6);
        //     }
        //     path {
        //         fill: rgba(255, 255, 255, 0.6);
        //     }
        // }

        &.activePR {
            margin: 0 auto;
            border-radius: 8px;
            background-color: #2aab7e;
            max-width: 50px;
            svg {
                path {
                    fill: white  !important;
                }
            }

            .item-title {
                color: white
            }
        }
        &.active {
            // background: linear-gradient(104.1deg, rgb(27 167 195 / 13%) 6.86%, rgb(59 246 147 / 16%) 87.57%);
            // border: 1px solid lightgray;
            color: #1E98B0;
            max-width: 85px;
            margin: 0 auto;
            border-radius: 10px;
            svg {
                // fill: color(market) !important;
                background: rgba(42, 171, 126, 1);
                border: 1px solid lightgray;
            }
            &:before {
                background-color: color(success);
            }
            path {
                fill: white  !important;
            }

            .item-title {
                color: rgba(31, 131, 82,1);
            }

            .custom-support-icon {
                svg {
                    path {
                        stroke: white;
                    }
                }
            }
        }
        &.disabled {
            pointer-events: none;
            p {
                color: lightgray !important;
            }
            
            svg path {
                fill: lightgray !important;
                // stroke: rgba(255, 255, 255, 0.3) !important;
            }
        }
        &.hidden {
            display: none;
        }
        &.small-icon {
            & > svg {
                padding: 3px;
            }
        }
        svg {

            padding: 3px;
            border-radius: 10px;
            object-fit: contain;
            width: 48px;
            height: 32px;
            padding: 7px;
            
            // filter: drop-shadow(0 0 3px color(dark,.1));
            path {
                fill: rgb(15 100 58);
                // fill: rgba(255, 255, 255, 1);
                // transition: fill 0.3s ease, stroke 0.3s ease;
            }
        }
        span {
            transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .info-popup {
            align-self: flex-start;
        }
    }

    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
        z-index: 998;
    }
    .copyright {
        color: color(muted);
        font-size: 12px;
    }
}

@media screen and (max-width: 992px) {
    // .sidebar {
    //     z-index: 999;
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     transform: translateX(-100%);
    //     transition: transform 0.3s ease, background-color 1s ease;
    //     &.show {
    //         transform: none;
    //     }
    //     &-backdrop {
    //         &.show {
    //             opacity: 1;
    //             pointer-events: auto;
    //         }
    //     }
    // }
    .sidebar  {
        display: none;
    }

    .drawer-sidebar {
        padding: 0 !important;
        max-width: 105px;
        .sidebar  {
            width: 100%;
            display: block !important;

            .collapse-menu  {
                display: none;
            }
        }

        div:first-child {
            background-color: #ffff;
            svg {
                path {
                    // fill: white;
                }
            }
        }
    }
    .profile-name {
        display: none;
    }

    .profile-icon {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .sidebar {
        transition: width 0.3s ease, background-color 1s ease;
        &-logo {
            display: block;
        }
        &-logo-sm {
            display: none;
        }
        &-toggler-show {
            display: none !important;
        }

        &.show {
            width: 70px;
            .sidebar {
                &-logo {
                    display: none;
                }
                &-logo-sm {
                    display: block;
                }
                &-inner {
                    span {
                        opacity: 0;
                    }
                }
                &-text {
                    opacity: 0;
                }
                &-link {
                    span {
                        opacity: 0;
                    }
                }

                &-footer {
                    display: none;
                }
            }
        }
        &.removeText {
            .sidebar {
                &-inner {
                    width: auto;
                }
                &-inner,
                &-link {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    .sidebar.show + .sidebar-backdrop + .main-content .sidebar-toggler-show {
        display: flex !important;
    }
}

// circled btn
// .sidebar-toggler-btn {
//     position: fixed;
//     top: 50%;
//     left: 0px;
//     z-index: 997;
//     transform: translateY(-50%) translateX(-50%);
//     box-shadow: 0 0px 6px rgba(0, 0, 0, 0.5);
//     background-color: #fff;
//     width: 30px;
//     height: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 50%;
//     cursor: pointer;
//     transition: .3s ease;

//     &:hover {
//         box-shadow: 0 0px 5px rgba(0, 0, 0, 0.8);
//         transform: translateY(-50%) translateX(0%);
//         svg path {
//             // stroke: #fff;
//         }
//     }
//     svg {
//         width: 10px;
//         height: 10px;
//         object-fit: contain;
//         path {

//             // transition: .3s ease;
//         }
//     }
// }

// .sidebar.show + .sidebar-backdrop + .sidebar-toggler-btn{
//     left: 260px;
//     &:hover {
//         transform: translateY(-50%) translateX(-50%);
//     }
// }

// @include mq(992px){
//     .sidebar-toggler-btn {
//         left: 260px;
//         &:hover {
//             transform: translateY(-50%) translateX(-50%);
//         }
//     }
//     .sidebar.show + .sidebar-backdrop + .sidebar-toggler-btn{
//         left: 70px;
//     }
// }


.collapase-menu {
    border-top: 1px solid lightgray;
    padding: 10px 0px;
    font-size: 12px;
    cursor: pointer;

    p {
        padding: 10px 10px;
    }

    :hover {
        background-color: rgb(139, 139, 139);
    }
}

.hidden {
    display: none !important;
}

.sidebar.collapsed {
    width: 60px; /* Adjust as needed */
}

.sidebar.collapsed .sidebar-text {
    display: none !important;
}

.sidebar.collapsed .sidebar-logo {
    display: none;
}

.sidebar.collapsed .sidebar-logo-sm {
    display: block;
    width: 35px;
}

.sidebar.collapsed .sidebar-link span {
    display: none !important;
}

.sidebar-link {
    padding-bottom: 9px
}

.sidebar.collapsed .sidebar-link {
    justify-content: center;
}

.collapsed {
    justify-content: center !important;
}

.collapse-menu {
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    p {
    cursor: pointer;
    border: 2px solid gray;
    padding: 2px 7px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 4px;
    }
}


.sidebar-header-sm {
    margin-left: 0;
    justify-content: center;
}


.site0footer-collapsed {
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.05);
    padding: 20px 0;
    svg {
        width: 18px;
        height: 18px;
    }
}


@media screen and (max-width: 580px) {
    .sidebar-footer {
        position: absolute;
        bottom: 10%;
        width: 100%;
        padding: 0 20px;
        margin: 0;
    }

    .sidebar-body {
        flex: initial;
    }
    

    .hamburger-menu {
        .drawer {
            margin-top: 0;
            margin-bottom: 0;
            height: 100vh;
        }
    }
}


@media screen and (max-height: 750px) { 
    .sidebar {
        &-list {
            margin-top: 0 !important;
            margin-bottom: 1rem !important;
        }

        .sidebar-footer {
            div {
                margin-top: 0 !important;
            }
        }

        .admin-container {
            padding-top: 0;
        }

    }
}