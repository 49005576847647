.average-cost-card {
    width: 100%;
    text-align: center;
    max-width: 210px;

    img {
        width: 50px;
        height: 50px;
    }
    .card-title {
        color: #66768E;
        font-size: 14px;
    }

    .card-price {
        text-wrap: nowrap;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
    }
}

