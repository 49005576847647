.navbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #ffff;
    color: color(dark);
    border-radius: 0;
    // gap: 5px;
    justify-content: flex-end;
    padding: 10px 10px;
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 997;
    border-bottom: 1px solid #EEF0F2;
    // backdrop-filter: saturate(200%) blur(1.875rem);

    // transition: background-color .3s ease, transform .3s ease, box-shadow .3s ease, top .3s ease;
    // padding-bottom: 5px;
    transition: all 0.3s ease;

    .deposit-btn {
        box-shadow: 0px 20px 20px -2.5px #00C2FF0D;
        padding: 6px 15px !important;
        border: 1px solid #1ba7c3b5 !important;
    }

    .page-title {
        font-size: 15px;
        font-weight: 500;
    }
    
    &-public {
        // background-color: #fff;
    }

    .sing-up-btn {
        margin: 0 10px;
    }
    &-divider {
        border-color: color(secondary, 0.1);
        border-width: 0 0 1px 0;
        margin-bottom: 25px;
    }
    &.shrink {
        // padding: 5px 10px;
        // top: 30px;
        // transform: translateY(5px);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        // border: none;
        background-color: color(dark);
        .deposit-btn {
            font-size: 0.8125rem;
            padding: 2px 10px;
            border-radius: 8px;
        }
        .profile {
            color: #fff;
        }
        .sidebar-toggler-show {
            color: #fff;
        }
        .navbar-title {
            color: #fff;
        }
        .profile-image {
            img {
                width: 25px;
                height: 25px;
                // border: 2px solid rgba(255,255,255,0.9);
                // border: 2px solid color(light,.3);
            }
        }
        .budget {
            // background-color: color(success, 0.3);
            border-color: rgba(255, 255, 255, 0.2);
            
            &-title {
                color: #fff;
            }
            &-balance {
                color: #fff;
            }
        }
    
    }
    .budget {
        font-size: 12px;
        color: color(dark);
        background: linear-gradient(180deg, #FCFCFC 0%, #F4F3F4 100%);
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        padding: 7px 6px;
        display: flex;
        align-items: center;
        gap: 4px;
        // box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
        &-title {
            font-family: Poppins;
            display: none;
            @include mq(576px){
                display: block;
            }
        }
        &-balance {
            font-weight: bold;
        }
    }
    & > .wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include mq(576px){
            gap: 10px;

        }
    }
    &-title {
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        // transition: color .3s ease;
        display: none;
        @include mq(576px) {
            font-size: 16px;
            display: block;
        }

        &-sm-media {
            display: block;
            width: 100%;
            margin-bottom: 5px;
            position: relative;
            text-align: center;
            // background-color: color(dark);
            transition: .3s ease;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            @include mq(576px) {
                display: none;
            }
        }
    }
    .deposit-btn {
        transition: 0.3s ease;
        font-size: 0.8125rem;
        padding: 2px 10px;
        margin: 0 8px;
        // display: none;
        // @include mq(576px) {
        //     display: flex;
        // }
    }
    // .deposit-btn {
    //     font-size: 0.8125rem;
    //     padding: 4px 10px;
    //     svg {
    //         width: 14px;
    //         height: 14px;
    //     }
    //     @include mq(576px){
    //         svg {

    //             width: 12px;
    //             height: 12px;
    //         }
    //     }
    // }
    .profile {
        display: flex;
        align-items: center;
        gap: 7px;
        &-image {
            
            img {
                border: 1px solid #e3e3e3;
                transition: 0.3s ease;
                width: 33px;
                height: 33px;
                object-fit: cover;
                display: block;
                border-radius: 50%;
                @include mq(576px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        &-name {
            color: #ffff;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .hamburger-menu {
        display: none;
    }

    .deposit-container {
        display: flex;
        gap: 5px;
        align-items: center;
        padding-right: 15px;
        border-right: 1px solid rgb(160, 160, 160);
        
        #login-tutorial-2 {
            .btn {
                // border: 1px solid #1ba7c3b5 !important;
            }
        }



        button {
            border-radius: 5px !important;
            margin: 0 5px;
        }

        .btn-outline-primary {
            border-color: rgba(21, 161, 197,1);
        }

        .budget  {
            margin: 0 5px;
        }
    }

    .add-user-container {
        display: flex;
        gap: 5px;
        align-items: center;
        border-right: 1px solid rgb(160, 160, 160);
        padding-right: 20px;

        button {
            border-radius: 5px !important;
        }
    }

    .custom-wrapper-navigation {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .right-side-navigation {
            display: flex;
        }
        .left-side-navigation {
            display: flex;
            img {
                max-width: 175px;
            }
        }
    }

    .react-select {
        width: 100%;
        z-index: 3;
        margin-left: 25px;
        line-height: 1.75rem;
        font-size: 1rem;
        letter-spacing: 0;
        font-weight: 400;
        color: #202124;

        .css-1u9des2-indicatorSeparator {
            display: none;
        }

        .css-1nmdiq5-menu {
            max-width: initial !important;
        }

        .css-1nmdiq5-menu {
            font-size: 12px;
        }

        div:first-of-type {
            border: none;
            box-shadow: none;
            color: #202124;
            max-width: 180px;
            margin-top: 2px;
        }

         input {
            color: color(dark) !important; /* Change text color */
            font-size: 10px !important;
            min-width: 200px !important;
        }
    }

    .hamburger-menu {
        align-self: center;
    }
}

.profile-icon svg path {
    fill: color(dark) !important;
}

.drawer {
    .drawer-text {
        color: black;
    }

    & > div {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &-toggler {
        & > .btn {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
    &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
        margin-bottom: 17px;
        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid #e3e3e3;
        }
    }
    &-body {
     flex: 1;
    }
    &-footer {
        & > .wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-bottom: 30px;
        }

    }
}

.account-selection-mobile {
    display: none;
}


@media screen and (max-width: 992px) {
    .profile-name {
        display: none;
    }

    .hamburger-menu {
        display: block !important;
    }

    .profile-icon {
        display: none;
    }

    .left-side-navigation {
        display: none !important;
    }

    .custom-wrapper-navigation {
        justify-content: flex-end !important;
    }

    .account-selection-mobile {
        display: block !important;
        margin-bottom: 20px;
        padding: 13px;

        .react-select {
            margin-left: 0px !important;
        }
    }
}

.css-hlgwow {
    // max-width: 180px !important;
}

.css-1u9des2-indicatorSeparator {
    // display: none !important;
}

.css-hmxuh1-control {
    // border: none !important;
    // padding-left: 10px !important;
    // margin-left: 15px;


    // :active {
    //     border: none !important;
    // }
}

.css-1hv476f-control {
    // border: none !important;
    // box-shadow: none !important;
}

.css-19bb58m {
    // max-width: 130px !important;
}

.accounts-title {
    align-self: center;
    margin-bottom: 0;
    margin-left: 20px;
}

/* Billing Dropdown Styles */
.billing-dropdown {
    width: 100%;
    margin-bottom: 8px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .billing-dropdown-header {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .billing-dropdown-header.active {
    background-color: rgba(27, 167, 195, 0.1);
  }
  
  .billing-dropdown-header:hover {
    background-color: rgba(104, 104, 104, 0.05);
  }
  
  .sidebar-item {
    display: flex;
    align-items: center;
    padding: 4px 13px;
    margin-top: 8px;
    font-size: 14px;
    color: #444;
    font-weight: 500;
    position: relative;
  }
  
  .dark-mode .sidebar-item {
    color: #e0e0e0;
  }
  
  .sidebar-item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    color: #1ba7c3;
  }
  
  .dropdown-arrow {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }
  
  .dropdown-arrow.expanded {
    transform: rotate(180deg);
  }
  
  .billing-dropdown-content {
    background-color: rgba(240, 240, 240, 0.3);
    overflow: hidden;
  }
  
  .dark-mode .billing-dropdown-content {
    background-color: rgba(50, 50, 50, 0.3);
  }
  
  .sidebar-subitem {
    padding: 15px 16px 10px 18px;
    cursor: pointer;
    color: #555;
    font-weight: 400;
    transition: background-color 0.2s ease;
  }
  
  .dark-mode .sidebar-subitem {
    color: #ccc;
  }
  
  .sidebar-subitem:hover {
    background-color: rgba(27, 167, 195, 0.05);
  }
  
  .sidebar-subitem.active {
    background-color: rgba(27, 167, 195, 0.1);
    font-weight: 500;
  }