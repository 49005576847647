.tracking-drawer {
    // background-color: rgb(246, 248, 250);
    // padding: 2rem;
    // background-color: rgb(246, 248, 250);
    height: 100%;
    & > div {
        height: auto;
    }
    .tabs {
        flex-direction: column;
        box-shadow: none;
        border: 1px solid color(light, 0.5);
        border-radius: 10px;
        background-color: #fff;
    }

    .tracking-tabs {
        & > .nav-tabs {
            display: none;
        }
    }


    .divider {
        display: none;
    }
    .tab-content {
        padding: 0;
    }
    .code {
        padding: 15px;
        min-height: 100px;
        background-color: rgb(246, 248, 250);
        border-radius: 10px;
        & > .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .label {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 10px;
                color: color(secondary,.7);
                font-weight: 600;
                margin-bottom: 10px;
                img {
                    height: 15px;
                    width: auto;
                    display: block;
                }
            }
            .copy-text-btn {
            }
        }
        pre {
            background-color: transparent !important;
            code {
                padding-bottom: 15px !important;
            }
        }
    }

    .tracking-page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .close-icon {
            cursor: pointer;
            padding-bottom: 1rem;
            svg {
            width: 25px;
            height: 25px;
        }}
    }


    button .startIcon img {
        width: 20px;
        height: 20px;
    }

    .tabs ul button {
        padding: 10px !important;
    }
}

