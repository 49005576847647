.btn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.75;
    text-transform: capitalize;
    min-width: 64px;
    padding: 5px 13px;
    border-radius: 8px;
    overflow: hidden;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.3s ease;
    svg,
    img {
        width: 12px;
        height: 12px;
        user-select: none;
        object-fit: contain;
        display: inline-block;

        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        path {
            fill: currentColor;
        }
    }
    // img {
    //     margin: 0 6px;
    // }
    &.stroke {
        svg path {
            fill: none;
            stroke: currentColor;
        }
    }
    &.btn-sm {
        // font-size: 0.8125rem;
        font-size: 12px;
        padding: 4px 10px;
        // svg {
        //     width: 10px;
        //     height: 10px;
        // }
    }
    &.btn-xs {
        font-size: 12px;
        padding: 2px 6px;
        border-radius: 4px;
        // svg {
        //     width: 10px;
        //     height: 10px;
        // }
    }
    // &.btn-md {
    //     svg {

    //     }
    // }
    &.btn-medium {
        width: 33px;
        height: 33px;
        svg {
            width: 18px;
            height: 18px;
        }
    }
    &.btn-lg {
        font-size: 14px;
        padding: 10px 22px;
        svg,
        img {
            width: 23px;
            height: 23px;
        }
    }

    &.alt {
        &:hover {
            background-color: color(dark);
            color: color(primary);
        }
    }
    &-primary,
    &-warning,
    &-success,
    &-secondary,
    &-danger,
    &-light,
    &-white,
    &-dark,
    &-marketplace,
    &-pale-dark,
    &-purple {
        color: #fff;
        // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        //     0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        // &:hover {
        //     box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        //         0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        // }
        &:active,
        &.active {
            // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            //     0px 3px 14px 2px rgba(0, 0, 0, 0.12);

            span.ripple {
                background-color: rgba(255, 255, 255, 0.2);
                transition: background-color 0s;
            }
        }
    }

    &-primary {
        background-color: color(primary);
        a {
            color: #fff;
        }
        &:hover {
            background-color: color(primary-hover);
        }
    }
    &-success {
        background-color: color(success);
        // color: color(dark);
        &:hover {
            background-color: color(success-hover);
        }
    }
    &-warning {
        background-color: color(warning);
        &:hover {
            background-color: color(warning-hover);
        }
    }
    &-danger {
        background-color: color(danger);
        // color: color(dark);
        color: #fff;
        &:hover {
            background-color: color(danger-hover);
        }
    }
    &-secondary {
        background-color: color(secondary);
        &:hover {
            background-color: color(secondary-hover);
        }
    }
    &-dark {
        background-color: color(dark);
        &:hover {
            background-color: color(dark-hover);
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }
    &-pale-dark {
        background-color: color(pale-dark);
        &:hover {
            background-color: color(pale-dark-hover);
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }
    &-purple {
        background-color: color(purple);

        &:hover {
            background-color: color(purple-hover);
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }
    &-light {
        color: color(dark);
        background-color: color(light);
        &:hover {
            background-color: color(light-hover);
        }
    }
    &-white {
        color: color(dark);
        background-color: color(light, 0.1);
        &:hover {
            background-color: color(light, 0.2);
        }
    }
    &-marketplace {
        background-color: color(marketplace);
        &:hover {
            background-color: color(marketplace-hover);
        }
    }

    &-outline,
    &-text,
    &-circle {
        &-primary,
        &-warning,
        &-success,
        &-secondary,
        &-danger,
        &-light,
        &-dark,
        &-marketplace,
        &-pale-dark,
        &-purple {
            // padding: 5px 15px;
            padding: 4px 13px;
            border: 1px solid;
            box-shadow: none;
            &:hover {
                box-shadow: none;
                border-color: currentColor;
            }
            span.ripple {
                opacity: 0.2;
            }
            &:active
            // &.active
            {
                span.ripple {
                    background-color: currentColor !important;
                    transition: background-color 0s;
                }
            }
        }

        &-primary {
            border-color: color(primary, 0.5);
            color: color(primary);
            &:hover {
                background-color: color(primary, 0.05);
            }
        }
        &-secondary {
            border-color: color(secondary, 0.3);
            color: color(secondary);
            &:hover {
                background-color: color(secondary, 0.05);
            }
        }
        &-dark {
            border-color: color(dark, 0.5);
            color: color(dark);
            &:hover {
                background-color: color(dark, 0.05);
            }
        }
        &-pale-dark {
            border-color: color(pale-dark, 0.5);
            color: color(pale-dark);
            &:hover {
                background-color: color(pale-dark, 0.05);
            }
        }
        &-purlple {
            border-color: color(purlple-v3, 0.5);
            color: color(purlple-v3);
            &:hover {
                background-color: color(purlple-v3, 0.05);
            }
        }
        &-success {
            border-color: color(success, 0.5);
            color: color(success);
            &:hover {
                background-color: color(success, 0.05);
            }
        }
        &-warning {
            border-color: color(warning, 0.5);
            color: color(warning);
            &:hover {
                background-color: color(warning, 0.05);
            }
        }
        &-danger {
            border-color: color(danger, 0.5);
            color: color(danger);
            &:hover {
                background-color: color(danger, 0.05);
            }
        }
        &-light {
            border-color: color(light, 0.5);
            color: color(light);
            &:hover {
                background-color: color(light, 0.05);
            }
        }
        &-purple {
            border-color: color(purple, 0.5);
            color: color(purple);
            &:hover {
                background-color: color(purple, 0.05);
            }
        }
        &-marketplace {
            border-color: color(marketplace, 0.5);
            color: color(marketplace);
            &:hover {
                background-color: color(marketplace, 0.05);
            }
        }
    }

    &-text {
        &-primary,
        &-warning,
        &-success,
        &-secondary,
        &-danger,
        &-light,
        &-dark,
        &-marketplace,
        &-pale-dark {
            // padding: 6px 8px;
            border: none;
            &:hover {
                border-color: inherit;
            }
        }
    }

    &-circle {
        &-primary,
        &-warning,
        &-success,
        &-secondary,
        &-danger,
        &-light,
        &-dark,
        &-purple {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            min-width: auto;
            padding: 0 !important;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: none;
            svg,
            img {
                width: 25px;
                height: 25px;
                object-fit: contain;
                path {
                    fill: currentColor;
                }
            }
            &.btn-sm {
                width: 25px;
                height: 25px;
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
            // &.btn-md {
            //     svg {
            //     }
            // }
            &.btn-lg {
                width: 50px;
                height: 50px;
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .ripple {
        pointer-events: none;
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        animation: ripple 1s linear forwards;
        transition: background-color 1s ease-out;
        @keyframes ripple {
            to {
                transform: scale(4);
            }
        }
    }
    .startIcon {
        // margin-left: -4px;
        margin-right: 8px;
        // margin-top: -3px;
    }
    .endIcon {
        // margin-right: -4px;
        margin-left: 8px;
        // margin-top: -3px;
    }
    .startIcon,
    .endIcon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-block {
        width: 100%;
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.mode {
        padding: 0;
        min-width: 0;
        width: 35px;
        height: 35px;
        &.active {
            // box-shadow: 0px 0px 6px color(primary);
            border: 1px solid color(success);
        }
        svg {
            width: 20px;
            height: 20px;
            path {
                fill: rgb(184, 198, 212) !important;
            }
        }
    }
}
