@media screen and (max-width: 992px) {
.MuiGrid-item {
    padding-left: 0 !important;
}
.objective-title {
    text-align: center;
}
}

.css-1u5w3v4-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #1f8352 !important;
}


.objective-title {
    margin: 7% auto 40px;
}

.create-campaign-loading-spinner {
    span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 16px !important;
    height: 16px !important;
    }
}