.option-card {
    position: relative;
    border: 2px solid #ccc; /* Default border color */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: border-color 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: #ffff !important;
        border-color: #1f8352 !important;
    }
    .card {
        &-icon {
            img {
                display: block;
                width: 60px;
                height: auto;
            }
        }
        &-title {
            font-size: 15px;
            color: color(dark,.9);
        }
        &-description {
            font-size: 12px;
            font-weight: 300;
            color: color(muted);
        }
        
    }
    & > .wrapper {
    }
}

.active-option-card {
    border-color: #1f8352 !important;
}

.checkbox-container {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 2px solid #00b0ff;

    svg {
        fill: #1f8352;
    }
}