.referral-banner {
    &-name {
        font-size: 20px;
        font-weight: 500;
        margin-left: 15px;
        margin-bottom: 10px;
        color: color(secondary);
    }
    &-inner {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        background-color: color(primary,.2);
        padding: 15px;
        border-radius: 10px;
    }
    &-image {
        overflow: auto;
        flex: 1;
        width: 100%;
        img {
            max-width: none;
        }
    }
    &-textarea {
        flex: 1;
        width: 100%;
      position: relative;
        .field-endIcon {
            top: 5px;
            right: 5px;
            transform: none;
        }
    }
    textarea {
        resize: vertical;
        line-height: 1.5;
        color: color(muted);
        padding: 10px 15px;
        padding-right: 25px;
        min-height: 150px;
        width: 100%;
        overflow: auto;
        display: block;
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
    }
    a {
        // text-decoration: initial;
        color: color(primary);
        &:hover {
            text-decoration: underline;
        }
    }
    &-column {
        .referral-banner {
            &-inner {

                @include mq(1300px) {
                    flex-direction: row;
                }
            }
        }
    }
    &-row {
    }
}