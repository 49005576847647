#root {
    display: flex;
}

.sidebar {
}

.main-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    // margin: 15px;
    margin-right: 0;
    margin-left: 0;
    height: calc(100vh - 30px);
    overflow-y: auto;
    border-radius: 0 !important;
    height: 100vh;
    @include mq(992px) {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
}

#loom-companion-mv3 {
    display: none;
}
.main {
    flex: 1;
}

//

.main-content {
    // padding: 20px;
    // @media screen and (min-width: 992px) {
    //     padding: 40px;
    // } 
    overflow-anchor: none;
    background-color: #F8F9FB;
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

.hero {
    margin-bottom: 2rem;
}

.main {
    background-color: #f1f3f4;
    // box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem,
    //     rgba(0, 0, 0, 0.6) 0rem 0.125rem 0.25rem -0.0625rem;
    padding: 10px 15px 100px;
    transition: background-color 1s ease;
    @include mq(576px) {
        padding: 10px 30px 20px;
    }
}

.main-inner {
    // min-height: 1400px;
}
