.option-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 180px;
    text-align: center;
    padding: 20px ;
    border-radius: 8px;
    border: 2px solid color(light,.5);
    max-width: 250px;
    margin: 0 auto;

    cursor: pointer;
    &:hover {
        background-color: color(primary,.05);
        box-shadow: 0 2px 4px #cdcfd2;
    }
    .card {
        &-icon {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            img {
                display: block;
                width: 60px;
                height: auto;
            }
        }
        &-title {
            font-size: 15px;
            color: color(dark,.9);
        }
        &-description {
            font-size: 12px;
            font-weight: 300;
            color: color(muted);
        }
        
    }
    & > .wrapper {
    }
}