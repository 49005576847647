.banner-slide {
    max-width: 500px;
    margin: 0 auto;
    .carousel {
   
        .swiper {
            width: 100%;
            height: 0;
            padding-top: 75%;
            position: relative;
            
            .swiper-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

            }
        }
    }
    .swiper-pagination {
        // width: 120px !important;
        right: 55px !important;
        left: auto !important;
        transform: none !important;
        bottom: 25px !important;
        &-bullet {
            background-color: color(success);
            width: 8px;
            height: 8px;
            transform: scale(1) !important;
            opacity: 0.5;
            
            &-active {
                
                opacity: 1;
            }
        }
    }
    .carousel-angles {
        top: auto;
        left: auto;
        bottom: 15px;
        right: 20px;
        max-width: 150px;
        width: 100%;
        margin: 0;
        // display: none;
        // border: 1px solid color(success,.3);
        border-radius: 100px;
        padding: 2px 3px;
        background-color: rgba(255,255,255,.1);
        .carousel-angle {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            // background-color: #fff;
            background-color: transparent !important;
            &:hover {
                background-color: color(light,.1) !important;
            }
            svg path {
                fill: color(success) !important;
            }
        }
    }
    .banner-slide-block {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        background-color: color(dark);
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 15%, transparent 50%);
            background: linear-gradient(to top, rgba(0, 0, 0, 1) 23%, transparent 74%);
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%,-50%);
            object-fit: cover;
        }
    }
}