.carousel {
    user-select: none;
    position: relative;
}

.carousel-angles {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 30px;
    z-index: 997;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
}

.carousel-angle {
    pointer-events: auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    border-radius: 7px;
    background-color: color(light,.1);
    cursor: pointer;
    transition: .3s ease;
    width: 35px;
    height: 35px;
    &:hover {
        background-color: color(light,.2);
        box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    }
    &:active {
        background-color: color(light,.5);

    }
    // cursor: pointer;
    // padding: 0;
    // width: 50px;
    // height: 50px;
    // background: rgba(0,0,0,0.05);
    // border-radius: 50%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // transition: background-color .3s ease;
    // &:hover:not(:active) {
    //     background-color: #333;
    //     svg path {
    //         fill: #fff !important;
    //     }
    // }
   
    svg {
        width: 6px;
        path {
            fill: color(success,.5) !important;
            transition: fill .3s ease;
        }
    }
}

.swiper-button-disabled {
    opacity: 0.3 !important;
    pointer-events: none;
}

.swiper-slide .swiper-lazy-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 42px;
    background-color: #fff;
    transform: none;
    animation: none !important;
    border: 0;
    border-radius: 0;
    margin: 0;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 4px solid #000;
        border-top-color: transparent;
        width: 42px;
        height: 42px;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
}
