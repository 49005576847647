.autocomplete {
    position: relative;
    user-select: none;
    // width: fit-content;
    width: 100%;
    max-width: 300px;
    * {
        font-family: "Inter", "Helvetica", "Arial", sans-serif;
     
    }
     // .btn {
    //     all: unset;
    // }
    &-label {
        font-size: 14px;
        color: color(dark);
        font-weight: bold;
        margin-bottom: 8px;
        display: inline-block;
    }
    &.active {
        .autocomplete {
            &-toggle {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                &-icon {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            &-menu {
                opacity: 1;
                transform: none;
                pointer-events: auto;
            }
        }
    }
    &-toggle,
    &-item {
        display: flex;
        align-items: center;
        .startIcon,
        .endIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            justify-content: center;
            flex-shrink: 0;
            svg,
            img {
                height: 12px;
                width: 12px;
                object-fit: contain;
                fill: currentColor;
            }
        }
        .endIcon {
            margin-left: 10px;
        }
        .startIcon {
            margin-right: 10px;
        }
    }
    &-toggle {
        padding: 4px 13px;
        padding-right: 0;
        cursor: pointer;
        width: 100%;
        justify-content: space-between;
        text-transform: lowercase;
        & > .wrapper {
            display: flex;
            align-items: center;
            // width: 80%;
            // transition: opacity .3 ease;
            width: calc(100%  - 45px);
            overflow: hidden;
            &.placeholder {
                opacity: 0.7;
            }
        }
        &-title {
            white-space: nowrap;
            text-align: left;
            font-weight: 500;
            letter-spacing: 0.02857em;
            // line-height: 1.2;
            overflow-x: hidden;
            text-overflow: ellipsis;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        &-icon {
            // transition-duration: 300ms;
            // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            // transition-property: opacity, transform;
            margin: 0 !important;
            position: absolute;
            top: 0;
            right: 0;
            width: 45px;
            // height: 35px;
            height: 100%;
            svg {
                margin-top: -1px;
                transition-duration: 300ms;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-property: transform;
            }
        }
    }

    &-field {
        // padding: 6px 16px;
        padding: inherit;
        padding-right: 0;
        outline: none;
        border: none;
        background-color: transparent;
        color: currentColor;
        text-transform: capitalize;
        font-weight: 500;
        letter-spacing: 0.02857em;
        line-height: 1.75;
        font-size: 0.875rem;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 45px);
        z-index: 1;
        // opacity: 0;
        // transition: opacity 0s ease .3s;
        // color: red;
        // &:focus {
        //     opacity: 1;
        //     & ~ .wrapper {
        //         opacity: 0;
        //     }
        // }
        &::placeholder {
            color: currentColor;
            opacity: 0.4;
        }
    }

    &-menu {
        padding: 2px; // for outline
        background-color: #fff;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        min-width: 100%;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        transform: rotateX(-90deg);
        transform-origin: center top;
        opacity: 0;
        pointer-events: none;
        will-change: transform, opacity;
        max-height: 16rem;
        overflow-y: auto;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: opacity, transform;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 8px;
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.1);
            transition: color 0.2s ease;
            border: none;
        }
        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.02);
            border-radius: 0;
        }
    }
    
    &-item {
        width: 100%;
        font-size: 14px;
        white-space: nowrap;
        line-height: 1.2;
        padding: 6px 16px;
        cursor: pointer;
        // transition: background-color 0.3s ease;
        &.active {
            background-color: rgba(0, 0, 0, 0.1);
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        &-title {
            &::first-letter {
                text-transform: uppercase;
            }
        }
        &:hover:not(.active) {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    &-sm {
        .autocomplete {
            &-toggle {
                padding: 3px 10px;
                &-title {
                    font-size: 12px !important;
                }
            }
        }
    }
}
