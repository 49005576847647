.blockchains-wrapper {
    .data-type-select {
        z-index: 3;
        min-width: 150px;
        margin-left: auto;
    }
    & > .row {
        position: relative;
        z-index: 2;
        width: fit-content;
        max-width: 400px;
        h2 {
            margin-bottom: 0;
        }
    
        margin-bottom: 10px;
        @include mq(576px){

            margin-bottom: -25px;
        }
        @include mq(768px){
            max-width: 500px;
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .wrapper {
            position: relative;
            z-index: 2;
            @include mq(480px) {
                gap: 10px;
                display: flex;
                align-items: center;
            }
        }
        .scroll-items {
            width: 90%;
            .btn {
                padding: 5px 5px;
                line-height: 1;
                min-width: auto;
                border-radius: 4px;
                &.active {
                    background-color: color(light,.5);
                }
            }
            .scroll-item-link {
                white-space: nowrap;
                max-width: 55px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                display: block;
                overflow-y: hidden;
              
            }
        }
    }
    .search {
        // margin-left: auto;
    }
    .hash-query {
        display: flex;
        align-items: center;
        padding: 2px;
        padding-left: 9px;
        padding-right: 4px;
        border-radius: 100px;
        border: 1px solid color(secondary, 0.3);
        &-text {
            font-size: 10px;
            font-weight: bold;
            max-width: 131px;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
        .btn {
            width: 21px;
            height: 21px;
        }
    }

    .blockchain-tabs {
        padding: 0;
        flex-direction: column;
        box-shadow: none;
        @include mq(576px) {
            // margin-top: -47px;
        }
        .nav-tabs {
            flex-direction: row;
            gap: 3px;
            margin-left: auto;
            margin-right: 0;
            margin-bottom: 30px;
            width: 100%;

            @include mq(576px) {
                max-width: 330px;
                width: fit-content;
            }
            .nav-link {
                // background-color: color(light, 0.3);
                border-radius: 4px;
                line-height: 1;
                justify-content: center;
                margin-bottom: 0;
                font-size: 12px;
                min-width: auto;
                &.active {
                    background-color: color(light, 0.6);
                }
                &:hover:not(.active) {
                    background-color: color(light, 0.3);
                }
            }
        }
        .indicator {
            display: none;
        }

        .divider {
            display: none;
        }
        .tab-content {
            padding: 0;
        }

        .tab-pane.fade {
            // transition: .3s ease .1s;
            transition: 1s ease 0.3s;
        }
        // CAROUSEL

        .carousel {
            width: 100%;
        }
        .swiper {
            margin-right: 0;
            position: relative;
            z-index: 0;
            .swiper-slide {
                &:first-child {
                    // margin-left: 20px;
                }
                &:last-child {
                    // margin-right: 20px;
                }
            }
            &:after,
            &:before {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                width: 20px;
                z-index: 9999;
                pointer-events: none;
            }
            &:before {
                // box-shadow: inset 15px 0 10px #fff;
            }
            &:after {
                // box-shadow: inset -15px 0 10px #fff;
                right: 0;
            }
        }
        .carousel {
            margin-bottom: -1px;
            display: flex !important;
            align-items: center;
            gap: 5px;
        }
        .swiper-slide {
            width: auto !important;
        }
        .carousel-angles {
            // width: 100%;
            all: unset;
            display: flex;
            gap: 5px;
            margin: 0;
        }
        .carousel-angle {
            border-radius: 3px;
            background-color: #fff;
            width: 20px;
            height: 20px;
            box-shadow: none;
            border-radius: 50%;
            &:hover {
                background-color: color(secondary, 0.1);
            }
            svg {
                path {
                    fill: color(dark) !important;
                }
            }
        }
    }
}
