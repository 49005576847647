.upload-image {
    text-align: center;
    // svg {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 3;
    //     opacity: 1;
    //     path {
    //     }
    // }
    &.image-selected {
        .image-wrapper {
            // position: relative;

            // width: 290px;
            // height: 290px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            // &:before {
            //     content: "";
            //     z-index: 1;
            //     top: 0;
            //     left: 0;
            //     position: absolute;
            //     width: 100%;
            //     height: 100%;
            //     // background: -moz-radial-gradient(transparent 150px, rgba(0, 0, 0, 1) 150px);
            //     background: -webkit-radial-gradient(transparent 145px, rgba(255, 255, 255, 0.8) 145px);
            //     // background: -ms-radial-gradient(transparent 150px, rgba(0, 0, 0, 1) 150px);
            //     // background: -o-radial-gradient(transparent 150px, rgba(0, 0, 0, 1) 150px);
            //     pointer-events: none;
            // }
            // &:after {
            //     content: '';
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%,-50%);
            //     border: 2px dashed #fff;
            //     width: 100%;
            //     height: 100%;
            //     z-index: 2;
            //     border-radius: 50%;
            // }
        }
        .file-image {
            // border-radius: 10px;
            width: 100%;
            height: 100%;
        }
        strong,
        p {
            display: none;
        }
    }
    .file-image {
        width: 90px;
        height: 90px;
        margin: 0 auto;
        display: block;
        // border-radius: 15px;
        object-fit: cover;
        object-position: center;
    }

    & > .wrapper {
        // background-color: #fff;

        cursor: pointer;
        margin: 0 30px;
        padding: 20px;
        border: 1px dashed color(muted, 0.3);
        border-radius: 10px;
    }

    .info {
        font-size: 12px;
        // color: rgba(0, 0, 0, 0.6);
        max-width: 400px;
        margin: 0 auto;
        line-height: 1.7;
    }

    strong {
        display: block;
        margin-top: 20px;
        margin-bottom: 5px;
        // color: color(dark);
    }
    p {
        display: block;
        font-size: 14px;
    }


    // .border-dashed {
    // border-radius: 10px;
    //     padding: 30px 10px;
    //     background-color: #fff;
    //     max-width: 247px;
    //     margin: 0 auto;
    // }
}

.upload-cards {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // padding: 0 20px;
    margin-top: 15px;
}