.invitation-page {
    max-width: 100%;
    min-height: 100vh;
    padding: 0;
    display: flex;
    align-items: center;
    & > .container {
        padding-top: 40px;
        padding-bottom: 40px;
        height: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        @include mq(1100px) {
        }
    }
    .form {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        & > .wrapper {

        }
        .logo {
            max-width: 240px;
            margin: 0 auto 50px;
            display: block;
        }
        &-title {
            margin-bottom: 20px;
            text-align: center;
        }
        &-description {
            margin-bottom: 40px;
            text-align: center;
        }
        .btn-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;
            white-space: nowrap;
            margin-bottom: 30px;
            @include mq(768px){
                flex-wrap: nowrap;
            }
            .btn {
            }
        }
    }
    .copyright {
        font-size: 14px;
        margin: 0 auto;
        max-width: 407px;
    }
}
