.estimated-results {
    background-color: #fff;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    position: sticky;
    top: 10%;
    border-radius: 14px;
    // margin: 30px 0;

 
    
    hr {
        background-color: color(muted, 0.4);
        border-top: 0;
    }
    &-header {
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 20px;

        .estimated-title-conatiner {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .select {
            margin-bottom: 0 !important;
            .select-toggle-wrapper {
                button {
                    border-color: lightgray;
                }
            }
        }

    }
    &-body {
        padding: 30px 20px;

        & > .wrapper {
        padding: 20px;
        padding-bottom: 0;

        }

        .wrapper-conatiner {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 20px;

        }
    }
    &-title {
        font-size: 16px;
        font-weight: 500;
        color: color(dark, 0.9);
    }
    .title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
        color: color(muted);
        border-bottom: 2px dashed color(muted);
        display: inline-block;
    }
    .row {
        display: flex;
        align-items: center;
        width: fit-content;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
        .line-skeleton {
            opacity : 0;
        }
        &.isLoading {
            .start,
            .end {
                opacity: 0;
            }
            .line-skeleton {
                opacity: 1;
            }
        }
        .error {
            all: unset;
            font-size: 14px;
            color: color(danger,.5);
        }
    }
    &-footer {
        padding: 20px;
        font-size: 12px;
        color: color(muted);
    }

    .estimated-description {
        font-size: 12px;
        padding: 20px;
    }
}
