.testimonials {
    background-image: url("../../assets/images/icons/layer.png"),
        linear-gradient(160deg, rgb(6, 23, 38) 19%, rgb(255, 255, 255, 0.5) 699%);
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    border-radius: 20px;
    color: #fff;
    padding: 80px 20px 120px;
    display: flex;
    align-items: flex-end;

    @include mq(1100px){
        padding-bottom: 180px;
    }
    & > .wrapper {
        width: 100%;
        max-width: 405px;
       
        margin: 0 auto;
    }
    &-icon {
        margin-bottom: 40px;
    }

    &-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    .carousel {
    }
    .carousel-card {
        display: flex;
        flex-direction: column;
        min-height: 170px;
        .card {
            &-description {
                font-size: 14px;
                line-height: 160%;
                font-weight: 400;
                margin-bottom: 20px;
                flex: 1;
            }
            &-name {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 2px;
            }
            &-position {
                font-size: 14px;
            }
        }
    }
}
