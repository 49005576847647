.geochart-wrapper {
    .chart-skeleton {
        @include mq(992px){
            grid-template-columns: 2fr 1fr;
        }
    }
    .country-metrics {
        background-color: #ffff;
        padding: 0;
        &-inner {
            padding: 22px;
            position: relative;
            // min-height: 400px;
            min-height: 300px;
            @include mq(992px){
                display: grid;
                justify-content: space-between;
                grid-gap: 50px;
                grid-template-columns: 1fr auto;
                align-items: start;
            }
    
            & > .wrapper {
                // max-width: 720px;
                overflow-x: auto;
                overflow-y: hidden;
            }
        }
        .details {
            width: 100%;
            font-size: 12px;
            text-transform: uppercase;
            border: 1px solid color(secondary,.1);
            border-radius: 8px;
            padding: 15px 15px;
            margin-top: 50px;
            position: relative;
            padding-right: 10px;
          
            @include mq(992px){
                margin-top: 0px;
                width: 255px;
            }
            &-header {
                display: flex;
                align-content: center;
                justify-content: space-between;
                margin-bottom: 10px;
                padding-bottom: 3px;
                margin-right: 10px ;
                padding-right: 5px ;
                // border-bottom: 1px solid color(secondary,.2);
            }
            &-body {
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 10px;
                max-height: 200px;
                @include mq(992px) {
                    max-height: 350px;

                }
                &::-webkit-scrollbar {
                    width: 4px;
                }
            }
            .progress-wrapper {
                margin-bottom: 18px;
            }
        }
    }    
}

