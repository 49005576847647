.insights-table {
}

.insights-table-wrapper {
    ul {
        max-width: 310px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        li {
            user-select: none;
            &.selected {
                a {

                    background-color: color(light,.5) !important;
                }
                // border: 1px solid color(secondary,.3);
            }
            &.previous {
                margin-right: auto;
            }
            &.next {
                margin-left: auto;
            }
            &.previous,
            &.next {
                &.disabled {
                    pointer-events: none;
                }
                svg {
                    width: 8px;
                    height: auto;
                    path {
                        fill: color(secondary);
                    }
                }
            }
            a {

                width: 26px;
                height: 26px;
                // background-color: color();
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                border-radius: 50%;
                cursor: pointer;
                // transition: background-color .3s ease;
                &:hover {
                    background-color: color(light,.3);
                }
            }
        }
    }

}