.marketplace-page {
    .swiper-slide {
        max-width: 350px !important;
    }

    .loading-spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5rem;
    }
    .featured-packages {
        padding: 0;
        .carousel {
        }
        .swiper {
            overflow: visible;
            // padding-bottom: 30px;
            // padding-left: 10px;
            // padding-right: 10px;
            &-slide {
                .marketplace-card {
                }
            }
        }
        &-title {
            margin-bottom: 20px;
        }
        .carousel-angles {
            z-index: 1;
            left: 50%;
            transform: translateX(-50%);
            @include mq(576px) {
                right: 0;
                left: auto;
                transform: none;
            }
        }
        .carousel-angle {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            // border: 2px solid color(marketplace);
            svg {
                width: 17px;
                height: 17px;
                path {
                    fill: color(dark) !important;
                }
            }
        }
    }
}

.marketplace-details-modal {
    .marketplace-details {
        &-close-btn {
            svg {
                width: 20px;
                height: 20px;
                background-color: #fff;
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
                border-radius: 50%;
                padding: 3px;
                margin-left: 5px;
            }
        }
    }
    .modal-description {
        // background-color: color(light, 0.2);
        // padding: 15px;
        // border-radius: 10px;
    }
    .modal-content {
        min-height: calc(100vh - 40px);
        padding: 30px 20px;
        border-radius: 10px;
    }
    .modal-header {
        padding: 0;
        padding-top: 30px;
        & > .wrapper {
            display: flex;
            align-items:center;
            gap: 30px;
            flex-wrap: wrap;
        }
       
    }
    .modal-body {
        .row-1 {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 10px 30px;
            @include mq(768px){
                flex-wrap: nowrap;
            }
            .col-left {
              width: 100%;
              max-width: 200px;
              max-height: 200px;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 100px;
                  overflow: hidden;

              }
            }
            .col-right {
                background-color: color(light,.2);
                padding: 15px 15px;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
            }
            .content-1 {
                flex: 1;
            }
            .content-2 {
                font-size: 12px;
                color: color(marketplace);
                text-align: right;
                margin-bottom: 0;
            }
        }
    }
    .modal-title {
        margin-bottom: 0;
    }
    .discount-badge {
        font-size: 14px;
        // border: 1px solid color(primary);
        border-bottom: 1px solid color(primary);
        color: color(primary);
        display: inline-block;
        margin-bottom: 30px;
    }
    .products {
        background-color: #ececec;
        // background: color(marketplace);
        padding: 10px;
        // border-radius: 10px;
        display: flex;
        gap: 5px 20px ;
        flex-wrap: wrap;
        // border: 1px solid color(secondary,.1);
        // box-shadow: 0 2px 6px rgba(0,0,0,0.1);
        border-bottom: 1px solid color(secondary,.1);
    }
    .product {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 5px;
        border-radius: 4px;
        
        // background-color: color(light, 0.05);
        // background-color: rgba(255, 255, 255, 0.1);
        &:not(:last-of-type) {
            margin-bottom: 5px;
        }
        &-title {
            font-size: 12px;
            // width: 200px;
            color: #fff;
            font-weight: 400;
            color: color(dark);
        }
        &-logo {
            max-width: 150px;
            height: 100%;
            object-fit: contain;
        }
    }
    .price {
        font-weight: 700;
        font-size: 18px;
    }
    
    .total-reach {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        display: inline-block;
        border-bottom: 1px solid color(primary);
        color: color(primary);
        line-height: 1.1;
        margin-bottom: 0px;
    }
    
    .included {
        &-title {
            margin-bottom: 3px ;
            position: relative;
            padding-left: 15px;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 5px;
                height: 5px;
                background-color: color(secondary,.5);
                // background-color: color(dark);
                // background-color: color(primary);
                border-radius: 50%;
                // border: 2px solid color(secondary,.5);
            }
          
        }
    }

    .modal-footer {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.shopping-cart-btn,
.wishlist-btn {
    width: 38px;
    height: 38px;
    svg {
        width: auto !important;
        height: auto !important;
        filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
        path {
            fill: color(dark);
        }
    }
    .badge {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 13px;
        height: 13px;
        // background-color: rgba(0,0,0,0.6);
        // background-color: rgba(255,255,255);
        background-color: color(primary);
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 9px;
        font-weight: 500;
        &-primary {
            background-color: color(primary);
        }
        &-success {
            background-color: color(success);
        }
    }
}