.field {
    margin-bottom: 15px;
    position: relative;
    & > .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        margin-bottom: 8px;
        .link {
            font-size: 12px;
        }
    }
    .input-group {
        position: relative;

    }
    &-startIcon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
        & + input {
            padding-left: 30px;
        }
    }
    &-endIcon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 3px;
        & + input {
            padding-right: 40px;
        }
    }
    .max-length {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 12px;
        font-weight: 500;
        & + input {
            padding-right: 40px;
        }
    }
    .custom-tooltip {
        max-width: 330px !important;
    }

    .additional-message {
        font-size: 10px !important;
        color: #000 !important;
        position: absolute;
        left: 0;
        margin-top: 3px;
        display: flex;
        align-items: flex-start;
        gap: 3px;
     
    }
    .info-popup {
        position: absolute;
        top: 5px;
        right: 3px;
        // background-color: color(warning, .3) !important;
        width: 15px;
        height: 15px;
        svg {
            height: 8px;
            path {
            }
        }
    }
}


textarea {
    resize: none;
    min-height: 170px;
}

.form-label {
    font-size: 14px;
    // color: color(dark);
    font-weight: bold;
}
.form-label-secondary {
    font-size: 12px;
    color: color(muted);
}
.form-control {
    font-family: Inter !important;
    display: block;
    width: 100%;
    padding: 12px 12px;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    background: none;
    outline: none;
    border-width: 1px;
    border-style: solid;
    color: color(dark);
    border-color: rgba(0, 0, 0, 0.23);
    appearance: none;
    border-radius: 8px;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    // &::selection {
    //     background-color: color(primary);
    // }
    // &[type='date']::-webkit-calendar-picker-indicator {
    //     display: none;
    //     position: absolute;
    // }
    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
    &::placeholder {
        // text-transform: capitalize;
        color: inherit;
        opacity: 0.4;
    }
    &-sm {
        padding: 6px 8px;
        height: 35px;
    }

    &-secondary {
        color: color(dark);
        border-color: color(secondary, 0.3);
        &:hover {
            border-color: rgba(0, 0, 0, 0.5);
        }
        &:focus {
            border-color: rgba(0, 0, 0, 1);
        }
    }

    &-primary {
        border-color: color(primary, 0.5);
        color: color(primary);
     
        &:hover {
            border-color: color(primary, 0.7);
        }
        &:focus {
            border-color: color(primary);
        }
    }
    &-success {
        border-color: color(success, 0.5);
        color: color(success);
        &:hover {
            border-color: color(success, 0.7);
        }
        &:focus {
            border-color: color(success);
        }
    }
}


