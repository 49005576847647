.campaign-details-popup {
 position: relative;
    &:hover {
        .popup-content {
            opacity: 1;
            transform: translateX(-50%) scale(1);
            pointer-events: auto !important;
            &.left,
            &.right,
            &.extended {
                transform: scale(1);
            }
        }
    }
    & > svg {
        width: 10px;
        height: 10px;
        object-fit: contain;
        path {
            fill: color(dark) !important;
            stroke: color(dark) !important;
        }
    }

    .popup {
        &-content {
            transform-origin: bottom center;
            pointer-events: none;
            position: absolute;
            opacity: 0;
            font-size: 10px;
            font-weight: 500;
            transition: 0.3s ease;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 3px;
            padding: 6px;
            color: #000;
            box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
            width: 100vw;
            max-width: 100px;
            text-align: left;
            bottom: 100%;
            left: 50%;
            margin-bottom: 10px;
            transform: translateX(-50%) scale(0.7);

        
            &.right {
                top: 0;
                bottom: auto;
                left: 20px;
                right: auto;
                transform-origin: left;
                transform: scale(0.7);
                max-width: fit-content;
                box-shadow: 0 2px 24px rgba(0, 0, 0, 0.4);
                & > svg {
                    right: 100%;
                    margin-right: -3px;
                    left: auto;
                    top: 50%;
                    bottom: auto;
                    transform: rotateZ(0deg) translateY(-50%);
                    margin-top: 0;
                }
            }

            & > svg {
                width: 7px;
                height: auto;
                object-fit: contain;
                position: absolute;
                left: 50%;
                top: 100%;
                transform: translateX(-50%) rotateZ(-90deg);
                margin-top: -7px;
                path {
                    fill: #fff;
                }
            }

            &-details {
                padding: 10px;
            }
        }
    }
}