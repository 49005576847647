.campaigns-page {
    padding: 0 !important;
    & > .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px 30px;
        justify-content: space-between;
        margin-bottom: 25px;
    }


}

.main {
    // padding: 2rem !important;
}

.campaign-page {
    padding: 2rem;
}

.campaign-page-header {
    // border-bottom: 2px solid rgba(31, 131, 82, 1);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    background: #f8f8f8;
    padding: 0.9rem 2rem;
    position: sticky;
    top: 0;
    z-index: 10;
    padding-top: 1.5rem;

    .dropdown-sm {
        .dropdown-toggle {
            padding: 8px 10px !important;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 992px) {
    .data-range-select-container {
        margin-left: 0 !important;
    }
    .campaign-targeting-conatiner {
        flex-wrap: wrap !important;
        gap: 10px;
    }

    .campaign-status-conatiner {
        margin-left: 0 !important;
    }
    
}