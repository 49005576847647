.original-popup {
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;

    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
        background-color: color(success, 0.1) !important;
    }
    &:hover {
        .popup-content {
            opacity: 1;
            pointer-events: auto !important;
            &.top {
                transform: translateX(-50%) scale(1);
            }
            &.right {
                transform: translateY(-50%) scale(1);
            }
        }
    }
    & > svg {
        height: 13px;
        path {
            fill: color(dark) !important;
            stroke: color(dark) !important;
        }
    }

    .popup {
        &-content {
            transform-origin: bottom center;
            pointer-events: none;
            position: absolute;
            z-index: 1;
            opacity: 0;
            font-size: 10px;
            font-weight: 500;
            transition: 0.3s ease;
            background-color: rgba(255, 255, 255, 1);

            border-radius: 3px;
            padding: 6px;
            color: #000;
            // box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0px 15px rgba(0, 0, 0, 0.3);
            text-align: left;
            display: inline-block;
            margin-bottom: 10px;
            will-change: transform;
            &.top {
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%) scale(0.7);
                & > svg {
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%) rotateZ(-90deg);
                    margin-top: -7px;
                }
            }

            &.right {
                top: 50%;
                left: calc(100% + 7px);
                transform-origin: left;
                transform: translateY(-50%) scale(0.7);
                & > svg {
                    top: 50%;
                    right: 100%;
                    transform: translateY(-50%);
                    margin-right: -3px;
                    path {
                    }
                }
            }

            &.dark {
                background-color: color(dark);
                color: #fff;
                svg path {
                    fill: color(dark);
                }
            }

            & > svg {
                width: 7px;
                height: auto;
                object-fit: contain;
                position: absolute;

                path {
                    fill: #fff;
                }
            }
        }
    }
}
