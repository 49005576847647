.accounts {
    color: color(dark);
    // color: rgba(255,255,255,0.8);
    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 50px;
        margin-top: 30px;
        // justify-content: space-between;
    }
    .search-bar-wrapper {
        margin-top: 50px;
        margin-bottom: 30px;
        @include mq(480px) {
            gap: 10px;
            display: flex;
            align-items: center;
        }
    }

    .accounts-cover {
        width: 100%;
        max-width: 200px;
        height: auto;
    }
    .accounts-close-btn {
        gap: 5px;
        min-width: auto !important;
        svg {
            height: 30px;
            width: auto;
            display: block;
        }
    }
    .date {
        // max-width: 450px;
        margin-top: 20px;
        li {
            display: flex;
            gap: 20px;
            margin-top: 10px;
            font-size: 12px;
            margin-top: 5px;
            b {
                width: 150px;
            }
            span {
                color: color(success);
            }
        }
    }
    .btn-close {
        background-color: color(purple);

        transition: all 0.3s ease;
        &:hover {
            background-color: color(purple-hover);
        }
        svg path {
            // fill: #fff;
        }
    }
    .modal-title {
        text-align: left;
        margin-bottom: 0;
        color: color(purple);
        font-size: 21px;
        font-weight: 700;
    }
    &.modal-dialog {
        // background-color: rgb(20,20,20);
    }
    .modal-content {
        background-color: transparent !important;
    }

    .table {
        border-collapse: collapse;
        width: 100%;
        font-size: 12px;
        // color: color(dark);
        margin: 10px 0;
        @include mq(768px) {
            font-size: 14px;
        }
        &-th {
            &-details {
                .original-popup {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
        }
        &-td {
            &-name {
                font-weight: 600;
                font-size: 13px;
                white-space: nowrap;
                max-width: 200px;
                span {
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
            &-bca-name {
                &.disabled {
                    pointer-events: none;
                    position: relative;
                    .field {
                        &:after {
                            content: "Refreshing...";
                            position: absolute;
                            border-radius: 5px;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            backdrop-filter: blur(3px);
                            font-size: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                .field {
                    min-width: 150px;
                    margin-bottom: 0;
                    input {
                        color: inherit;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        background-color: transparent;
                        height: auto;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
            &-admin-email,
            &-name {
                // max-width: 200px;
                // overflow-x: hidden;
                // text-overflow: ellipsis;
                display: flex;
                align-items: center;
            }
            &-hush {
                width: 25px;
            }
        }
        .details {
            &-tr {
                background-color: color(dark, 0.03);
            }
            // &-title {
            //     text-align: center;
            //     background-color: color(dark,.03);
            //     padding: 5px;
            //     font-weight: 500;
            // }
        }
        thead {
            tr {
                // border-bottom: 1px solid rgba(255,255,255,0.3);
                border-bottom: 1px solid color(purple);
            }
        }
        tbody {
            transition: opacity 0.3s ease;
            &.hide {
                opacity: 0;
            }
            tr {
            }
        }
        tr {
            &:not(:last-of-type) {
                // box-shadow: 0px 0px 2px color(light);
                border-bottom: 1px solid color(light);
                // border-bottom: 1px solid rgb(255, 0, 255,.2);
            }
            &.campaign-details-tr {
            }
        }
        th {
            text-align: left;
            font-weight: bold;
        }
        td {
        }
        th,
        td {
            padding: 13px 10px;
            white-space: nowrap;
            // vertical-align: top;
        }
    }

    .text-inactive {
        color: lightgray;
    }
}
.cta {
    text-decoration: none;
    color: inherit;
    position: relative;
    margin: auto;
    padding: 1em 2em;
    transition: all 0.2s ease;
    margin-bottom: 30px;
    display: block;
    cursor: pointer;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border-radius: 2rem;
        background: rgba(142, 72, 238, 0.5);
        // background: color(primary);
        width: 40%;
        height: 100%;
        transition: all 0.3s ease;
    }
    span {
        position: relative;
        font-size: 0.875rem;

        font-weight: bold;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
        margin-right: 1em;
    }
    svg {
        position: relative;
        top: 0;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: #fff;
        stroke-width: 2;
        transform: translateX(-0.5rem);
        transition: all 0.3s ease;
    }
    &:hover {
        &:before {
            width: 100%;
            background: color(primary);
        }
        svg {
            transform: translateX(0);
        }
    }
    &:active {
        transform: scale(0.96);
    }
}

// ================================================
.glitch-wrapper {
    background-color: #232323;
    height: 10rem;
    margin-bottom: 1rem;
    position: relative;
}

.glitch {
    font: {
        size: 4rem;
        family: "Raleway", sans-serif;
        weight: 700;
    }
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: #fff;
    letter-spacing: 5px;

    &:before,
    &:after {
        display: block;
        content: attr(data-glitch);
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.8;
    }
    &:after {
        color: #f0f;
        z-index: -2;
    }
    &:before {
        color: #0ff;
        z-index: -1;
    }
    &:hover {
        &:before {
            animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 5;
        }
        &:after {
            animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both 5;
        }
    }
}

@media only screen and (max-width: 400px) {
    .glitch {
        font-size: 3em;
    }
}

@keyframes glitch {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-5px, 5px);
    }
    40% {
        transform: translate(-5px, -5px);
    }
    60% {
        transform: translate(5px, 5px);
    }
    80% {
        transform: translate(5px, -5px);
    }
    to {
        transform: translate(0);
    }
}
/////////////////////////////////////////////////////////////
@import url("https://fonts.googleapis.com/css?family=Montserrat:100");

#app {
    border-radius: 10px;
    // box-shadow: 0 0px 200px 50px  #000;
    font-family: "Montserrat", sans-serif;
    background: rgb(10, 10, 10);
    height: 40vh;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(10, 10, 10, 0.6), rgba(0, 0, 0, 0.9)),
        repeating-linear-gradient(0, transparent, transparent 2px, black 3px, black 3px),
        url("https://images.unsplash.com/photo-1506399558188-acca6f8cbf41?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=7118759521e3184778a3b5e010e202c6");
    background-size: cover;
    background-position: center;
    z-index: 1;
}

#wrapper {
    text-align: center;
}

@mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch-2 {
    font-weight: 100;
    opacity: 0.3;
    position: relative;
    color: white;
    // font-size: 4em;
    font-size: 4vw;
    letter-spacing: 0.5em;
    animation: glitch-skew 1s infinite linear alternate-reverse;
    &::before {
        @include glitchCopy;
        left: 2px;
        text-shadow: -2px 0 #ff00c1;
        clip: rect(44px, 450px, 56px, 0);
        animation: glitch-anim 5s infinite linear alternate-reverse;
    }
    &::after {
        @include glitchCopy;
        left: -2px;
        text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
        animation: glitch-anim2 1s infinite linear alternate-reverse;
    }
}

@keyframes glitch-anim {
    $steps: 20;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100) + px, 9999px, random(100) + px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-anim2 {
    $steps: 20;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100) + px, 9999px, random(100) + px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-skew {
    $steps: 10;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            transform: skew((random(10) - 5) + deg);
        }
    }
}


.ellipsis {
    max-width: 180px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.table-td-name:hover {
    font-weight: 800;
    cursor: pointer !important;
}