.upload-image-basic {
    text-align: center;
    margin: 0 auto;
    // max-width: 250px;
    // margin-bottom: 10px;
    .wrapper {
        
    }
    .file-image {
        width: 50px;
        height: 50px;
    }
    &.image-selected {
        .image-wrapper {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
    
        }
        .file-image {
            // border-radius: 10px;
            width: 100%;
            height: 100%;
        }
        strong,
        p {
            display: none;
        }
    }
    .file-image {
        width: 90px;
        height: 90px;
        margin: 0 auto;
        display: block;
        // border-radius: 15px;
        object-fit: cover;
        object-position: center;
    }

    & > .wrapper {
        // background-color: #fff;

        cursor: pointer;
        padding: 20px;
        border: 1px dashed color(muted, 0.3);
        padding: 10px;
        border-radius: 10px;
        img {
            display: block;
            max-width: 100%;
            max-height: 250px;
            margin: 0 auto;
        }
    }

    .info {
        font-size: 12px;
        // color: rgba(0, 0, 0, 0.6);
        max-width: 400px;
        margin: 0 auto;
    }

    strong {
        display: block;
        margin-top: 20px;
        margin-bottom: 5px;
        // color: color(dark);
    }
    p {
        display: block;
        font-size: 14px;
    }



}

