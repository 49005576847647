// .parent {
//     position: relative;
//     display: flex;
//     justify-content: center;
// }

// :root {
//     --border-color: #8e8ea0;
//     --text-color: black
// }

// .topSection {
//     width: 100%;
//     height: 50px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     z-index: 1;
//     position: sticky;
//     top: 0px;
// }

// .Download {
//     padding: 6px 10px;
//     border-radius: 10px;
//     border: 1px solid var(--border-color);
//     margin-right: 20px;
// }

// .Chat {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     /* z-index: 6; */
// }

// .InnerChat {
//     width: 90%;
//     height: 100%;
// }

// .InputSection {
//     z-index: 1;
//     width: 100%;
//     height: 75px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     position: fixed;
//     bottom: 0;
//     margin-top: 10px;
// }

// .InputMain {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin: 0 auto;
//     width: 70%;
//     border: 1px solid var(--border-color);
//     padding: 7px;
//     border-radius: 10px;
// }



// .input {
//     border: none;
//     width: 100%;
//     padding-left: 10px;
//     font-size: small;
//     color: #9b9ca2;
// }

// .input:placeholder-shown {
//     font-size: small;
// }

// .input:focus {
//     border: none;
//     outline: none;
// }

// .Submit {
//     display: flex;
//     justify-items: center;
//     align-items: center;
//     border-radius: 7px;
//     padding: 5px 5px;
//     color: white;
//     border: 1px solid var(--border-color);
//     background-color: var(--border-color);
// }

// .Submit:hover {
//     background-color: #343541;
// }

// .InformationText {
//     color: var(--text-color);
//     font-size: 0.75rem;
//     line-height: 1rem;
//     text-align: center;
//     width: 70%;
//     margin-top: 0.5rem;
//     margin-bottom: 2rem;
//     background-color: #fff;
// }

// .chatMainDiv {
//     display: flex;
//     margin: 10px 0px;
// }

// .chatLogo {
//     border-radius: 180px;
//     height: 30px;
//     width: 30px;
//     background-color: purple;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 10px 20px;
//     color: white;
//     font-size: small;
//     font-family: "Courier New", Courier, monospace;
//     font-weight: 900;
// }

// .chatLogo img {
//     padding: 2px;
//     object-fit: cover;
// }

// .chatTitile {
//     width: 680px;
//     margin: 10px 0px;
//     color: var(--text-color);
//     font-weight: 900;
// }

// .chatText {
//     width: 680px;
//     color: var(--text-color);
//     font-weight: 400;
//     line-height: 1.5;
// }

// .ChatScrollbar {
//     height: 100%;
//     overflow-y: auto;
// }


// /* medium screens  */
// @media (max-width: 768px) {
//     .InputMain {
//         width: 80%;
//     }

//     .chatTitile {
//         width: 80%;
//     }

//     .chatText {
//         width: 80%;
//     }

//     .InformationText {
//         width: 80%;
//     }
// }

// @media (max-width: 480px) {
//     .InputMain {
//         width: 90%;
//     }

//     .chatTitile {
//         width: 90%;
//     }

//     .chatText {
//         width: 90%;
//     }
// }

.chat-page {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    height: 100%;
    .chat-section {
        flex: 1;
            .basic-chat {
                height: 100%;
            }
             .container {
                // padding-right: 0;
                // padding-left: 0;
                max-width: 650px;
                @include mq(1280px){
                    max-width: 768px;

                }
            }
        // & > .container {
        //     height: 100%;
        //     max-width: calc(740px + 80px * 2) ;
        //     .basic-chat {
        //         height: 100%;
        //     }
        // }
     
    }
}