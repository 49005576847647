.forgot-password-page {
    max-width: 100%;
    min-height: 100vh;
    padding: 0;

    & > .container {
        padding-top: 40px;
        padding-bottom: 40px;
        height: 100%;
        & > .wrapper {

            width: 100%;
            max-width: 407px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            @include mq(1100px) {
                margin-bottom: 0px;
            }
        }
    }

    .form {
        display: flex;
        flex-direction: column;

        // .logo {
        //     max-width: 240px;
        //     margin: 0 auto 40px;
        // }
        &-description {
            font-size: 14px;
            text-align: center;
            margin-bottom: 40px;
        }
        &-title {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 10px;
        }
        .field {
            margin-bottom: 20px;
        }
        .submit-btn {
            margin-top: 12px;
            margin-bottom: 20px;
        }
    }

    .login-signup-now {
        margin: 0 auto;
    }



    .copyright {
        font-size: 14px;
        margin: 0 auto;
        max-width: 407px;
        text-align: center;
    }
   
}
