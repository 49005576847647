.shopping-cart-page {
    .shopping-cart {
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px 50px;
            flex-wrap: wrap;
        }
        &-items-count {
            font-weight: 600;
        }
    }
    .setup-btns {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        .btn {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        }
    }
    .setup-btn {
        svg {
            width: auto !important;
            height: auto !important;
        }
    }
    .empty-cart-icon {
        flex: 1;
        margin-top: 30px;
        svg {
            max-width: 200px;
            height: auto;
            margin: 0 auto;
            display: block;
        }
    }
    .row {
        .col {
            &-left {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                grid-gap: 0px 10px;
                .marketplace-card {
                    margin-bottom: 10px;
                }
            }
        }
        @include mq(786px) {
            display: flex;
            gap: 30px;
            .col {
                &-left {
                    flex: 1;
                }
                &-right {
                    margin-top: 53px;
                    flex: 1;
                    max-width: 250px;
                }
            }
        }
    }
    .submit-order {
        border-radius: 10px;
        padding: 40px 20px;
        width: 100%;
        background-color: #ffff;
        // border: 1px solid color(secondary);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        @include mq(768px) {
            // max-width: 200px;
        }
        .price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-text {
            }
            &-amount {
            }
        }
        hr {
            border-top: none;
            border-color: color(light, 0.5);
        }
        .submit-btn {
            white-space: nowrap;
            width: 100%;
        }
    }
}

