.campaign-objective-modal {
    font-family: Manrope;
    max-width: 900px;
    .modal {
        &-dialog {

        }
        &-title {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 20px;
            @include mq(992px) {
                font-size: 28px;
            }
        }
        &-description {
            font-size: 16px;
            color: color(muted);
            text-align: center;
        }
        &-body {
            border: none;
            display: grid;
            grid-gap:25px 15px;
            padding: 0 30px 30px;
            justify-content: center;
            @include mq(768px){
                grid-template-columns: 1fr 1fr 1fr;
            }
            .col {
                &-title {
                    font-size: 13px;
                    color: color(secondary,.8);
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 15px;
                }
            }
            .option-card {
                margin-bottom: 15px;
                max-width: 250px;
                @include mq(768px){
                    max-width: 100%;
                }
            }
        }
    }
}