.form-check {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    &-input {
        display: none;
    }
    &-btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        min-width: auto !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-input:checked ~ .form-check-btn .form-check-checkbox,
    &-input:checked ~ .form-check-checkbox {
        background-color: currentColor;
        border-color: currentColor;
        background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
        background-size:15px;
        background-position: center;
    }

    &.some-checked .form-check-input ~ .form-check-btn .form-check-checkbox,
    &.some-checked .form-check-input ~ .form-check-checkbox {
        border-color: currentColor;
        background-image: url("data:image/svg+xml,%3Csvg fill='%23000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
        background-size:10px;
        background-position: center;
        // .form-check-checkbox {
        //     border-color: color(dark) !important;
        // }
    }
    &-checkbox {
        width: 18px;
        height: 18px;
        border: 2px solid rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        cursor: pointer;
        transition: background-color .3s ease;
    }
    &-label {
        cursor: pointer;
        user-select: none;
        font-weight: 500 !important;
    }
    &.disabled,
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &-sm {
        margin-left: -6px;
        .form-check {
            &-btn {
                width: 25px;
                height: 25px;
                
            }
            &-checkbox {
                width: 12px;
                height: 12px;
                background-size:10px !important;
            }
            &-label {
                font-size: 12px;
            }
        }
    }
}
