    .line-skeleton {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 12px;
        // background-color: color(light, 0.3);
        background-color:  #F8FBFF;
        box-shadow: 0 0 3px rgba(0,0,0,0.05);
        position: absolute;
        overflow: hidden;
        border-radius: 100px;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, transparent, color(secondary, 0.05), transparent);
            animation: loading 1s infinite;
        }
        @keyframes loading {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(100%);
            }
        }
    }
    