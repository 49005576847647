.campaigns-graph {
    min-height: 300px;
    padding: 0;
    background-color: #fff;
    &-inner {
        padding: 22px;
        position: relative;
        // align-items: start;

        @include mq(992px) {

        }
    }
    .no-data {
        align-self: start;
    }
    .graph {
        display: flex;
        flex-direction: column;

        &-header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 15px;
            margin-bottom: 20px;
            img {
                width: 75px;
                height: 75px;
                object-fit: cover;
                border-radius: 50%;
                display: block;
                border: 2px solid #e3e3e3;
            }
            span {
                display: block;
                margin-bottom: 5px;
                line-height: 1;
            }
            strong {
                line-height: 1;
                display: block;
                img {
                    display: block;
                }
            }
        }
        &-body {
            // padding-top: 50px;
            flex: 1;
            position: relative;
            background-color: color(primary, 0.2);
            padding: 15px 15px;
            border-radius: 15px;
            .custom-tooltip {
                opacity: 1;
            }
        }
        &-text {
            color: color(secondary);
            font-size: 14px;
            strong {
                text-transform: capitalize;
                font-size: 16px;
                color: color(dark);
            }
        }
        .prefix {
            font-size: 16px;
            // color: color(dark, .6);
            display: block;
            font-weight: 700;
            // font-family: Roboto;
            // padding-left: 5px;
            margin-bottom: 3px;
            color: color(dark);
        }

        &-title {
            font-weight: 500;
            text-transform: none;
            white-space: nowrap;
            // max-width: 90%;
            overflow-x: hidden;
            text-overflow: ellipsis;
            margin-bottom: 20px;
            font-size: 18px;
            // display: flex;
            // align-items: center;
            color: color(dark,.8);
            marquee {
                // white-space: nowrap;
            }
        }
        .btn-icon {
            background-color: rgba(255, 255, 255, 0.3);
        }
        .select {
            margin-bottom: 17px;
        }

        .division {
            display: flex;
            align-items: center;
            gap: 7px;
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 10px;
            }
            &-title {
                font-size: 14px;
                color: color(secondary);
                font-weight: 400;
                margin-bottom: 5px;
            }
            &-amount {
                font-size: 16px;
                color: color(dark);
                font-weight: bold;
            }
        }
    }

    .divider {
        margin: 30px 0;
        @include mq(992px) {
            margin: 0;
        }
    }

    .chart-wrapper {
        position: relative;
        // &-loading {
        //     // position: relative;
        //     // &:after {
        //     //     content: 'Loading...';
        //     //     text-align: center;
        //     //     display: block;

        //     //     position: absolute;
        //     //     top: 50%;
        //     //     left: 50%;
        //     //     transform: translate(-50%,-50%);
        //     //     color: color(secondary);
        //     // }
        //     // & > * {
        //     //     pointer-events: none;
        //     //     opacity: 0.2;
        //     //     user-select: none;
        //     // }
        // }
        // .loading {
        //     position: absolute !important;
        //     top: 50% !important;
        //     left: 50% !important;
        //     transform: translate(-50%,-50%) !important;
        //     img {
        //         margin: 0 auto;
        //         display: block;
        //     }

        // }
        .loader {
            .message {
                position: absolute;
                top: 0px;
                left: 0%;
                width: 100%;
                height: 100%;
                text-align: center;
                //    background-color: rgba;
                padding: 10px;
                z-index: 1;
                border-radius: 4px;
                backdrop-filter: blur(3px);
                text-align: center;
                padding-top: 40px;
                font-size: 12px;
                //    font-weight: 500;
                color: color(secondary);
                @include mq(576px) {
                    font-size: 16px;
                }
            }
            img {
                width: 30px;
                position: absolute;
                z-index: 1;
                top: 80px;
                left: 50%;
                transform: translateX(-50%);
                filter: grayscale(100%);
                border-radius: 8px;
            }
        }

        & > .wrapper {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
            @include mq(1200px) {
                flex-direction: row;
                align-items: center;
            }
        }
        .row {
            &-bottom {
                .col {
                    &-left {
                        display: none;
                    }
                }
            }
        }
        .btn-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 3px;
        }

        .date-dropdown {
            .dropdown-menu {
                @include mq(576px) {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}


.css-127evc8-MuiCardContent-root:last-child {
    padding-bottom: 0 !important;
}