.audience-estimate {
    min-width: 320px;
    background-color: #fff;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin: 30px 0;
    // min-height: 300px;
    display: flex;
    flex-direction: column;
    @include mq(992px) {
        max-width: 320px;
    }
    &-title {
        font-size: 16px;
        font-weight: 500;
        color: color(dark, 0.9);
    }
    hr {
        background-color: color(muted, 0.4);
        border-top: 0;
    }
    &-header {
        gap: 5px;
        display: flex;
        padding: 20px;
    }
    &-body {
        min-height: 50px;
        padding: 20px;
        flex: 1;
    }

    &-value {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
    }
    &-description {
        font-size: 14px;
        max-width: 250px;
    }
    &-unable {
        font-size: 14px;
        color: color(danger,.5);
    }
    &-footer {
        padding: 20px;

        font-size: 12px;
        color: color(muted);
    }
}
