.analytics-page {
    padding: 0 !important;

    .analytics-page-header {
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        background: #f8f8f8;
        padding: 0.9rem 2rem;
        position: sticky;
        top: 0;
        z-index: 10;
    }

    .calendar-dropdown {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .dropdown-toggle-wrapper {
            margin-top: 16px;

            .dropdown-toggle {
                padding: 6px 10px;
                border: 1px solid #06172680;
                background-color: white;
            }

            button {
                // border: 1px solid #06172680;
            }
        }
    }

    .analytics-page-body {
        padding: 2rem;
    }

    .analytics {
        &-title {
            font-size: 14px;
            font-weight: 400;
            padding: 4px 6px;
            background-color: color(dark,.05);
            border-radius: 4px;
            display: inline-block;
            img {
                max-width: 30px;
                margin: 3px 10px;
            }
        }
    }
    & > .row {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 15px 30px;
        justify-content: space-between;
        margin-bottom: 10px;
        &  > .title {

        }
        & > .wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            width: 100%;
            & > .text {
                font-size: 14px;
                color: color(primary);
                white-space: nowrap;
                display: block;
                margin-bottom: 3px;
                img {
                    width: 20px !important;
                }
            }
            .btn {
                // white-space: nowrap;
            }
        }
        & > .dropdown {
            // flex: 1;
            .dropdown-menu {
                left: auto ;
                right: 0 ;
            }
        }
        .analytics-properties {
            width: 100%;
            max-width: 100%;
            @include mq(576px){
                width: 200px;

            }
            .select-menu {
                max-width: 100%;
            }
        }
    }
}

.disclosure-modal {
    .modal-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
    }
    .modal-header {
        padding: 25px 0 !important;
    }
    .modal-content {
        border-radius: 0;
        background-color: rgba(0,0,0,0.9);
        color: #fff ;
    }
    .modal-body {
        margin-bottom: 30px;
    }
    .modal-text {
        // color: rgba(255,255,255,.8);
        // font-weight: 300 !important;
    }
}