.publications-wrapper {
    position: relative;
    .scroll {
        border: 1px solid color(marketplace, 0.3);
        border-radius: 8px;
        padding: 8px 5px !important;
    }
    .scroll-items {
        position: sticky;
        top: 95px;
        z-index: 4;
        @include mq(768px) {
            top: 70px;
        }
        .swiper {
            padding-bottom: 3px;
        }
        .btn {
            padding: 0;
            min-width: auto;
            a {
                padding :3px 10px;
                backdrop-filter: blur(3px);
                // background-color: color(marketplace,.8);
                background-color: rgb(242, 242, 242, 0.3);
                border: 1px solid color(marketplace, 0.3);
                border-radius: inherit;
                //  color: #fff;
                color: color(marketplace, 1);
                &.active {
                    background-color: color(success);
                }
            }
        }
        .carousel-angles {
        }
        .swiper {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .shadow {
        border: 2px solid color(marketplace);

        // box-shadow: 0 0 3px color(light, 0.5);
        border-radius: 8px;
    }
}

.publications-table {
    width: 100%;
    font-size: 12px;
    // color: color(dark);
    border-collapse: collapse;
    // margin: 10px 0;
    @include mq(768px) {
        font-size: 14px;
    }

    .form-check {
        margin-left: 0;
    }
    border-radius: 5px;
    overflow: hidden;
    .top {
        // margin-top: -120px;
        transform: translateY(-140px);
        @include mq(768px) {
            transform: translateY(-120px);
        }
    }

    .table {
        &-row {
            &-marked {
                // background-color: purple;
                // color: #fff;
            }
        }
        &-th {
            &-settings {
                .settings-icon {
                    width: 12px;
                    height: 12px;
                    margin: 0 auto;
                    display: inline-block;
                }
            }

            &-details {
                .original-popup {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
            &-highlight {
                .form-check {
                    margin-left: 0;
                }
            }
        }
        &-td {
            &-name {
                font-weight: bold;
            }
            &-transaction {
                max-width: 200px;
                width: 50%;
                .original-popup {
                    display: block;
                }
                .blockHash {
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    width: 100%;
                }
            }
            &-hush {
                width: 25px;
            }
            &-highlight {
                .checkbox-wrapper {
                    // background-color: rgba(255,255,255,.3);
                    // border-radius: 50%;
                }

                .spin-loading {
                    width: 25px;
                    height: 25px;
                }
            }
            &-settings {
                .btn-group {
                    display: flex;
                    gap: 5px;
                    .btn {
                        width: 25px;
                        height: 25px;
                        .ripple {
                            display: none;
                        }
                        &-circle-primary {
                            &.active {
                                background-color: color(primary);
                            }
                        }
                        &-circle-success {
                            &.active {
                                background-color: color(success);
                            }
                        }
                        &.active {
                            svg path {
                                fill: #fff;
                            }
                        }
                        svg {
                            width: auto !important;
                            height: auto !important;
                            transform: scale(0.7);
                        }
                    }
                    .details-btn {
                        svg {
                            transform: scale(0.18);
                            margin-top: -5px;
                        }
                    }
                }
            }
            &-all-settings {
                pointer-events: none;
                .btn-group {
                    display: flex;
                    gap: 5px;
                    .btn {
                        overflow: visible;
                        width: 25px;
                        height: 25px;
                        .ripple {
                            display: none;
                        }
                        &-circle-primary {
                            &.active {
                                background-color: color(primary);
                            }
                        }
                        &-circle-success {
                            &.active {
                                background-color: color(success);
                            }
                        }
                        &.active {
                            svg path {
                                fill: #fff;
                            }
                        }
                        svg {
                            width: auto !important;
                            height: auto !important;
                            transform: scale(0.7);
                        }
                        .badge {
                            position: relative;
                        }
                    }
                }
            }
            &-media {
                white-space: nowrap;
                max-width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-footer {
            }
        }
        &-tr {
            &-summary {
                position: relative;
                // background-color: color(dark, 0.1);
                td {
                    text-align: center;
                }
                h2 {
                    // display: inline-block;
                    // vertical-align: middle;
                    font-size: 14px;
                    font-weight: 600;
                    // position: absolute;
                    // left: 50%;
                    // top: 50%;
                    // transform: translateX(-50%) translateY(-50%);
                }
                // .table-td-checkbox {
                //     position: relative;
                //     .float {
                //         position: absolute;
                //         transform: translateX(-100%) translateY(-50%);
                //         top: 50%;
                //     }
                // }

                // .form-check-checkbox {
                // }
            }
            &-extra-source {
                // opacity: 0.5;
                color: rgba(0, 0, 0, 0.4)
            }
            &-footer {
                // background-color: rgba(0,0,0,0.5);
                // color: #fff;
                background-color: color(light);
                color: color(marketplace);
                border-bottom: 2px solid color(marketplace) !important;
                h2 {
                    font-size: 14px;
                    font-weight: 700;
                }
                .wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 30px;
                    gap: 10px;
                    // .footer-btn-left {

                    //     svg {
                    //         margin-left: -3px;
                    //         margin-right: -5px;
                    //         width: auto;
                    //         height: auto;
                    //         transform: scale(0.7);
                    //     }
                    // }
                }

                .details-btn {
                    display: flex;
                    border-radius: 100px;
                    &:hover {
                        // background-color: color(light, 0.2);
                    }
                    svg {
                        // width: 20px;
                        // height: 20px;
                        background-color: #fff;
                        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
                        border-radius: 50%;
                        padding: 2px;
                        margin-left: 5px;
                        path {
                            fill: color(dark);
                        }
                    }
                }
                .add-to-cart-btn {
                    &.btn {
                        // width: 27px;
                        // height: 27px;
                        // background-color: #fff;
                        // border: 1px solid color(marketplace);
                        border-radius: 100px;
                        &:hover {
                            // background-color: color(marketplace, 0.1);
                        }
                        // .ripple {
                        //     display: none;
                        // }

                        &.active {
                            background-color: color(marketplace);
                            color: #fff;
                            svg path {
                                fill: #fff;
                            }
                        }
                        svg {
                            margin-left: -5px !important;
                            width: auto !important;
                            height: auto !important;
                            transform: scale(0.7);
                            path {
                                fill: color(marketplace);
                            }
                        }
                    }
                }
                .order-btn {
                    height: 25px;
                    // transition: 0.3s ease !important;
                    border-radius: 100px;
                    gap: 10px;
                    // &.hidden {
                    //     opacity: 0;
                    //     pointer-events: none;
                    // }
                }
                .circle-btn {
                    // box-shadow: 0 0px 3px rgba(255,255,255,.3);
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

                    background-color: rgba(255, 255, 255, 0.9);
                    .ripple {
                        display: none;
                    }
                    width: 25px;
                    height: 25px;
                    
                    &.active {
                        svg path {
                            fill: #fff;
                        }
                    }
                    svg {
                        width: auto !important;
                        height: auto !important;
                        transform: scale(0.7);
                    }
                    &.btn {
                        &-circle-primary {
                            &.active {
                                background-color: color(primary);
                            }
                        }
                        &-circle-success {
                            &.active {
                                background-color: color(success) !important;
                            }
                        }
                    }
                }
            }
        }
    }
    thead,
    .secondary-thead {
        // position: relative;
        // z-index: 3;
        background-color: color(marketplace);
        color: #fff;
        tr {
            background-color: inherit !important;
        }
        // .btn {
        //     width: 25px;
        //     height: 25px;
        // }
        // &.fixed {
        //     @include mq(768px) {
        //         top: 123px;
        //     }
        //     position: absolute;
        //     left: 0;
        //     z-index: 997;
        // }
    }
    tbody {
        transition: opacity 0.3s ease;
        &.hide {
            opacity: 0;
        }
        tr {
            &.active {
                background-color: color(success);
            }
            &:last-of-type {
                display: none;
            }
        }
    }
    tr {
        background-color: color(light, 0.15);
        &:not(:last-of-type) {
            border-bottom: 1px solid color(light);
        }
    }
    th {
        font-weight: 500;
    }
    td {
    }
    th,
    td {
        padding: 8px 10px;
        white-space: nowrap;
    }
}