.profile-page {
    padding-top: 2rem;
    & > .row {
        margin-bottom: 25px;
    }

    // TABS
    .edit-profile {
        .edit-avatar {
            position: relative;
            cursor: pointer;
            width: 100px;
            height: 100px;
            margin-bottom: 35px;
            img {
                border-radius: 50%;
                width: 100px;
                height: 100px;
                object-fit: cover;
                object-position: center;
            }
            .btn {
                position: absolute;
                top: 74px;
                right: 0px;
                background-color: color(primary);
                width: 25px;
                height: 25px;
                &:hover {
                    background-color: color(dark);
                    svg path {
                    }
                }
                .ripple {
                    opacity: 0.8;
                }
                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
    .form {
        // max-width: 280px;
        &-header {
            margin-bottom: 22px;

        }
        &-title {
            font-size: 16px;
        }
        &-description {
            font-size: 14px;
        }
        .field {
            margin-bottom: 15px;
        }
        .submit-btn {
            margin-top: 10px;
        }
    }
    .tab-content {
        margin-bottom: 30px;
    }
}
