.radio {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.6);
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 70%;
        height: 70%;
        border-radius: 50%;
        background-color: color(dark);
        display: none;
    }
    &.checked {
        &:after {
            display: block;
        }
    }
    &.disabled  {
        border-color: color(dark,.1);
        pointer-events: none;
    }
    &-danger {
        border-color: color(danger,.5);
        &:after {
            background-color: color(danger,.5);
        }
    }
    &-secondary {
        border-color: color(secondary,.5);
        &:after {
            background-color: color(secondary,.5);
        }
    }
    &-dark {
        border-color: color(dark,.7) !important;
        &:after {
            background-color: color(dark);
        }
    }
}

.radio-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    margin-bottom: 15px;
    user-select: none;
    .form-check-btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        min-width: auto !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .radio-text {
        font-size: 14px;
    }
    .field {
        flex: 1;
        margin-bottom: 0 !important;
    }
}