.campaigns-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
    // margin: 10px 0;
    // margin-top: 10px;
    @include mq(768px) {
        font-size: 14px;
    }
    .form-check {
        margin-left: 0 !important;
    }
    .table {
        &-th {
          
            &-details {
                .original-popup {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
            &-conversions {
                .conversions-dropdown {
                    // margin: 0 auto;
                    // margin-left: auto;
                    .dropdown-toggle-title {
                        font-weight: inherit;
                        font-size: inherit !important;
                        color: inherit;
                    }
                    li {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 12px;
                        padding: 0 5px;
                        color: color(secondary,.8);
                        font-weight: 500;
                        .stat-number {
                            // color: color(secondary,.7);
                            color: #000;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        &-td {
            &-name {
                font-weight: 600 !important;
                // font-size: 13px;
                white-space: nowrap;
                max-width: 200px;
                span {
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
                &-totals {
                }
            }
            &-settings {
                .play-pause-btn-wrapper-inner {
                    position: relative;
                    // display: flex;
                    
                }
                .info-popup {
                    position: absolute;
                    top: -6px;
                    right: 0;
                }
            }
            &-hush {
                width: 25px;
            }
            &-status {
                // .status {
                //     font-weight: 600;
                //     letter-spacing: 0.2px;
                //     font-size: 10px;
                //     background-color: color(secondary);
                //     color: #fff;
                //     padding: 3px 6px;
                //     text-transform: capitalize;
                //     display: inline-block;
                //     border-radius: 4px;
                //     &.working {
                //         background-color: color(success);
                //         // color: #000;
                //     }
                //     &.stopped,
                //     &.paused {
                //         background-color: color(warning);
                //     }
                //     &.rejected,
                //     &.unknown {
                //         background-color: color(danger);
                //     }
                //     &.draft {
                //         background-color: color(secondary, 0.4);
                //     }
                //     &.moderation {
                //         border: 1px solid color(primary);
                //         background-color: transparent;
                //         color: color(primary);
                //     }
                //     &.paused-daily-budget {
                //         border: 1px solid color(warning);
                //         background-color: transparent;
                //         color: color(warning);
                //     }
                // }
            }
            &-conversion {
               
            }
            &-txn-value {
                span {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 130px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
            &-totals {
                &-title {
                    // color: color(primary);
                    // font-weight: 600;
                    // font-size: 14px;
                }
            }
        }
        &-tr {
            &-totals {
                pointer-events: none;
                // background-color: rgba(0,0,0,0.05);
                td {
                    border-top: 1px solid rgba(0,0,0,0.3);
                }
            }
        }
       
    }
    tbody {
        transition: opacity 0.3s ease;
        &.hide {
            opacity: 0;
        }
        tr {
            &:hover:not(.campaign-details-tr) {
                background-color: color(secondary, 0.03);
                &.dark-mode {
                    background-color: color(light, 0.1);
                }
            }
            &.active {
                background-color: color(primary, 0.2) !important;
            }
        }
    }
    tr {
        &:not(:last-of-type) {
            // box-shadow: 0px 0px 2px color(light);
            border-bottom: 1px solid color(light);
        }
        &.campaign-details-tr {
        }
    }
    th {
        text-align: left;
        // font-weight: bold;
    }
    td {
    }
    th,
    td {
        padding: 13px 10px;
        white-space: nowrap;
        // vertical-align: top;
    }

    .table-td-settings-wrapper {
        // display: inline-flex;
        // display: grid;
        display: flex;
        // grid-template-columns: 1fr 1fr 1fr;
        // justify-content: end;
        .play-pause-btn-wrapper {
            grid-column: 1/2;
        }
        .edit-btn-wrapper {
            grid-column: 2/3;
        }
        .delete-btn-wrapper {
            grid-column: 3/4;
        }
    }

    .play-pause-btn-wrapper {
    }
    
}

.campaigns-table-wrapper {
    & > .row {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        flex-wrap: wrap;
        .dropdown {
            &-menu {
                left: auto;
                right: 0;
            }
        }
        .csv-btn-link {
            margin-left: auto;
            margin-right: 0 !important;
            &.disabled {
                pointer-events: none;
                opacity: 0.3;;
            }
        }
    }
}

